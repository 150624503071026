/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { CustomersIndex } from "../pages/customers";
import { Customer } from "../interfaces/interfaces";
import { useQuotesStore } from "../stores/quotesStore";
import { useLayoutStore } from "../stores/layoutStore";
import { CustomersCreate } from "../pages/customers/create";

interface destinyProps {
  close?: () => void;
  reload?: () => void;
}

export const Destiny: React.FC<destinyProps> = ({close}) => {
  // const states = [
  //   "Clientes",
  //   // "Proveedores"
  // ];
  const [option,] = React.useState("Clientes");
  const quotesStore = useQuotesStore();
  const layoutStore = useLayoutStore();
  const insideContent = (destiny: Customer | any) => {
    return <>
      <Button variant="success" onClick={(e) => {
        e.stopPropagation();
        quotesStore.setDestiny({...destiny, type: option});
        layoutStore.setModule("Cotizaciones");
        close && close();
      }}>
        <i className="fa-solid fa-check"></i>
      </Button>
    </>;
  };

  const outsideContent = (destiny: Customer | any) => {
    quotesStore.setDestiny({...destiny, type: option});
    layoutStore.setModule("Cotizaciones");
    close && close();
  };

  const createContent = () => {
    return <>
      <CustomersCreate close={close} reload={() => {}} outside={outsideContent}/>
    </>;
  };
  return (
    <Form>
      <Form.Group as={Col} className="mb-3 col-12">
        <Col>
          {
            option === "Clientes" && 
            <>
              Selecciona un cliente del listado.
              <CustomersIndex type="inside" insideContent={insideContent} createContent={createContent}/>
            </>
          }
        </Col>
      </Form.Group>
    </Form>
  );
};