import React, { useEffect } from 'react';
import proemsa_logo from '../../../public/assets/images/proemsa.png';
import { useAuthStore } from '../../stores/authStore';
import { useLayoutStore } from '../../stores/layoutStore';
export function Home() {
  const auth = useAuthStore();
  const setModule = useLayoutStore(state => state.setModule);
  useEffect(() => {
    setModule("");
  }, []);
  return (
    <>
      <div className='home '>
        <div className="row align-items-end ">
          <div className="col-9">
            <h1>Administrador Proemsa</h1>
            <h2>Bienvenido { auth.user?.name } {auth.user?.lastname} </h2>
          </div>
          <div className="col-3">
            <img src={proemsa_logo} className=' w-100' alt=""/>
          </div>
        </div>
        {
          auth.user?.role === 'administrator' && (
            <div className='container-fluid p-4'>
            <div className="row justify-content-end">
              <div className="col-md-12">
                <iframe title="PREOMSA" style={{width: "100%", height: "550px"}} src="https://app.powerbi.com/view?r=eyJrIjoiN2MyMzgyMTctZDk0ZC00YzQ1LTg1ZGQtMWE4MTgyMzliYmQ2IiwidCI6ImQ2OWE3MzZlLTE0OTctNDUwYi1iNDk4LWQ5ZmZmMDY3MDk3YSIsImMiOjR9" frameBorder="0" allowFullScreen/>
              </div>
              
            </div>
            </div>
          )
        }
      </div>
      
    </>
  ); 
}
