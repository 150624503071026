/* eslint-disable @typescript-eslint/no-explicit-any */
import { PaginationResult, Product, Quotes } from "../interfaces/interfaces";
import { AjaxService } from "./ajax";
const basePath = "quotes";
export const quotesService = {
  getPagination: (page: number, perPage?: number, name?: string, orderBy?: string, orderType?: string, filters?: any) =>
    AjaxService.get<PaginationResult<Quotes>>({
      url: `${basePath}`,
      data: {
        page,
        perPage,
        name,
        orderBy,
        orderType,
        filters,
      },
    }),
  delete: (id: number) =>
    AjaxService.delete<Product>({
      url: `${basePath}/${id}`,
    }),
  create: (data: Record<string, any>) =>
    AjaxService.post<Product>({
      url: `${basePath}`,
      data,
    }),
  get: (id: number) =>
    AjaxService.get<Quotes>({
      url: `${basePath}/${id}`,
    }),
  update: (id: number, data: Record<string, any>) =>
    AjaxService.put<Quotes>({
      url: `${basePath}/${id}`,
      data,
    }),
  getPdf: (id: number) =>
    AjaxService.get<{pdf: string}>({
      url: `${basePath}/${id}/pdf`,
    }),
  getLog: (id: number, page: number, perPage?: number, name?: string) => 
    AjaxService.get<any>({
      url: `${basePath}/${id}/log`,
      data: {
        page,
        perPage,
        name,
      },
    }),
  toOrder: (id: number) =>
    AjaxService.post<any>({
      url: `${basePath}/${id}/toOrder`,
    }),
};
