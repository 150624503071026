/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Image, Tab, Tabs } from "react-bootstrap";
import { catalogService } from "../../services/catalogService";
import { Catalog, Product } from "../../interfaces/interfaces";
import { productsService } from "../../services/productsService";
import { commonService } from "../../services/commonService";
import noImage from "../../../public/assets/images/no-image.png";
interface propsViewProduct {
  close?: () => void;
  reload?: () => void;
  object?: Product;
}

export const ViewProduct: React.FC<propsViewProduct> = ({
  close,
  reload,
  object,
}) => {
  const [brands, setBrands] = useState<Catalog[]>([]);
  const [categories, setCategories] = useState<Catalog[]>([]);
  const form = useRef<HTMLFormElement>(null);
  const fileElement = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState(noImage);
  const [b64Image, setB64Image] = useState("");
  useEffect(() => {
    if (object) {
      catalogService.get("brand").then((res) => {
        if (res.success) {
          setBrands(res.object ?? []);
        }
      });
      catalogService.get("category").then((res) => {
        if (res.success) {
          setCategories(res.object ?? []);
        }
      });

      document
        .querySelector("[name='id']")
        ?.setAttribute("value", `${object.id}`);
      document
        .querySelector("[name='name']")
        ?.setAttribute("value", object.name);
      document
        .querySelector("[name='price']")
        ?.setAttribute("value", object.price);
      document
        .querySelector("[name='description']")
        ?.setAttribute("value", object.description ?? "");
      const tarea = document.querySelector("[name='long_description']");
      if (tarea) {
        tarea.innerHTML = object.long_description ?? "";
      }
      if (object.image !== "") {
        setImage(object.image);
      }
      document
        .querySelector("[name='unidad_sat']")
        ?.setAttribute("value", object.extra_fields?.unidad_sat ?? "");
      document
        .querySelector("[name='clave_sat']")
        ?.setAttribute("value", object.extra_fields?.clave_sat ?? "");

      // commonService.log(object.brand_id, object.category_id)
    }
  }, []);

  const getBase64 = (file: Blob) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL: any = "";
      // Make new FileReader
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        setImage(baseURL);
        setB64Image(baseURL);
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
      })
      .catch((err) => {});
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const product: Product = {
      id: 0,
      name: "",
      status: "",
      description: "",
      short_description: "",
      price: "",
      tax: "",
      category_id: 0,
    };
    for (const [key, value] of data.entries()) {
      product[key] = value;
    }
    if (b64Image) {
      product.image = b64Image;
    }
    //
    productsService.update(object?.id ?? 0, data).then((res) => {
      if (res.success) {
        commonService.toast("Producto creado correctamente", "success");
        close && close();
        reload && reload();
        return;
      }
      commonService.toast("Error al crear producto", "error");
    });
  };

  return (
    <>
      {object ? (
        <>
          <Form
            onSubmit={handleSubmit}
            method="POST"
            ref={form}
            encType="multipart/form-data"
          >
            <Tabs defaultActiveKey="home" className="mb-3">
              <Tab eventKey="home" title="Información General">
                <input type="hidden" name="id" />
                <Form.Group as={Row} className="mb-3 text-center">
                  <div className="col-4 offset-4">
                    <Image
                      src={image}
                      thumbnail
                      onClick={() => {
                        fileElement.current?.click();
                      }}
                      style={{ cursor: "pointer" }}
                      className="w-100"
                    />
                  </div>
                  <Form.Control
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileInputChange}
                    ref={fileElement}
                  />
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Nombre <small className="text-danger">*</small>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" name="name" required />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Precio <small className="text-danger">*</small>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="number" name="price" required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Descripción <small>Máximo 250 caracteres</small>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      name="description"
                      maxLength={250}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Descripción Larga
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="long_description"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Marca
                  </Form.Label>
                  <Col sm="10">
                    <select className="form-select" name="brand_id">
                      <option></option>
                      {brands.map((item, i) => (
                        <option
                          key={i}
                          value={`${item.id}`}
                          selected={item.id === object.brand_id}
                        >
                          {item.id} - {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="2">
                    Categoría
                  </Form.Label>
                  <Col sm="10">
                    <select className="form-select" name="category_id">
                      <option></option>
                      {categories.map((item, i) => (
                        <option
                          key={i}
                          value={`${item.id}`}
                          selected={item.id === object.category_id}
                        >
                          {item.id} - {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Form.Group>
              </Tab>
              <Tab eventKey="profile" title="Información Adicional">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Unidad SAT
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" name="unidad_sat" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Clave SAT
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" name="clave_sat" />
                  </Col>
                </Form.Group>
              </Tab>
            </Tabs>
            <div className="text-center">
              <Button
                variant="danger"
                id="button-addon2"
                onClick={() => close && close()}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                id="button-addon2"
                className="ms-2"
              >
                Guardar
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <div className="text-center">
          <h3>Cargando</h3>
        </div>
      )}
    </>
  );
};
