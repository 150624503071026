/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Catalog } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { usersService } from "../../services/usersService";

interface propsCreateUser {
  close?: () => void;
  reload?: () => void;
}

export const CreateUser: React.FC<propsCreateUser> = ({
  close,
  reload,
}) => {
  const form = useRef<HTMLFormElement>(null);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const object: Record<string, any> = {};
    for (const [key, value] of data.entries()) {
      object[key] = value;
    }
    const pss = object['password'].trim();
    if(pss !== '' && pss.length < 10){
      commonService.toast("La contraseña debe tener al menos 10 caracteres, los espacios en blanco no son validos.", 'error');
      return;
    }
    usersService.create( object as Catalog).then((res) => {
      if (res.success) {
        commonService.toast("Usuario creado con éxito", "success");
        close && close();
        reload && reload();
      } else {
        commonService.toast("Error al crear la Usuarios", "error");
      }
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} method="POST" ref={form}>
        <input type="hidden" name="id" />
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
              Nombre <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="name" required />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
              Usuario <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="email" required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
              Contraseña <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="password" name="password" required/>
          </Col>
        </Form.Group>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => close && close()}
          >
              Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
          >
              Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
