import React, { ReactNode } from "react";
import { Navigate, redirect } from "react-router-dom";
import { useAuthStore } from "../stores/authStore";

interface IProps {
  children: ReactNode;
}

export default function PrivateRoute({
  children
}: IProps) {
  const user = useAuthStore();
  const isLoggedIn = user.authorization?.token ? true : false;
  if(isLoggedIn){
    return children;
  }
  return <Navigate to="/login" replace/>;
 
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkLogin = () => {
  const user = useAuthStore.getState();
  const isLoggedIn = user.authorization?.token ? true : false;
  if(!isLoggedIn)
    return redirect('/login');
  return null;
};
