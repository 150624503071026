import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { catalogService } from "../../services/catalogService";
import { Catalog, Product } from "../../interfaces/interfaces";
import { productsService } from "../../services/productsService";
import { commonService } from "../../services/commonService";

interface propsCreateProduct {
  close?: () => void;
  reload?: () => void;
}

export const CreateProduct: React.FC<propsCreateProduct> = ({
  close,
  reload,
}) => {
  const [brands, setBrands] = useState<Catalog[]>([]);
  const [categories, setCategories] = useState<Catalog[]>([]);
  useEffect(() => {
    catalogService.get("brand").then((res) => {
      if (res.success) {
        setBrands(res.object ?? []);
      }
    });
    catalogService.get("category").then((res) => {
      setCategories(res.object ?? []);
    });
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const product: Product = {
      id: 0,
      name: "",
      status: "",
      description: "",
      short_description: "",
      price: "",
      tax: "",
      category_id: 0,
    };
    for (const [key, value] of data.entries()) {
      product[key] = value;
    }
     
    productsService.create(product).then((res) => {
      if (res.success) {
        commonService.toast("Producto creado correctamente", "success");
        close && close();
        reload && reload();
        return;
      }
      commonService.toast("Error al crear producto", "error");
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} method="POST">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Nombre <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="name" required />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Precio <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="number" name="price" required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Descripción <small>Máximo 250 caracteres</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="description" maxLength={250}/>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Descripción Corta
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="short_description" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Marca
          </Form.Label>
          <Col sm="10">
            <Form.Select aria-label="Default select example" name="brand_id">
              <option></option>
              {brands.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.id} - {item?.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
          <Form.Label column sm="2">
            Categoría
          </Form.Label>
          <Col sm="10">
            <Form.Select aria-label="Default select example" name="category_id">
              <option></option>
              {categories.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.id} - {item?.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => close && close()}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
          >
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
