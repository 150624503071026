/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import {
  Catalog,
  PaginationResult,
  Quotes,
  statusQuotes,
  statusQuotesClass,
} from "../../interfaces/interfaces";
// import { commonService } from "../../services/commonService";
// import { CreateBrand } from "./create";
import { Button, Modal } from "react-bootstrap";
// import { ViewBrand } from "./view";
import { quotesService } from "../../services/quotesService";
import { useLayoutStore } from "../../stores/layoutStore";
import { commonService } from "../../services/commonService";
import { useAuthStore } from "../../stores/authStore";

export function QuotesIndex() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [, setObject] = useState<Catalog>();
  const moduleLabel = "Cotización";
  const auth = useAuthStore();
  const [orderBy, setOrderBy] = useState("");
  const [orderType, setType] = useState("desc");
  const [filters, setFilters] = useState({});
  // const base = process.env.REACT_APP_API_URL;
  const setModule = useLayoutStore((state) => state.setModule);
  useEffect(() => {
    setModule("Cotizaciones");
  }, []);
  const headers = {
    id: {
      label: "Proyecto",
      width: "20%",
      order: true,
      render: (row: any) => (
        <a href={`/movements/quotes/${row?.id}`} className="link">
          ({row?.id}) - ({row?.name})
        </a>
      ),
    },
    // name: {
    //   label: "Nombre",
    //   width: "10%",
    //   render: (row: any) => <a href={`/movements/quotes/${row?.id}`} className="link">{row?.name}</a>
    // },
    customer: {
      label: "Cliente",
      width: "30%",
      render: (row: any) => (
        <a href={`/movements/customers/${row?.customer?.id}`} className="link">
          ({row?.customer?.id}) - {row?.customer?.name}
        </a>
      ),
    },
    status: {
      label: "Estatus",
      width: "10%",
      order: true,
      format: (value: string) =>
        statusQuotes[value as keyof typeof statusQuotes],
      customClass: (value: any) =>
        statusQuotesClass[value.status as keyof typeof statusQuotes],
    },
    expiration_date: {
      label: "Fecha de Expiración",
      width: "10%",
      order: true,
      format: (value: string) => commonService.formatDate(value),
    },
    total: {
      label: "Total",
      width: "10%",
      format: (value: string) => commonService.toCurrency(value),
    },
    actions: {
      label: "Acciones",
      width: "20%",
      render: ({ id }: { id: number }) => (
        <>
          {/* <button className="btn btn-warning ms-2" title="Editar" onClick={async (e) => {
          e.stopPropagation();
          handleShow();
          quotesService.get(id).then((res) => {
             
            if(res.success){
              setObject(res.object);
            }
          });
        }}>
          <i className="fa-solid fa-pen-to-square"></i>
        </button> */}
          <Button
            variant="success"
            title="Ver"
            className="ms-2 mt-2"
            href={`/movements/quotes/${id}`}
          >
            <i className="fa-solid fa-eye"></i>
          </Button>
          <Button
            variant="primary"
            title="Ver PDF"
            className="ms-2 mt-2"
            onClick={async () => {
              const response = await quotesService.getPdf(id);

              if (response.success) {
                commonService.showPDF(response?.object?.pdf ?? "");
              }
            }}
          >
            <i className="fa-regular fa-file-pdf"></i>
          </Button>
          {auth.user?.role === "administrator" && (
            <button
              className="btn btn-danger ms-2 mt-2"
              title="Borrar"
              onClick={async (e) => {
                e.stopPropagation();
                const response = await commonService.confirm(
                  "¿Estas seguro de borrar la " +
                    moduleLabel +
                    "?, esta acción es irreversible"
                );
                if (response.isConfirmed) {
                  const res = await quotesService.delete(id);
                  if (res.success) {
                    commonService.toast(
                      moduleLabel + " borrado con éxito",
                      "success"
                    );
                    reload();
                  } else {
                    commonService.toast(
                      "Error al borrar la " + moduleLabel,
                      "error"
                    );
                  }
                }
              }}
            >
              <i className="fa-solid fa-trash-can"></i>
            </button>
          )}
        </>
      ),
    },
  };

  const getObject = (
    page: number,
    filter?: string,
    orderBy?: string,
    orderType?: string,
    filters?: any
  ) =>
    quotesService
      .getPagination(page, 10, filter, orderBy, orderType, filters)
      .then((res) => {
        if (res.success && res.object?.data) {
          setPaginationResults(res.object);
        }
      });

  useMemo(
    () => getObject(page, filter, orderBy, orderType, filters),
    [page, filter, orderBy, orderType, filters]
  );

  const reload = () => getObject(page, filter);

  const [paginationResults, setPaginationResults] =
    useState<PaginationResult<Quotes>>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setObject(undefined);
    setShow(false);
  };

  const setOrder = (order: string, type: string) => {
    setOrderBy(order);
    setType(type);
  };
  // const handleShow = () => setShow(true);

  return (
    <>
      <DataTable
        // create={(close: () => void) => <CreateBrand close={close} reload={reload}/>}
        createUrl="/movements/quotes/create"
        onRowClick={viewItem}
        headers={headers}
        changePage={setPage}
        filter={setFilter}
        reload={reload}
        resultInfo={paginationResults}
        setOrder={setOrder}
        generalFilter="Filtrar por Proyecto"
        filters={[
          {
            name: "status",
            placeholder: "Estatus",
            options: [
              {
                label: "Pendiente",
                value: "pending",
              },
              {
                label: "Aprobado",
                value: "approved",
              },
              {
                label: "Rechazado",
                value: "rejected",
              },
            ],
            onChange: (value: any) => setFilters({ ...filters, status: value }),
          },
          {
            name: "created_at",
            placeholder: "Fecha de Pedido",
            type: "date",
            onChange: (value: any) =>
              setFilters({
                ...filters,
                created_at: { operation: ">=", value },
              }),
          },
          {
            type: "reset",
            onChange: () => {
              setFilters({})
              setFilter('')
            },
          },
        ]}
      />
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              // object && <ViewBrand object={object} close={handleClose} reload={reload}/>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
