import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { CustomersIndex } from "../pages/customers";
import { Customer } from "../interfaces/interfaces";
import { useLayoutStore } from "../stores/layoutStore";
import { useSalesStore } from "../stores/salesStore";
import { CustomersCreate } from "../pages/customers/create";

interface customerProps {
  close?: () => void;
  reload?: () => void;
}

export const CustomerSelector: React.FC<customerProps> = ({close}) => {
  const salesStore = useSalesStore();
  const layoutStore = useLayoutStore();
  const insideContent = (destiny: Customer) => {
    return <>
      <Button variant="success" onClick={(e) => {
        e.stopPropagation();
        salesStore.setValues('customer', destiny);
        salesStore.setValues('customer_id', destiny.id);
        salesStore.setValues('email', destiny.email);
        salesStore.setValues('phone', destiny.phone);
        salesStore.setValues('shipping_address', destiny.address);
        salesStore.setValues('shipping_city', destiny.city);
        salesStore.setValues('shipping_zipcode', destiny.zipcode);
        salesStore.setValues('shipping_state', destiny.state);
        salesStore.setValues('shipping_country', destiny.country);
        layoutStore.setModule("Ventas");
        close && close();
      }}>
        <i className="fa-solid fa-check"></i>
      </Button>
    </>;
  };
  const outsideContent = (destiny: Customer) => {
    salesStore.setValues('customer', destiny);
    salesStore.setValues('customer_id', destiny.id);
    salesStore.setValues('email', destiny.email);
    salesStore.setValues('phone', destiny.phone);
    salesStore.setValues('shipping_address', destiny.address);
    salesStore.setValues('shipping_city', destiny.city);
    salesStore.setValues('shipping_zipcode', destiny.zipcode);
    salesStore.setValues('shipping_state', destiny.state);
    salesStore.setValues('shipping_country', destiny.country);
    layoutStore.setModule("Ventas");
    close && close();
  };

  const createContent = () => {
    return <>
      <CustomersCreate close={close} reload={() => {}} outside={outsideContent}/>
    </>;
  };
  return (
    <Form>
      <Form.Group as={Col} className="mb-3 col-12">
        <Col className="mt-1">
          Selecciona un cliente del listado.
          <CustomersIndex type="inside" insideContent={insideContent} createContent={createContent}/>
        </Col>
      </Form.Group>
    </Form>
  );
};