import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Authorization, User } from "../interfaces/interfaces";

interface AuthState {
  user?: User;
  authorization?: Authorization;
}

interface AuthActions {
  setUser: (user?: User) => void;
  logout: () => void;
  refresh: (token?: Authorization) => void;
  login: (token?: Authorization) => void;
  getPermissions: () => string[];
}

const initialState: AuthState = {
  user: undefined,
  authorization: undefined,
};

export const useAuthStore = create<AuthState & AuthActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setUser(user) {
          set({ user });
        },
        logout() {
          set({ user: undefined, authorization: undefined });
        },
        refresh(authorization) {
          set({ authorization });
        },
        login(authorization) {
          set({ authorization });
        },
        getPermissions() {
          const aux = get().user;
          try {
            return aux?.permissions.map(permission => permission.module.name) ?? [];
          } catch (error) {
            localStorage.clear();
            window.location.replace("/");
            return [];
          }
        },
      }),
      {
        name: "crisa-auth",
      }
    )
  )
);
