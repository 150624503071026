/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button, Modal, Table, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../services/commonService";
import { useSalesStore } from "../../stores/salesStore";
import { states } from "../../interfaces/interfaces";
import AsyncSelect from "react-select/async";
import { productsService } from "../../services/productsService";
import { salesService } from "../../services/salesService";
import { CustomerSelector } from "../../components/customerSelector";
import { SupplierSelector } from "../../components/supplierSelector";
import { usePurchasesStore } from "../../stores/purchasesStore";
import { purchasesService } from "../../services/purchasesService";

export interface SalesCreateProps {
  close?: () => void;
  reload?: () => void;
}

export const PurchaseCreate: FC<SalesCreateProps> = ({ reload }) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const purchaseStore = usePurchasesStore();
  const backToList = () => {
    purchaseStore.reset();
    navigate("/movements/purchases");
  };
  const [loading, setLoading] = useState(false);
  const salesStore = useSalesStore();
  // const [destiny, setDestiny] = useState();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = purchaseStore.getState();
    if (payload.details?.length === 0) {
      commonService.toast("Debe agregar productos a la venta", "error");
      return;
    }
    if (!payload.supplier_id) {
      commonService.toast("Debe seleccionar un proveedor", "error");
      return;
    }
    setLoading(true);
    const response = await purchasesService.create(payload);
    if (response.success) {
      commonService.toast(response.message, "success");
      backToList();
      setLoading(false);
      return;
    }
    commonService.toast(response.message, "error");
    setLoading(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setObject(undefined);
    setShow(false);
  };
  const getProducts = (filter?: string) =>
    productsService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
      }
      return [];
    });
  const addItem = (element: any) => {
    if (element) {
      const item = purchaseStore?.details?.find(
        (item) => item.id === element.id
      );
      if (!item) purchaseStore.addItem(element);
    }
  };
  useEffect(() => {
    purchaseStore.calculateTotal();
  }, [purchaseStore.details, purchaseStore.discount]);

  useEffect(() => {
    purchaseStore.reset();
    setLoad(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona Proveedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SupplierSelector close={handleClose} reload={reload} />
        </Modal.Body>
      </Modal>
      {load && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!load && (
        <Form onSubmit={handleSubmit} method="POST">
          <Row className="mt-5">
            <Col sm="12">
              <Button variant="primary" onClick={() => setShow(true)}>
                Selecciona Proveedor
              </Button>
            </Col>
          </Row>
          {purchaseStore?.supplier && (
            <>
              <hr />
              <Row>
                <Col sm="6">
                  <strong>Cliente: </strong> {purchaseStore?.supplier?.name}{" "}
                  <br />
                  <strong>RFC: </strong> {purchaseStore?.supplier?.rfc}
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Proyecto
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        name="name"
                        required
                        value={purchaseStore.name}
                        onChange={(e) =>
                          purchaseStore.setValues("name", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Dirección <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="address"
                        value={purchaseStore.shipping_address}
                        onChange={(e) =>
                          purchaseStore.setValues(
                            "shipping_address",
                            e.target.value
                          )
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Ciudad <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="created"
                        required
                        value={purchaseStore.shipping_city}
                        onChange={(e) =>
                          purchaseStore.setValues(
                            "shipping_city",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col as={Row}>
                  <Form.Label column sm="3">
                    Estado
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select example"
                      name="state"
                      className="form-control"
                      value={purchaseStore.shipping_state}
                      onChange={(e) =>
                        purchaseStore.setValues(
                          "shipping_state",
                          e.target.value
                        )
                      }
                    >
                      <option></option>
                      {states.map((item, i) => (
                        <option key={i} value={`${item}`}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Código Postal <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="zipcode"
                        required
                        value={purchaseStore.shipping_zipcode}
                        onChange={(e) =>
                          purchaseStore.setValues(
                            "shipping_zipcode",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col as={Row}>
                  <Form.Label column sm="3">
                    País
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select example"
                      name="country"
                      className="form-control"
                    >
                      <option value="México">México</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Email <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="email"
                        name="email"
                        required
                        value={purchaseStore.email}
                        onChange={(e) =>
                          purchaseStore.setValues("email", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col as={Row}>
                  <Form.Label column sm="3">
                    Teléfono <small className="text-danger">*</small>
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      name="phone"
                      value={purchaseStore.phone}
                      onChange={(e) =>
                        purchaseStore.setValues("phone", e.target.value)
                      }
                      required
                    />
                  </Col>
                </Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-3">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={getProducts}
                  onChange={addItem}
                />
              </Row>
              <Row>
                <Table striped bordered hover className="w-100">
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>Producto</th>
                      <th style={{ width: "10%" }}>Cantidad</th>
                      <th style={{ width: "10%" }}>Precio</th>
                      <th style={{ width: "10%" }}>Impuesto</th>
                      <th style={{ width: "15%" }}>Total</th>
                      <th style={{ width: "5%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseStore?.details &&
                      purchaseStore?.details?.map((element: any, index) => (
                        <tr key={`key-${index}`}>
                          <td>{element.name}</td>
                          <td>
                            <Form.Control
                              type="number"
                              name="quantity"
                              required
                              value={element.quantity}
                              onChange={(e) => {
                                purchaseStore.updateItem(index, {
                                  quantity: e.target.value,
                                });
                                purchaseStore.calculateTotal();
                              }}
                              step="1.00"
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="price"
                              required
                              value={element.price}
                              onChange={(e) => {
                                purchaseStore.updateItem(index, {
                                  price: e.target.value,
                                });
                                purchaseStore.calculateTotal();
                              }}
                              step="1.00"
                            />
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="iva"
                              value={element.tax_percent}
                              onChange={(e) => {
                                purchaseStore.updateItem(index, {
                                  tax_percent: e.target.value,
                                });
                                purchaseStore.calculateTotal();
                              }}
                            >
                              <option value="0.00">0 %</option>
                              <option value="0.16">16 %</option>
                            </Form.Select>
                          </td>
                          <td className="text-right">
                            {commonService.toCurrency(`${element.total}`)}
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => {
                                purchaseStore.removeItem(index);
                                purchaseStore.calculateTotal();
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    {purchaseStore?.details &&
                      purchaseStore?.details?.length > 0 && (
                        <>
                          <tr>
                            <td colSpan={3}></td>
                            <td>Subtotal</td>
                            <td className="text-right">
                              {commonService.toCurrency(
                                `${purchaseStore.subtotal}`
                              )}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={3}></td>
                            <td>Impuesto</td>
                            <td className="text-right">
                              {commonService.toCurrency(`${purchaseStore.tax}`)}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={3}></td>
                            <td>Descuento</td>
                            <td className="text-right">
                              <Form.Control
                                type="number"
                                className="text-right"
                                name="discount"
                                value={purchaseStore.discount}
                                onChange={(e) =>
                                  purchaseStore.setValues(
                                    "discount",
                                    e.target.value
                                  )
                                }
                                step="1.00"
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan={3}></td>
                            <td>Total</td>
                            <td className="text-right">
                              {commonService.toCurrency(
                                `${purchaseStore.total}`
                              )}
                            </td>
                            <td></td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Observaciones
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="notes"
                        value={purchaseStore.notes}
                        onChange={(e) =>
                          purchaseStore.setValues("notes", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <div className="text-center">
            <Button
              variant="danger"
              id="button-addon2"
              disabled={loading}
              onClick={async () => {
                const res = await commonService.confirm(
                  "Se perderán los cambios no guardados, ¿Esta seguro?"
                );
                if (res.isConfirmed) {
                  backToList();
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              id="button-addon2"
              className="ms-2"
              disabled={loading}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
                hidden={!loading}
              />
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};
