/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Catalog,
  Customer,
  Suppliers,
  states,
} from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { suppliesService } from "../../services/suppliesService";

export interface CustomersCreateProps {
  close?: () => void;
  reload?: () => void;
  outside?: (customer: Suppliers) => void;
}

export const SuppliesCreate: FC<CustomersCreateProps> = ({
  close,
  reload,
  outside,
}) => {
  const { id } = useParams();
  const [object] = useState<Customer>();
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState<Catalog[]>([]);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
    // console.log({ values });
    // return;
    suppliesService.create(values).then((response) => {
      setIsLoading(false);
      if (response.success) {
        commonService.toast("Insumo creado correctamente", "success");
        if (outside) {
          outside(response.object || ({} as Suppliers));
          return;
        }
        close && close();
        reload && reload();
      }
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Form onSubmit={handleSubmit} method="POST">
        <Row>
          <h5>Insumo {id}</h5>
        </Row>
        <Form.Group as={Row} className="mb-3">
          <input type="hidden" name="id" value={id} />
          <Form.Label column sm="2">
            Nombre <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="name" required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Descripción
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="description" />
          </Col>
        </Form.Group>
        <div className="row">
          <Form.Group as={Col} className="mb-3 col-6">
            <Form.Label column sm="12">
              Tipo
            </Form.Label>
            <Col sm="12">
              <Form.Check
                inline
                label="Materia Prima"
                name="type"
                type="radio"
                value="product"
                defaultChecked
              />
              <Form.Check
                inline
                label="Servicio"
                name="type"
                type="radio"
                value="service"
              />
            </Col>
          </Form.Group>
        </div>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => close && close()}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
            disabled={isLoading}
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
