import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface LayoutState {
  module: string;
}

interface LayoutActions {
  setModule: (module: string) => void;
}  

const initialState: LayoutState = {
  module: "Home",
};

export const useLayoutStore = create<LayoutState & LayoutActions>()(
  devtools(
    persist(
      (set,) => ({
        ...initialState,
        setModule: (module) => set({ module }),
      }),
      {
        name: "crisa-layout",
      }
    )
  )
);
