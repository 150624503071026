import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import { productsService } from "../../services/productsService";
import { Category, PaginationResult, Product } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { CreateProduct } from "./create";
import { Modal } from "react-bootstrap";
import { ViewProduct } from "./view";
import { useLayoutStore } from "../../stores/layoutStore";

export function ProductsIndex() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item)
  const moduleLabel = 'Productos';
  const setModule = useLayoutStore(state => state.setModule);
  useEffect(() => {
    setModule(moduleLabel);
  }, []);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [product, setProduct] = useState<Product>();
  const filters = [
    {
      name: 'name',
      type: 'text',
      placeholder: 'Busque por nombre'
    },
    {
      name: 'category',
      type: 'options',
      options: ['Cat 1 ', 'cat 2'],
    }
  ]
  const headers = {
    id: {
      label: "ID",
    },
    name: {
      label: "Nombre"
    },
    model: {
      label: "Modelo",
    },
    price: {
      label: "Precio",
      format: (value: string) => `$ ${Number(value).toFixed(2)}`
    },
    category: {
      label: "Categoría",
      format: (value?: Category) => value?.name ?? ''
    },
    actions: {
      label: "Acciones",
      render: ({id}: {id: number}) => <>
        <button className="btn btn-warning ms-2 mt-1" title="Editar" onClick={async (e) => {
          e.stopPropagation();
          handleShow();
           
           
          productsService.get(id).then((res) => {
             
            if(res.success){
              setProduct(res.object);
            }
          });
        }}>
          <i className="fa-solid fa-pen-to-square"></i>
        </button>
        <button className="btn btn-danger ms-2 mt-1" title="Borrar" onClick={async (e) => {
          e.stopPropagation();
          const response = await commonService.confirm('¿Estas seguro de borrar el producto?, esta acción es irreversible');
          if(response.isConfirmed){
             
            const res = await productsService.delete(id);
            if(res.success){
              commonService.toast('Producto borrado con éxito', 'success');
              reload();
            }else{
              commonService.toast('Error al borrar el producto', 'error');
            }
          }
        }}>
          <i className="fa-solid fa-trash-can"></i>
        </button>
      </>
    }
  };
  
  const getProducts = (page: number, filter?: string) => productsService.getPagination(page, 10, filter).then((res) => {
    if (res.success && res.object?.data) {
      setPaginationResults(res.object);
    }
  });

  useMemo(() => getProducts(page, filter), [page, filter]);
  
  const reload = () => getProducts(page, filter);
  

  const [paginationResults, setPaginationResults] = useState<PaginationResult<Product>>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setProduct(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  
  return (
    <>
      <DataTable
        create={(close: () => void) => <CreateProduct close={close} reload={reload}/>}
        onRowClick={viewItem}
        headers={headers}
        changePage={setPage}
        filter={setFilter}
        // filters={filters}
        reload={reload}
        resultInfo={paginationResults}  
      />
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Producto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              product && <ViewProduct object={product} close={handleClose} reload={reload}/>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
