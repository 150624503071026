/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../services/commonService";
import { useSalesStore } from "../../stores/salesStore";
import { DeliveriesDetails, Product, states } from "../../interfaces/interfaces";
import AsyncSelect from 'react-select/async';
import { productsService } from "../../services/productsService";
import { salesService } from "../../services/salesService";
import { SaleSelector } from "../../components/saleSelector";
import { useDeliveriesStore } from "../../stores/deliveriesStore";
import moment, { max } from "moment";
import { deliveriesService } from "../../services/deliveriesService";



export interface SalesCreateProps {
  close?: () => void;
  reload?: () => void;
}

export const DeliveriesCreate: FC<SalesCreateProps> = ({reload}) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const salesStore = useSalesStore();
  const deliveriesStore = useDeliveriesStore()
  const [products, setProducts] = useState('')
  const backToList = () => {
    deliveriesStore.reset();
    navigate('/movements/deliveries');
  };
  // const [destiny, setDestiny] = useState();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = deliveriesStore.getState();
    commonService.log({payload})
    if(payload.details?.length === 0){
      commonService.toast("Debe agregar productos a la nota de remisión", "error");
      return;
    }
    if(!payload.sale){
      commonService.toast("Debe seleccionar una venta", "error");
      return;
    }
    setLoading(true);
    
    // return;
    const response = await deliveriesService.create(payload);
    if(response.success){
      commonService.toast(response.message, "success");
      backToList();
      setLoading(false);
      const resPdf = await deliveriesService.getPdf(Number(response?.object?.id));
      if(resPdf.success){
        commonService.showPDF(resPdf?.object?.pdf ?? '');
      }
      return;
    }
    commonService.toast(response.message, "error");
    setLoading(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setObject(undefined);
    setShow(false);
  };
  const getProducts = (filter?: string) => productsService.getPagination(1, 10, filter, products).then((res) => {
    if (res.success && res.object?.data) {
      // commonService.log('obj', res.object.data)
      return res.object.data.map(element => ({...element, value: element.id, label: element?.name}));
    }
    return [];
  });
  const addItem = (element: any) => {
    if(element){
      const item = deliveriesStore?.details?.find(item => item.id === element.id);
      if(!item) deliveriesStore.addItem(element);
    }
  };
  // useEffect(() => {
  //   salesStore.calculateTotal();
  // }, [salesStore.details, salesStore.discount]);
  
  useEffect(() => {
    deliveriesStore.reset();
    setLoad(false);
  }, []);
  useEffect(() => {
    if(deliveriesStore?.sale?.id){
      setProducts(deliveriesStore?.sale?.sales_details.map(e => `${e.product_id}`).join(','))
    }
  }, [deliveriesStore?.sale?.id]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona una Venta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SaleSelector close={handleClose} />
        </Modal.Body>
      </Modal>
      {
        load && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )
      }
      {
        !load && (
          <Form onSubmit={handleSubmit} method="POST">
            <Row className="mt-5">
              <Col sm="12">
                <Button variant="primary" onClick={() => setShow(true)}>Selecciona una Venta</Button>
              </Col>
            </Row>
            {/* {
              JSON.stringify(deliveriesStore)
            } */}
            {
              
              deliveriesStore?.sale?.id && (
                <>
                  <hr />
                  <Row>
                    <Col sm="6">
                      <strong>Cliente: </strong> {deliveriesStore?.sale?.customer?.name} <br/>
                      <strong>RFC: </strong> {deliveriesStore?.sale?.customer.rfc} <br />
                      <strong>Venta: </strong> {deliveriesStore?.sale?.id} <br />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                  Expiración <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control type="date" name="expiration_date" required  
                            value={deliveriesStore.expiring_date} onChange={(e) => deliveriesStore.setValues('expiring_date', e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-3 mb-3">
                    <AsyncSelect cacheOptions defaultOptions loadOptions={getProducts} onChange={addItem} />
                  </Row>
                  <Row>
                    <Table striped bordered hover className="w-100">
                      <thead>
                        <tr>
                          <th style={{width: "5%"}}>#</th>
                          <th style={{width: "75%"}}>Artículo</th>
                          <th style={{width: "10%"}}>Cantidad</th>
                          <th style={{width: "10%"}}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          deliveriesStore?.details && deliveriesStore?.details?.map((element: DeliveriesDetails, index) => (
                            <tr key={`key-${index}`}>
                              <td>
                                {index + 1}
                              </td>
                              <td>
                                {element?.product?.name}
                              </td>
                              <td>
                                <Form.Control type="number" name="quantity" required value={element.quantity} onChange={(e) => {
                                  const maxQuantity = Number(deliveriesStore.sale?.sales_details[index].quantity)
                                  const currentQuantity = Number(e.target.value)
                                  commonService.log({maxQuantity, currentQuantity})
                                  if(currentQuantity <= 0) return commonService.toast(`La cantidad del producto ${element.product?.name} no puede ser menor o igual a 0`, "error")
                                  if(currentQuantity > maxQuantity) return commonService.toast(`La cantidad del producto ${element.product?.name} no puede ser mayor a ${maxQuantity}`, "error")
                                  deliveriesStore.updateItem(index, {quantity: e.target.value});
                                }} step="1.00"/>
                              </td>
                              <td>
                                <Button variant="danger" onClick={() => {
                                  deliveriesStore.removeItem(index);
                                }}>
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))
                        }
                        {
                          deliveriesStore?.details && deliveriesStore?.details?.length > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td style={{textAlign: 'right'}}>Total</td>
                                <td className="text-right">{deliveriesStore.details.reduce((acc, e) => acc + Number(e.quantity), 0)}</td>
                                <td></td>
                              </tr>
                            </>
                          )
                        }

                      </tbody>
                    </Table>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Notas
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control as="textarea" rows={5} name="notes" value={deliveriesStore.notes} onChange={(e) => deliveriesStore.setValues('notes', e.target.value)}/>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )
            }
            
            <div className="text-center">
              <Button
                variant="danger"
                id="button-addon2"
                disabled={loading}
                onClick={async () => {
                  const res = await commonService.confirm("Se perderán los cambios no guardados, ¿Esta seguro?");
                  if(res.isConfirmed){
                    backToList();
                  }
                }}
              >
                  Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                id="button-addon2"
                className="ms-2"
                disabled={loading}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                  hidden={!loading}
                />
                  Guardar
              </Button>
            </div>
          </Form>

        )
      }
    </>
  );
};
