import { Catalog, PaginationResult } from "../interfaces/interfaces";
import { AjaxService } from "./ajax";

export const catalogService = {
  get: (catalogName: string) =>
    AjaxService.get<Catalog[]>({ url: `catalog/${catalogName}` }),
  getById: (catalogName: string, id: number) =>
    AjaxService.get<Catalog>({ url: `catalog/${catalogName}/${id}` }),
  list: (catalogName: string, page: number, perPage?: number, name?: string) =>
    AjaxService.get<PaginationResult<Catalog>>({
      url: `catalog/${catalogName}`,
      data: {
        page,
        perPage,
        name,
      },
    }),
  create: (catalogName: string, catalog: Catalog) =>
    AjaxService.post<Catalog>({
      url: `catalog/${catalogName}/upsert`,
      data: catalog,
    }),
  delete: (catalogName: string, id: number) =>
    AjaxService.post<Catalog>({
      url: `catalog/${catalogName}/delete`,
      data: { id },
    }),
  update: (catalogName: string, catalog: Catalog) =>
    AjaxService.post<Catalog>({
      url: `catalog/${catalogName}/upsert`,
      data: catalog,
    }),
};
