import React from "react";
import { App } from "./App";
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../public/assets/fontawesome/css/all.min.css';
const root = createRoot(
  document.getElementById("app") as HTMLElement
);
root.render(
  <App />
);