import { createBrowserRouter, redirect } from "react-router-dom";
import { Login } from "./pages/login";
import { NotFound } from "./pages/notfound";
import { Main } from "./layouts/main";
import { checkLogin } from "./components/privateRoute";
import { authService } from "./services/authService";
import { ProductsIndex } from "./pages/products";
import { Home } from "./pages/home";
import { BrandsIndex } from "./pages/brands";
import { CategoryIndex } from "./pages/categories";
import { UsersIndex } from "./pages/users";
import { QuotesIndex } from "./pages/quotes";
import { CustomersIndex } from "./pages/customers";
import { CustomersEdit } from "./pages/customers/edit";
import { QuotesCreate } from "./pages/quotes/create";
import { QuotesEdit } from "./pages/quotes/edit";
import { SalesIndex } from "./pages/sales";
import { SalesEdit } from "./pages/sales/edit";
import { SalesCreate } from "./pages/sales/create";
import { DeliveriesIndex } from "./pages/deliveries";
import { DeliveriesCreate } from "./pages/deliveries/create";
import { SenderIndex } from "./pages/senders";
import { SenderCreate } from "./pages/senders/create";
import { SupplierIndex } from "./pages/suppliers";
import { SupplierEdit } from "./pages/suppliers/edit";
import { PurchaseIndex } from "./pages/purchases";
import { PurchaseCreate } from "./pages/purchases/create";
import { PurchaseEdit } from "./pages/purchases/edit";
import { SuppliersCategoryIndex } from "./pages/suppliers_categories";
import { Children, Component } from "react";
import { SuppliesIndex } from "./pages/supplies";
import { FeaturesIndex } from "./pages/features";
import { TechnicalSheetIndex } from "./pages/technical-sheet";
import { EmployeesIndex } from "./pages/employees";
import { commonService } from "./services/commonService";
import { Attendance } from "./pages/employees/attendance";
import { EmployeesDasboard } from "./pages/employees/dashboard";
export const routes = [
  {
    index: true,
    loader: checkLogin,
    Component: Home,
    label: "Dashboard",
    path: "/",
    icon: "fa-chart-line",
  },
  {
    path: "/movements",
    loader: checkLogin,
    //Component:Home,
    label: "Ventas",
    icon: "fa-money-bill-trend-up",
    children: [
      {
        path: "/movements/customers",
        loader: checkLogin,
        Component: CustomersIndex,
        module: "customers",
        label: "Clientes",
        icon: "fa-circle",
      },
      {
        path: "/movements/customers/:id",
        loader: checkLogin,
        Component: CustomersEdit,
        module: "customers",
        label: "Ver",
        hidden: true,
        icon: "fa-circle",
      },
      {
        path: "/movements/quotes",
        Component: QuotesIndex,
        module: "quotes",
        label: "Cotizaciones",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/quotes/:id",
        Component: QuotesEdit,
        module: "quotes",
        hidden: true,
        label: "Cotizaciones",
        icon: "fa-file-invoice",
      },
      {
        path: "/movements/quotes/create",
        Component: QuotesCreate,
        module: "quotes",
        label: "Crear Cotizaciones",
        icon: "fa-file-invoice",
        hidden: true,
      },
      {
        path: "/movements/sales",
        Component: SalesIndex,
        module: "sales",
        label: "Ventas",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/sales/create",
        Component: SalesCreate,
        module: "sales",
        label: "Ventas",
        hidden: true,
        icon: "fa-file-invoice",
      },
      {
        path: "/movements/sales/:id",
        Component: SalesEdit,
        module: "sales",
        label: "Ventas",
        hidden: true,
        icon: "fa-file-invoice",
      },
      {
        path: "/movements/deliveries",
        Component: DeliveriesIndex,
        module: "sales",
        label: "Notas de Remisión",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/deliveries/create",
        Component: DeliveriesCreate,
        module: "sales",
        label: "Ventas",
        hidden: true,
        icon: "fa-file-invoice",
      },
      {
        path: "/movements/senders",
        Component: SenderIndex,
        module: "sales",
        label: "Remitentes",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/senders/create",
        Component: SenderCreate,
        module: "sales",
        label: "Ventas",
        hidden: true,
        icon: "fa-file-invoice",
      },
    ],
  },
  {
    path: "/catalogs",
    loader: checkLogin,
    //Component: ProductsIndex,
    label: "Productos",
    icon: "fa-dolly",
    children: [
      {
        path: "/catalogs/list",
        Component: ProductsIndex,
        label: "Lista de productos",
        module: "products",
        icon: "fa-circle",
      },
      {
        path: "/catalogs/brands",
        loader: checkLogin,
        Component: BrandsIndex,
        module: "brands",
        label: "Marcas",
        icon: "fa-circle",
      },
      {
        path: "/catalogs/categories",
        loader: checkLogin,
        Component: CategoryIndex,
        module: "categories",
        label: "Categorías",
        icon: "fa-circle",
      },
    ],
  },
  {
    path: "/movements",
    loader: checkLogin,
    // Component: Home,
    label: "Compras",
    icon: "fa-cart-shopping",
    children: [
      {
        path: "/movements/suppliers/groups",
        loader: checkLogin,
        Component: SuppliersCategoryIndex,
        module: "purchases",
        label: "Grupos",
        icon: "fa-circle",
      },
      {
        path: "/movements/suppliers",
        loader: checkLogin,
        Component: SupplierIndex,
        module: "purchases",
        label: "Proveedores",
        icon: "fa-circle",
      },
      {
        path: "/movements/suppliers/:id",
        loader: checkLogin,
        Component: SupplierEdit,
        module: "purchases",
        label: "Ver",
        hidden: true,
        icon: "fa-circle",
      },
      {
        path: "/movements/purchases",
        Component: PurchaseIndex,
        module: "purchases",
        label: "Compras",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/purchases/create",
        Component: PurchaseCreate,
        module: "purchases",
        label: "Ventas",
        hidden: true,
        icon: "fa-file-invoice",
      },
      {
        path: "/movements/purchases/:id",
        Component: PurchaseEdit,
        module: "purchases",
        label: "Compras",
        hidden: true,
        icon: "fa-file-invoice",
      },
    ],
  },
  {
    path: "/users",
    loader: checkLogin,
    Component: UsersIndex,
    module: "users",
    role: "administrator",
    label: "Usuarios",
    icon: "fa-users",
  },
  {
    path: "/movements",
    loader: checkLogin,
    // Component: Home,
    label: "Ficha Técnica",
    icon: "fa-solid fa-clipboard",
    children: [
      {
        path: "/movements/supplies",
        Component: SuppliesIndex,
        module: "technical-sheet",
        label: "Insumos",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/technical-sheet/create",
        Component: DeliveriesCreate,
        module: "technical-sheet",
        label: "Ventas",
        hidden: true,
        icon: "fa-file-invoice",
      },
      {
        path: "/movements/technical-sheet",
        Component: TechnicalSheetIndex,
        module: "technical-sheet",
        label: "Hoja técnica",
        icon: "fa-regular fa-circle",
      },
    ],
  },
  {
    path: "/movements",
    loader: checkLogin,
    // Component: Home,
    label: "Recursos Humanos",
    icon: "fa-solid fa-user-clock",
    children: [
      {
        path: "/movements/employees/dashboard",
        Component: EmployeesDasboard,
        module: "staff",
        label: "Dashboard",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/employees",
        Component: EmployeesIndex,
        module: "staff",
        label: "Empleados",
        icon: "fa-regular fa-circle",
      },
      {
        path: "/movements/attendance",
        Component: Attendance,
        module: "staff",
        label: "Carga de Asistencia",
        icon: "fa-regular fa-circle",
      },
    ],
  },
];

export const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Main,
    children: [...routes],
  },
  {
    path: "/login",
    Component: Login,
  },
  {
    path: "/logout",
    action: async () => {
      await authService.logout();
      localStorage.clear();
      return redirect("/");
    },
  },
  {
    path: "*",
    Component: NotFound,
  },
]);
