/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Sale } from "../interfaces/interfaces";

interface SalesState extends Sale {
  details: any[];
}

interface SalesActions {
  setValues: (key: string, value: any) => void;
  reset: () => void;
  deleteAllItems: () => void;
  addItem: (item: any) => void;
  updateItem: (index: number, changes: any) => void;
  removeItem: (index: number) => void;
  calculateTotal: () => void;
  getState: () => SalesState;
  initSale: (init: Partial<SalesState>) => void;
}

const initialState: SalesState = {
  details: [],
  name: '',
  subtotal: 0,
  tax: 0,
  discount: 0,
  total: 0,
  notes: "",
  id: 0,
  customer_id: 0,
  user_id: 0,
  email: "",
  phone: "",
  status: "",
  shipping_address: "",
  shipping_city: "",
  shipping_zipcode: "",
  shipping_state: "",
  shipping_country: "",
  created_at: "",
  updated_at: "",
  customer: {
    id: 0,
    name: "",
    lastname: "",
    address: "",
    phone: "",
    city: "",
    state: "",
    status: "",
    created_at: "",
    updated_at: "",
    contacts: []
  },
  user: {},
  sales_details: [],
  quote_id: 0
};

export const useSalesStore = create<SalesState & SalesActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setValues: (key, value) => set({ [key]: value }),
        initSale: (init) =>
          set({
            ...init,
            notes: init.notes ?? "",
            details: init.sales_details?.map((e) => ({
              ...e,
              tax_amount: e.tax,
              tax_percent: e.tax_rate,
              price: e.unit_price,
            })),
          }),
        reset: () => set(initialState),
        deleteAllItems: () => set({ details: [] }),
        addItem: (item) => {
          set((state) => {
            const price = Number(item.price ?? 0);
            const details = state.details ?? [];
            const taxPercent = (item.tax ?? 0) / 100;
            const taxAmount = price * taxPercent;
            const total = price + taxAmount;

            return {
              details: [
                ...details,
                {
                  ...item,
                  product_id: item.id,
                  name: item.name,
                  cost: price,
                  quantity: 1,
                  tax_percent: taxPercent,
                  tax_amount: taxAmount,
                  subtotal: price,
                  total: total,
                },
              ],
            };
            //  ({details: [...state.details, item]})),
          });
          // this.calculateTotal();
        },
        updateItem(index, changes) {
          set((state) => {
            const details = state.details ?? [];
            details[index] = { ...details[index], ...changes };
            // Update Total Item
            const price = Number(details[index].price ?? 0);
            const taxPercent = Number(details[index].tax_percent ?? 0);
            const quantity = Number(details[index].quantity ?? 0);
            const taxAmount = quantity * price * taxPercent;
            const total = quantity * price + taxAmount;
            const subTotal = quantity * price;
            details[index] = {
              ...details[index],
              total,
              subtotal: subTotal,
              tax_amount: taxAmount,
            };

            return { details };
          });
        },
        removeItem(index) {
          set((state) => {
            const details = state.details ?? [];
            details.splice(index, 1);
            return { details };
          });
        },
        calculateTotal() {
          set((state) => {
            const details = state.details ?? [];
            const subtotal = details.reduce(
              (acc, item) => acc + Number(item.subtotal),
              0
            );
            const totalTax = details.reduce(
              (acc, item) => acc + Number(item.tax_amount),
              0
            );
            const discount = Number(state.discount ?? 0);
            const total = subtotal + totalTax - discount;
            return { total, subtotal, tax: totalTax, discount };
          });
        },
        getState: () => ({
          ...initialState,
          name: get().name,
          details: get().details,
          subtotal: get().subtotal,
          tax: get().tax,
          quote_id: get().quote_id,
          customer_id: get().customer_id,
          discount: get().discount,
          total: get().total,
          notes: get().notes,
          status: get().status,
          email: get().email,
          phone: get().phone,
          shipping_address: get().shipping_address,
          shipping_city: get().shipping_city,
          shipping_zipcode: get().shipping_zipcode,
          shipping_state: get().shipping_state,
          shipping_country: get().shipping_country,
        }),
      }),
      {
        name: "crisa-sale",
      }
    )
  )
);
