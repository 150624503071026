/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Catalog } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { staffService } from "../../services/staffService";

interface propsCreateBrand {
  close?: () => void;
  reload?: () => void;
}

export const Attendance: React.FC<propsCreateBrand> = ({
  close,
  reload,
}) => {
  useEffect(() => {
  }, []);
  const [file, setFile] = useState<string>();
  const [b64File, setB64File] = useState<string>();
  const form = useRef<HTMLFormElement>(null);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const object: Record<string, any> = {};
    staffService.uploadAttendance(`${b64File}`).then((res) => {
      if (res.success) {
        commonService.toast("Archivo de Asistencia cargado con éxito", "success");
        form.current?.reset();
        // window.location.reload(false);
      } else {
        commonService.toast("Error al Subir el archivo", "error");
      }
    });
  };
  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
      })
      .catch((err) => {});
  };
  const getBase64 = (file: Blob) => {
    return new Promise((resolve) => {
      let baseURL: any = "";
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        setFile(baseURL);
        setB64File(baseURL);
        resolve(baseURL);
      };
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmit} ref={form} method="POST">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Archivo de Asistencia <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <input type="file" className="form-control" required onChange={handleFileInputChange}/>
          </Col>
        </Form.Group>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            type="reset"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
          >
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
