/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { AjaxService } from "./ajax";
import { Modules } from "../interfaces/interfaces";
const enableLog = true;
export const commonService = {
  alert(message: string, type?: SweetAlertIcon ) {
    return Swal.fire({
      title: message,
      icon: type,
    });
  },
  confirm(message: string) {
    return Swal.fire({
      title: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
    });
  },
  toast(message: string, type?:SweetAlertIcon){
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: "info",
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: type,
      title: message
    });
  },
  toCurrency(value: string){
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(parseFloat(value));
  },
  formatDate(value: string, format = 'DD/MM/YYYY'){
    return moment(value).format(format);
  },
  formatJSDate: (value: string) => new Date(value),
  formatISODate: (value: string) => moment(value).toISOString(),
  showPDF: async(b64File: string) => {
    return Swal.fire({
      width: '80%',
      // height: '100%',
      customClass: 'pdf-modal',
      html: `<iframe src="data:application/pdf;base64,${b64File}" style="height:100%; width: 100%"></iframe>`,
      showConfirmButton: false,
      showCloseButton: true,
      allowEscapeKey: true,
      allowOutsideClick: true,
    });
  },
  getLog: (module: string, id: number, page: number, perPage?: number, name?: string) => 
    AjaxService.get<any>({
      url: `${module}/${id}/log`,
      data: {
        page,
        perPage,
        name,
      },
    }),
  getModules: () => AjaxService.get<Modules[]>({ url: 'modules' }),
  log: (...params: any): any => enableLog && console.log({...params}),
  updateField: (field: string, value: any, id: number, module: string) =>
    AjaxService.put<any>({
      url: `update-field/${module}/${field}/${id}`,
      data: {
        value,
      },
    }),
};