/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import {
  Catalog,
  PaginationResult,
  Sale,
  statusSales,
} from "../../interfaces/interfaces";
// import { commonService } from "../../services/commonService";
// import { CreateBrand } from "./create";
import { Button, Modal } from "react-bootstrap";
// import { ViewBrand } from "./view";
import { useLayoutStore } from "../../stores/layoutStore";
import { commonService } from "../../services/commonService";
import { useAuthStore } from "../../stores/authStore";
import { sendersService } from "../../services/sendersService";
import { useSenderStore } from "../../stores/senderStore";
import { staffService } from "../../services/staffService";
import { EmployeeCreate } from "./create";
import { EmployeeEdit } from "./edit";

export function EmployeesIndex() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [, setObject] = useState<Catalog>();
  const moduleLabel = "Empleados";
  const auth = useAuthStore();
  const senderStore = useSenderStore();
  const [supply, setSupply] = useState<any>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setSupply(undefined);
    setShow(false);
  };
  const setModule = useLayoutStore((state) => state.setModule);
  useEffect(() => {
    setModule("Empleados");
  }, []);
  const headers = {
    id: {
      label: "ID",
      width: "10%",
    },
    name: {
      label: "Nombre",
      width: "50%",
    },
    code: {
      label: "Código",
      width: "20%",
    },
    actions: {
      label: "Acciones",
      width: "20%",
      render: ({ id }: { id: number }) => (
        <>
          <button
            className="btn btn-warning ms-2 mt-1"
            title="Editar"
            onClick={async (e) => {
              e.stopPropagation();
              handleShow();

              staffService.get(id).then((res) => {
                if (res.success) {
                  setSupply(res.object);
                }
              });
            }}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
          {auth.user?.role === "administrator" && (
            <button
              className="btn btn-danger ms-2 mt-2"
              title="Borrar"
              onClick={async (e) => {
                e.stopPropagation();
                const response = await commonService.confirm(
                  "¿Estas seguro de borrar la " +
                    moduleLabel +
                    "?, esta acción es irreversible"
                );
                if (response.isConfirmed) {
                  const res = await staffService.delete(id);
                  if (res.success) {
                    commonService.toast(
                      moduleLabel + " borrado con éxito",
                      "success"
                    );
                    reload();
                  } else {
                    commonService.toast(
                      "Error al borrar la " + moduleLabel,
                      "error"
                    );
                  }
                }
              }}
            >
              <i className="fa-solid fa-trash-can"></i>
            </button>
          )}
        </>
      ),
    },
  };

  const getObject = (page: number, filter?: string) =>
    staffService.getPagination(page, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        setPaginationResults(res.object);
      }
    });

  useMemo(() => getObject(page, filter), [page, filter]);

  const reload = () => getObject(page, filter);

  const [paginationResults, setPaginationResults] =
    useState<PaginationResult<any>>();
  const handleShow = () => setShow(true);

  return (
    <>
      <DataTable
        create={(close: () => void) => (
          <EmployeeCreate close={close} reload={reload} />
        )}
        onRowClick={viewItem}
        headers={headers}
        changePage={setPage}
        filter={setFilter}
        reload={reload}
        resultInfo={paginationResults}
      />
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Empleado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {supply && (
              <EmployeeEdit
                object={supply}
                close={handleClose}
                reload={reload}
              />
            )}
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
