/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Modal,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Catalog, Contacts, Suppliers, states } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { suppliersService } from "../../services/suppliersService";
import { catalogService } from "../../services/catalogService";

export interface EditCustomersProps {}

export const SupplierEdit: FC<EditCustomersProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState<Catalog[]>([])
  const [object, setObject] = useState<Suppliers>();
  const [contact, setContact] = useState<Contacts>();
  useEffect(() => {
    if (id) {
      refreshInfo();
    }
  }, []);

  const refreshInfo = () => {
    suppliersService.get(Number(id)).then((data) => {
      if (data.success) {
        const fields = data.object;
        setObject(fields);
        for (const field in fields) {
          const input: any = document.querySelector(`[name='${field}']`);
          if (input) {
            input.value = fields[field as keyof Suppliers];
          }
        }
      }
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
    suppliersService.update(Number(id), values).then((response) => {
      if(response.success){
        commonService.toast("Proveedor creado correctamente", "success");
        navigate("/movements/suppliers");
      }
    });
  };
  const handleSubmitContact = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setObject(undefined);
    setShow(false);
  };
  useEffect(() => {
    if (contact) {
      document
        ?.querySelector('[name="id"]')
        ?.setAttribute("value", `${contact.id}`);
      document
        ?.querySelector('[name="contact_name"]')
        ?.setAttribute("value", `${contact.name}`);
      document
        ?.querySelector('[name="contact_phone"]')
        ?.setAttribute("value", `${contact.phone}`);
      document
        ?.querySelector('[name="contact_email"]')
        ?.setAttribute("value", `${contact.email}`);
    }
  }, [contact]);

  useEffect(() => {
    catalogService.list('group', 1, 100).then((response) => {
      if(response.success){
        setGroup(response?.object?.data || [])
      }
    })
  }, [])

  const contactForm = useRef(null);
  return (
    <>
      <>
        <Form onSubmit={handleSubmit} method="POST">
          <Tabs defaultActiveKey="home" className="mb-3">
            <Tab eventKey="home" title="Información General">
              <Row>
                <h5>Cliente {id}</h5>
              </Row>
              <Form.Group as={Row} className="mb-3">
                <input type="hidden" name="id" value={id} />
                <Form.Label column sm="2">
                  Nombre <small className="text-danger">*</small>
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" name="name" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Dirección
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" name="address" />
                </Col>
              </Form.Group>
              <div className="row">
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Teléfono
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="phone" />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Email
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="email" />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Ciudad
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="city" />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Grupo
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="group_id" required>
                    <option></option>
                    {group.map((item, i) => (
                      <option
                        key={i}
                        value={`${item.id}`}
                        selected={item.id === Number(object?.group_id)} 
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Form.Group>
              </div>
              <div className="row">
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Estado
                  </Form.Label>
                  <Col sm="12">
                    <select className="form-select" name="state">
                      <option></option>
                      {states.map((item, i) => (
                        <option
                          key={i}
                          value={`${item}`}
                          selected={item === object?.state}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Sitio web
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="website" />
                  </Col>
                </Form.Group>
              </div>
            </Tab>
            <Tab eventKey="fiscal" title="Información Fiscal">
              <div className="row">

                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    RFC
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="rfc" style={{textTransform: "uppercase"}}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Código Postal
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="zipcode" />
                  </Col>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                  Uso de CFDI
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="use_cfdi">
                    <option></option>
                    <option selected={object?.use_cfdi === 'G01-Adquisición de mercancías.'} value="G01-Adquisición de mercancías.">G01-Adquisición de mercancías.</option>
                    <option selected={object?.use_cfdi === 'G02-Devoluciones, descuentos o bonificaciones.'} value="G02-Devoluciones, descuentos o bonificaciones.">G02-Devoluciones, descuentos o bonificaciones.</option>
                    <option selected={object?.use_cfdi === 'G03-Gastos en general.'} value="G03-Gastos en general.">G03-Gastos en general.</option>
                    <option selected={object?.use_cfdi === 'I01-Construcciones.'} value="I01-Construcciones.">I01-Construcciones.</option>
                    <option selected={object?.use_cfdi === 'I02-Mobiliario y equipo de oficina por inversiones.'} value="I02-Mobiliario y equipo de oficina por inversiones.">I02-Mobiliario y equipo de oficina por inversiones.</option>
                    <option selected={object?.use_cfdi === 'I03-Equipo de transporte.'} value="I03-Equipo de transporte.">I03-Equipo de transporte.</option>
                    <option selected={object?.use_cfdi === 'I04-Equipo de computo y accesorios.'} value="I04-Equipo de computo y accesorios.">I04-Equipo de computo y accesorios.</option>
                    <option selected={object?.use_cfdi === 'I05-Dados, troqueles, moldes, matrices y herramental.'} value="I05-Dados, troqueles, moldes, matrices y herramental.">I05-Dados, troqueles, moldes, matrices y herramental.</option>
                    <option selected={object?.use_cfdi === 'I06-Comunicaciones telefónicas.'} value="I06-Comunicaciones telefónicas.">I06-Comunicaciones telefónicas.</option>
                    <option selected={object?.use_cfdi === 'I07-Comunicaciones satelitales.'} value="I07-Comunicaciones satelitales.">I07-Comunicaciones satelitales.</option>
                    <option selected={object?.use_cfdi === 'I08-Otra maquinaria y equipo.'} value="I08-Otra maquinaria y equipo.">I08-Otra maquinaria y equipo.</option>
                    <option selected={object?.use_cfdi === 'D01-Honorarios médicos, dentales y gastos hospitalarios.'} value="D01-Honorarios médicos, dentales y gastos hospitalarios.">D01-Honorarios médicos, dentales y gastos hospitalarios.</option>
                    <option selected={object?.use_cfdi === 'D02-Gastos médicos por incapacidad o discapacidad.'} value="D02-Gastos médicos por incapacidad o discapacidad.">D02-Gastos médicos por incapacidad o discapacidad.</option>
                    <option selected={object?.use_cfdi === 'D03-Gastos funerales.'} value="D03-Gastos funerales.">D03-Gastos funerales.</option>
                    <option selected={object?.use_cfdi === 'D04-Donativos.'} value="D04-Donativos.">D04-Donativos.</option>
                    <option selected={object?.use_cfdi === 'D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).'} value="D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).">D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).</option>
                    <option selected={object?.use_cfdi === 'D06-Aportaciones voluntarias al SAR.'} value="D06-Aportaciones voluntarias al SAR.">D06-Aportaciones voluntarias al SAR.</option>
                    <option selected={object?.use_cfdi === 'D07-Primas por seguros de gastos médicos.'} value="D07-Primas por seguros de gastos médicos.">D07-Primas por seguros de gastos médicos.</option>
                    <option selected={object?.use_cfdi === 'D08-Gastos de transportación escolar obligatoria.'} value="D08-Gastos de transportación escolar obligatoria.">D08-Gastos de transportación escolar obligatoria.</option>
                    <option selected={object?.use_cfdi === 'D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.'} value="D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.">D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.</option>
                    <option selected={object?.use_cfdi === 'D10-Pagos por servicios educativos (colegiaturas).'} value="D10-Pagos por servicios educativos (colegiaturas).">D10-Pagos por servicios educativos (colegiaturas).</option>
                    <option selected={object?.use_cfdi === 'S01-Sin efectos fiscales.'} value="S01-Sin efectos fiscales.">S01-Sin efectos fiscales.</option>
                    <option selected={object?.use_cfdi === 'CP0-Pagos'} value="CP0-Pagos">CP0-Pagos</option>
                    <option selected={object?.use_cfdi === 'CN0-Nómina'} value="CN0-Nómina">CN0-Nómina</option>
                  </select>
                </Col>
              </Form.Group>


              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                  Regimen Fiscal
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="country">
                    <option></option>
                    <option selected={object?.country === '601-General de Ley Personas Morales'} value="601-General de Ley Personas Morales">601-General de Ley Personas Morales</option>
                    <option selected={object?.country === '603-Personas Morales con Fines no Lucrativos'} value="603-Personas Morales con Fines no Lucrativos">603-Personas Morales con Fines no Lucrativos</option>
                    <option selected={object?.country === '605-Sueldos y Salarios e Ingresos Asimilados a Salarios'} value="605-Sueldos y Salarios e Ingresos Asimilados a Salarios">605-Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                    <option selected={object?.country === '606-Arrendamiento'} value="606-Arrendamiento">606-Arrendamiento</option>
                    <option selected={object?.country === '607-Régimen de Enajenación o Adquisición de Bienes'} value="607-Régimen de Enajenación o Adquisición de Bienes">607-Régimen de Enajenación o Adquisición de Bienes</option>
                    <option selected={object?.country === '608-Demás ingresos'} value="608-Demás ingresos">608-Demás ingresos</option>
                    <option selected={object?.country === '610-Residentes en el Extranjero sin Establecimiento Permanente en México'} value="610-Residentes en el Extranjero sin Establecimiento Permanente en México">610-Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                    <option selected={object?.country === '611-Ingresos por Dividendos (socios y accionistas)'} value="611-Ingresos por Dividendos (socios y accionistas)">611-Ingresos por Dividendos (socios y accionistas)</option>
                    <option selected={object?.country === '612-Personas Físicas con Actividades Empresariales y Profesionales'} value="612-Personas Físicas con Actividades Empresariales y Profesionales">612-Personas Físicas con Actividades Empresariales y Profesionales</option>
                    <option selected={object?.country === '614-Ingresos por intereses'} value="614-Ingresos por intereses">614-Ingresos por intereses</option>
                    <option selected={object?.country === '615-Régimen de los ingresos por obtención de premios'} value="615-Régimen de los ingresos por obtención de premios">615-Régimen de los ingresos por obtención de premios</option>
                    <option selected={object?.country === '616-Sin obligaciones fiscales'} value="616-Sin obligaciones fiscales">616-Sin obligaciones fiscales</option>
                    <option selected={object?.country === '620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos'} value="620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos">620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                    <option selected={object?.country === '621-Incorporación Fiscal'} value="621-Incorporación Fiscal">621-Incorporación Fiscal</option>
                    <option selected={object?.country === '622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'} value="622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                    <option selected={object?.country === '623-Opcional para Grupos de Sociedades'} value="623-Opcional para Grupos de Sociedades">623-Opcional para Grupos de Sociedades</option>
                    <option selected={object?.country === '624-Coordinados'} value="624-Coordinados">624-Coordinados</option>
                    <option selected={object?.country === '625-Régimen de las Actividades Empresariales con ingresos a t'} value="625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                    <option selected={object?.country === '626-Régimen Simplificado de Confianza'} value="626-Régimen Simplificado de Confianza">626-Régimen Simplificado de Confianza</option>
                  </select>
                </Col>
              </Form.Group>
              </div>
            </Tab>
            
          </Tabs>
          <div className="text-center">
            <Button
              variant="danger"
              id="button-addon2"
              onClick={() => navigate(-1)}
            >
              Atrás
            </Button>
            <Button
              type="submit"
              variant="primary"
              id="button-addon2"
              className="ms-2"
            >
              Guardar
            </Button>
          </div>
        </Form>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Contacto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={contactForm} onSubmit={handleSubmitContact}>
              <input type="hidden" name="customer_id" value={id} />
              <input type="hidden" name="id" />
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Nombre <small className="text-danger">*</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  name="contact_name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Teléfono"
                  name="contact_phone"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="contact_email"
                />
              </Form.Group>
              <div className="text-center mt-3">
                <Button
                  variant="danger"
                  id="button-addon2"
                  onClick={() => setShow(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  id="button-addon2"
                  className="ms-2"
                >
                  Guardar
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};
