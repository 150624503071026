/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Staff } from "../interfaces/interfaces";

interface StaffState extends Staff {
}

interface StaffActions {
  setValues: (key: string, value: any) => void;
  reset: () => void;
  getState: () => StaffState;
  initSale: (init: Partial<StaffState>) => void;
}

const initialState: StaffState = {
  id: 0,
  name: '',
  code: '',
  email: '',
  position: '',
  start_on: '',
};

export const useStaffStore = create<StaffState & StaffActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setValues: (key, value) => set({ [key]: value }),
        initSale: (init) =>
          set({
            ...init,
          }),
        reset: () => {
          set({ ...initialState });
        },
        getState: () => ({
          ...initialState,
          id: get().id,
          name:  get().name,
          code:  get().code,
          email:  get().email,
          position:  get().position,
          start_on:  get().start_on
        }),
      }),
      {
        name: "crisa-senders",
      }
    )
  )
);
