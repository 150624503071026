import { PaginationResult, Product } from "../interfaces/interfaces";
import { AjaxService } from "./ajax";
import { commonService } from "./commonService";
const basePath = "products";
export const productsService = {
  getPagination: (page: number, perPage?: number, name?: string, products_id?: string) => {
    commonService.log({products_id})
    return AjaxService.get<PaginationResult<Product>>({
      url: `${basePath}`,
      data: {
        page,
        perPage,
        name,
        products_id,
      },
    })
  },
  delete: (id: number) =>
    AjaxService.delete<Product>({
      url: `${basePath}/${id}`,
    }),
  create: (product: Partial<Product>) =>
    AjaxService.post<Product>({
      url: `${basePath}`,
      data: product,
    }),
  get: (id: number) =>
    AjaxService.get<Product>({
      url: `${basePath}/${id}`,
    }),
  update: (id: number, product: Partial<Product>) =>
    AjaxService.put<Product>({
      url: `${basePath}/${id}`,
      data: product,
    }),
};
