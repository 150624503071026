/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Catalog, Modules, User, mapModuleNames } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { usersService } from "../../services/usersService";

interface propsViewUser {
  close?: () => void;
  reload?: () => void;
  object?: User;
}

export const ViewUser: React.FC<propsViewUser> = ({
  close,
  reload,
  object,
}) => {
  let execute = true;
  const [modules, setModules] = useState<Modules[]>();
  const form = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (object) {
      document
        .querySelector("[name='id']")
        ?.setAttribute("value", `${object.id}`);
      document
        .querySelector("[name='name']")
        ?.setAttribute("value", object.name);
      document
        .querySelector("[name='email']")
        ?.setAttribute("value", object.email);

      commonService.getModules().then(response => {
        if(response.success){
          setModules(response?.object?.map(module => ({...module, active: object.permissions.find(v => v.module.name === module.name) ? true : false})));
        }
      });
      
    }
  }, []);
  useEffect(() => {
    if(modules && object && execute){
      execute = false;
      for (let index = 0; index < modules.length; index++) {
        const element = modules[index];
        const active = object.permissions.find(v => v.module.name === element.name) ? true : false;
        if(active){
          document
            .getElementById(`s-${element.name}`)
            ?.setAttribute("checked", "checked");
        }
        
      }
    }
  }, [modules]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const product: Record<string, any> = {
      id: 0,
      name: "",
    };
    for (const [key, value] of data.entries()) {
      if(!modules?.find(module => module.name === key)){
        product[key] = value;
      }
    }
    product.permissions = modules;

    const pss = product['password'].trim();
    if(pss !== '' && pss.length < 10){
      commonService.toast("La contraseña debe tener al menos 10 caracteres, los espacios en blanco no son validos.", 'error');
      return;
    }
    
    usersService.update(product.id, product as Catalog).then((res) => {
      if (res.success) {
        commonService.toast("Usuario actualizado correctamente", "success");
        close && close();
        reload && reload();
        return;
      }
      commonService.toast("Error al actualizar Usuario", "error");
    });
  };

  return (
    <>
      {object ? (
        <Form onSubmit={handleSubmit} method="POST" ref={form}>
          <input type="hidden" name="id" />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Nombre <small className="text-danger">*</small>
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" name="name" required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Usuario <small className="text-danger">*</small>
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" name="email" required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Contraseña <small className="text-danger">Solo llenar si requiere cambiar</small>
            </Form.Label>
            <Col sm="10">
              <Form.Control type="password" name="password"/>
            </Col>
          </Form.Group>
          <Row>
            {
              object.role !== 'administrator' && (
                <Col sm={12} className="mt-2 mb-2">
                  <strong>Permisos a los Módulos</strong>
                </Col>
              )
            }
            {
              object.role !== 'administrator' && modules?.map((module, index) => (
                <Col sm={4} key={`m-${index}`}>
                  <Form.Check
                    type="switch"
                    key={`s-${index}`}
                    id={`s-${module.name}`}
                    label={mapModuleNames[module.name as keyof typeof mapModuleNames]}
                    checked={module.active}
                    onChange={(e) => {
                       
                      const newModules = modules?.map(m => {
                        if(m.name === module.name){
                          m.active = e.target.checked ?? false;
                        }
                        return m;
                      });
                       
                      setModules(newModules);}
                    }
                    name={module.name}
                  />
                </Col>
              ))
            }
            
          </Row>
          
          <div className="text-center">
            <Button
              variant="danger"
              id="button-addon2"
              onClick={() => close && close()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              id="button-addon2"
              className="ms-2"
            >
              Guardar
            </Button>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <h3>Cargando</h3>
        </div>
      )}
    </>
  );
};
