/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { useAuthStore } from "../stores/authStore";
import { Response } from "../interfaces/interfaces";
import { authService } from "./authService";
import { commonService } from "./commonService";

export const apiBase = process.env.REACT_APP_API_URL;
const authorization = useAuthStore.getState().authorization;
const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${authorization?.token}`,
};
axios.interceptors.response.use(
  (res) => {
    if (res.data.message === "Unauthorized" && res.status === 200) {
      // commonService.toast('No', 'error');
      commonService.alert(
        "Esta intentando entrar a un area no autorizada, su sesión sera cerrada por seguridad.",
        "error"
      );
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PREFIX ?? "/");
    }
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      authService.logout();
      window.location.replace(process.env.REACT_APP_PREFIX ?? "/");
      return;
    }
    throw err;
  }
);

export const AjaxService = {
  async post<T>({
    url,
    data,
    method = "POST",
    headers,
  }: {
    url: string;
    data?: any;
    method?: "POST" | "DELETE" | "PUT";
    headers?: Record<string, any>;
  }) {
    return axios<Response<T>>({
      url: apiBase + url,
      data,
      method: method,
      headers: !headers ? defaultHeaders : headers,
    })
      .then((res: { data: any }) => res.data as Response<T>)
      .catch((err: { message: any; response: { data: { message: any } } }) => {
        let message = err.message;
        if (err.response?.data?.message) {
          message = err.response?.data?.message;
        }
        return Promise.resolve({
          success: false,
          message: message,
          object: undefined,
        } as Response<T>);
      });
  },
  async get<T>({
    url,
    data = {},
    headers,
  }: {
    url: string;
    data?: any;
    headers?: Record<string, any>;
  }) {
    const qs =
      "?" +
      Object.keys(data)
        .map((key) =>
          key === "filters"
            ? `${key}=${encodeURIComponent(JSON.stringify(data[key]) ?? "")}`
            : `${key}=${encodeURIComponent(data[key] ?? "")}`
        )
        .join("&");
    return axios<Response<T>>({
      url: apiBase + url + qs,
      method: "GET",
      headers: !headers ? defaultHeaders : headers,
    })
      .then((res: { data: any }) => res.data as Response<T>)
      .catch((err: { message: any }) => {
        return Promise.resolve({
          success: false,
          message: err.message,
          object: undefined,
        } as Response<T>);
      });
  },
  async delete<T>({
    url,
    data,
    headers = undefined,
  }: {
    url: string;
    data?: any;
    headers?: Record<string, any>;
  }) {
    return AjaxService.post<T>({ url, data, method: "DELETE", headers });
  },
  async put<T>({
    url,
    data,
    headers = undefined,
  }: {
    url: string;
    data?: any;
    headers?: Record<string, any>;
  }) {
    return AjaxService.post<T>({ url, data, method: "PUT", headers });
  },
};
