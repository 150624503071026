/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import { Customer, PaginationResult } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
// import { CreateProduct } from "./create";
// import { Modal } from "react-bootstrap";
import { customerService } from "../../services/customersService";
import { CustomersCreate } from "./create";
import { useLayoutStore } from "../../stores/layoutStore";
import { useAuthStore } from "../../stores/authStore";
// import { ViewProduct } from "./view";

interface CustomersIndexProps {
  type?: 'inside';
  insideContent?: (object: any) => void;
  createContent?: () => ReactNode;
}

export function CustomersIndex({ type, insideContent, createContent}: CustomersIndexProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item)
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const auth = useAuthStore();
  const moduleLabel = 'Clientes';
  const setModule = useLayoutStore(state => state.setModule);
  useEffect(() => {
    setModule(moduleLabel);
  }, []);
  // const [, setObject] = useState<Customer>();
  const headers = {
    id: {
      label: "ID",
    },
    name: {
      label: "Nombre"
    },
    phone: {
      label: "Telefono",
    },
    actions: {
      label: "Acciones",
      render: (object: any) => <>
        {
          type === 'inside' && (
            insideContent && insideContent(object)
          )
        }
        {
          !type && (
            <>
              <a className="btn btn-warning ms-2 mt-1" title="Editar" href={`/movements/customers/${object?.id}`}>
                <i className="fa-solid fa-pen-to-square"></i>
              </a>
              {
                auth.user?.role === 'administrator' && (
                  <button className="btn btn-danger ms-2 mt-1" title="Borrar" onClick={async (e) => {
                    e.stopPropagation();
                    const response = await commonService.confirm('¿Estas seguro de borrar el Cliente?, esta acción es irreversible');
                    if(response.isConfirmed){
                       
                      const res = await customerService.delete(object?.id);
                      if(res.success){
                        commonService.toast('Cliente borrado con éxito', 'success');
                        reload();
                      }else{
                        commonService.toast('Error al borrar el Cliente', 'error');
                      }
                    }
                  }}>
                    <i className="fa-solid fa-trash-can"></i>
                  </button>
                )
              }
            </>
          )
        }
      </>
    }
  };
  
  const getObjects = (page: number, filter?: string) => customerService.getPagination(page, 10, filter).then((res) => {
    if (res.success && res.object?.data) {
      setPaginationResults(res.object);
    }
  });

  useMemo(() => getObjects(page, filter), [page, filter]);
  
  const reload = () => getObjects(page, filter);
  

  const [paginationResults, setPaginationResults] = useState<PaginationResult<Customer[]>>();
  // const [show, setShow] = useState(false);
  // 
  // const handleClose = () => {
  //   setObject(undefined);
  //   setShow(false);
  // };
  // const handleShow = () => setShow(true);
  
  return (
    <>
      {
        type === 'inside' && createContent && (
          <DataTable
            create={() => createContent()}
            onRowClick={viewItem}
            headers={headers}
            changePage={setPage}
            filter={setFilter}
            reload={reload}
            resultInfo={paginationResults}  
          />
        )
        
      }
      {
        type === 'inside' && !createContent && (
          <DataTable
            // create={(close: () => void) => createContent()}
            onRowClick={viewItem}
            headers={headers}
            changePage={setPage}
            filter={setFilter}
            reload={reload}
            resultInfo={paginationResults}  
          />
        )
      }
      {
        !type && (
          <DataTable
            create={(close: () => void) => <CustomersCreate close={close} reload={reload}/>}
            onRowClick={viewItem}
            headers={headers}
            changePage={setPage}
            filter={setFilter}
            reload={reload}
            resultInfo={paginationResults}  
          />
        )
      }
    </>
  );
}
