/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import { Catalog, PaginationResult, Sale, statusSales } from "../../interfaces/interfaces";
// import { commonService } from "../../services/commonService";
// import { CreateBrand } from "./create";
import { Button, Modal } from "react-bootstrap";
// import { ViewBrand } from "./view";
import { useLayoutStore } from "../../stores/layoutStore";
import { commonService } from "../../services/commonService";
import { useAuthStore } from "../../stores/authStore";
import { sendersService } from "../../services/sendersService";
import { useSenderStore } from "../../stores/senderStore";
import { render } from "react-dom";
import { SenderCreate } from "./create";

export function SenderIndex() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item)
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [, setObject] = useState<Catalog>();
  const moduleLabel = 'Remitentes';
  const auth = useAuthStore();
  const senderStore = useSenderStore();
  // const base = process.env.REACT_APP_API_URL;
  const setModule = useLayoutStore(state => state.setModule);
  const handleShow = () => setShow(true);
  const [supply, setSupply] = useState<any>();
  useEffect(() => {
    setModule("Remitentes");
  }, []);
  const headers = {
    id: {
      label: "ID",
      width: "10%",
      render: (row: any) => <a href={`/movements/senders/${row?.id}`} className="link">{row?.id}</a>
    },
    deliver_by: {
      label: "Paqueteria",
      width: "40%",
    },
    is_payed: {
      label: "Pagado",
      width: "30%",
      render: (row: any) => row.is_payed === '1' ? 'Pagado' : 'Por Cobrar'
    },
    actions: {
      label: "Acciones",
      width: "20%",
      render: ({id}: {id: number}) => <>
        <Button variant="primary" title="Ver PDF" className="ms-2 mt-2" onClick={async () => {
          const response = await sendersService.getPdf(id);
          if(response.success){
            commonService.showPDF(response?.object?.pdf ?? '');
          }
        }}>
          <i className="fa-regular fa-file-pdf"></i>
        </Button>
        <button
            className="btn btn-warning ms-2 mt-1"
            title="Editar"
            onClick={async (e) => {
              e.stopPropagation();
              handleShow();
              sendersService.get(id).then((res) => {
                console.log({res})
                if (res.success) {
                  setSupply(res.object);
                }
              });
            }}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
        {
          auth.user?.role === 'administrator' && (
            <button className="btn btn-danger ms-2 mt-2" title="Borrar" onClick={async (e) => {
              e.stopPropagation();
              const response = await commonService.confirm('¿Estas seguro de borrar la ' + moduleLabel + '?, esta acción es irreversible');
              if(response.isConfirmed){
                const res = await sendersService.delete(id);
                if(res.success){
                  commonService.toast(moduleLabel + ' borrado con éxito', 'success');
                  reload();
                }else{
                  commonService.toast('Error al borrar la ' + moduleLabel, 'error');
                }
              }
            }}>
              <i className="fa-solid fa-trash-can"></i>
            </button>
          )
        }
      </>
    }
  };
  
  const getObject = (page: number, filter?: string) => sendersService.getPagination(page, 10, filter).then((res) => {
    if (res.success && res.object?.data) {
      setPaginationResults(res.object);
    }
  });
    
  useMemo(() => getObject(page, filter), [page, filter]);
  
  const reload = () => {
    setSupply(null)
    getObject(page, filter)
  };
  
  const [paginationResults, setPaginationResults] = useState<PaginationResult<any>>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setObject(undefined);
    setShow(false);
  };
  // const handleShow = () => setShow(true);
  
  return (
    <>
      <DataTable
        // create={(close: () => void) => <CreateBrand close={close} reload={reload}/>}
        createUrl="/movements/senders/create"
        onRowClick={viewItem}
        headers={headers}
        changePage={setPage}
        filter={setFilter}
        reload={reload}
        resultInfo={paginationResults}  
      />
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              supply && <SenderCreate object={supply} close={handleClose} reload={reload}/>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
