/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../services/commonService";
import { sendersService } from "../../services/sendersService";
import { useLayoutStore } from "../../stores/layoutStore";
import { useStaffStore } from "../../stores/staffStore";
import { staffService } from "../../services/staffService";
import { Staff } from "../../interfaces/interfaces";

export interface SalesCreateProps {
  close?: () => void;
  reload?: () => void;
  object?: Staff;
}

export const EmployeeEdit: FC<SalesCreateProps> = ({ reload, close, object }) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const employeeStore = useStaffStore()
  const setModule = useLayoutStore((state) => state.setModule);
  useEffect(() => {
    setModule("Empleados");
  }, []);
  const backToList = () => {
    employeeStore.reset();
    reload && reload();
    close && close();
  };
  // const [destiny, setDestiny] = useState();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = employeeStore.getState();
    commonService.log({ payload });
    // if (!payload.sale_id) {
    //   commonService.toast("Debe seleccionar una venta", "error");
    //   return;
    // }
    setIsLoading(true);

    // return;
    const response = await staffService.update(Number(payload.id), payload);
    if (response.success) {
      commonService.toast(response.message, "success");
      backToList();
      setIsLoading(false);
      return;
    }
    commonService.toast(response.message, "error");
    setIsLoading(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  // useEffect(() => {
  //   salesStore.calculateTotal();
  // }, [salesStore.details, salesStore.discount]);

  useEffect(() => {
    if(object){
      employeeStore.setValues("id", object.id)
      employeeStore.setValues("name", object.name)
      employeeStore.setValues("code", object.code)
    }
    setLoad(false);
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit} method="POST">
        <Row>
          <h5>Empleado</h5>
        </Row>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Nombre
          </Form.Label>
          <Col sm="10">
            <Form.Control
              name="name"
              value={employeeStore.name}
              onChange={(e) =>
                employeeStore.setValues("name", e.target.value)
              }
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Código de Empleado
          </Form.Label>
          <Col sm="10">
            <Form.Control
              name="code"
              value={employeeStore.code}
              onChange={(e) =>
                employeeStore.setValues("code", e.target.value)
              }
              required
            />
          </Col>
        </Form.Group>


        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => {
              employeeStore.reset();
              close && close();
            }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
            disabled={isLoading}
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
