/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Deliveries, Sender } from "../interfaces/interfaces";

interface SenderState extends Sender {
}

interface SenderActions {
  setValues: (key: string, value: any) => void;
  reset: () => void;
  getState: () => SenderState;
  initSale: (init: Partial<SenderState>) => void;
}

const initialState: SenderState = {
  id: '0',
  customer_id: '',
  sale_id: '',
  sale: undefined,
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  rfc: '',
  type: '',
  is_payed: '',
  deliver_by: '',
  created_at: '',
  updated_at: '',
  notes: '',
};

export const useSenderStore = create<SenderState & SenderActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setValues: (key, value) => set({ [key]: value }),
        initSale: (init) =>
          set({
            ...init,
          }),
        reset: () => {
          set({ ...initialState });
        },
        getState: () => ({
          ...initialState,
          customer_id: get().customer_id,
          sale_id: get().sale_id,
          sale: get().sale,
          name: get().name,
          address: get().address,
          city: get().city,
          state: get().state,
          zip: get().zip,
          phone: get().phone,
          rfc: get().rfc,
          type: get().type,
          is_payed: get().is_payed,
          deliver_by: get().deliver_by,
          notes: get().notes,
          id: get().id,
        }),
      }),
      {
        name: "crisa-senders",
      }
    )
  )
);
