import { Contacts, Customer, PaginationResult } from "../interfaces/interfaces";
import { AjaxService } from "./ajax";

const basePath = 'customers';

export const customerService = {
  getPagination: (page: number, perPage?: number, name?: string) =>
    AjaxService.get<PaginationResult<Customer[]>>({
      url: `${basePath}`,
      data: {
        page,
        perPage,
        name,
      },
    }),
  delete: (id: number) =>
    AjaxService.delete<Customer>({
      url: `${basePath}/${id}`,
    }),
  create: (product: Partial<Customer>) =>
    AjaxService.post<Customer>({
      url: `${basePath}`,
      data: product,
    }),
  get: (id: number) =>
    AjaxService.get<Customer>({
      url: `${basePath}/${id}`,
    }),
  update: (id: number, product: Partial<Customer>) =>
    AjaxService.put<Customer>({
      url: `${basePath}/${id}`,
      data: product,
    }),
  getContacts: (id: number) =>
    AjaxService.get<Contacts>({
      url: `contacts/${id}`,
    }),
  upsertContacts: (contacts: Partial<Contacts>) =>
    AjaxService.post<Contacts>({
      url: `contacts`,
      data: contacts,
    }),
  deleteContact: (id: number) =>
    AjaxService.delete<Contacts>({
      url: `contacts/${id}`,
    }),
};
