/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { DataTable } from "../../components/datatable";
import { PaginationResult } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { salesPaymentsService } from "../../services/salesPaymentsService";

interface ListSalesPaymentsProps {
  id: string;
}

export const ListSalesPayments: React.FC<ListSalesPaymentsProps> = ({
  id,
}) => {
  const [load, setLoad] = React.useState(false);
  const [page, setPage] = useState(1);
  const [filter] = useState("");
  const [paginationResults, setPaginationResults] =
    useState<PaginationResult<any>>();
  const getObject = (page: number) =>
    salesPaymentsService.getPagination(page, 10, id).then((res) => {
      console.log({res});
      if (res.success && res.object?.data) {
        setPaginationResults(res.object);
        setLoad(false);
      }
    });

  useMemo(() => getObject(page), [page, filter]);

  const reload = () => {
    setLoad(true);
    getObject(page);
  };
  useEffect(() => {
    setLoad(true);
  }, []);
  const headers = {
    id: {
      label: "ID",
      width: "10%",
    },
    amount: {
      label: "Monto Pagado",
      width: "50%",
      format: (row: any) => commonService.toCurrency(row),
    },
    user: {
      label: "Usuario",
      width: "20%",
      render: (row: any) => row?.user?.name,
    },
    created_at: {
      label: "Fecha de Pago",
      width: "20%",
      format: (row: string) =>
        commonService.formatDate(row, "DD/MM/YYYY hh:mm a"),
    },
  };
  return (
    <>
      {load && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!load && (
        <DataTable
          // create={(close: () => void) => <CreateBrand close={close} reload={reload}/>}
          onRowClick={() => true}
          headers={headers}
          changePage={setPage}
          reload={reload}
          resultInfo={paginationResults}
          simple
          filter={() => true}
        />
      )}
    </>
  );
};
