/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { catalogService } from "../../services/catalogService";
import { Catalog } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";

interface propsCreateCategory {
  close?: () => void;
  reload?: () => void;
}

export const CreateCategory: React.FC<propsCreateCategory> = ({
  close,
  reload,
}) => {
  useEffect(() => {
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const object: Record<string, any> = {};
    for (const [key, value] of data.entries()) {
      object[key] = value;
    }
    catalogService.create('category', object as Catalog).then((res) => {
      if (res.success) {
        commonService.toast("Categoría creada con éxito", "success");
        close && close();
        reload && reload();
      } else {
        commonService.toast("Error al crear la Categoría", "error");
      }
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} method="POST">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Nombre <small className="text-danger">*</small>
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" name="name" required />
          </Col>
        </Form.Group>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => close && close()}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
          >
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
