import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/authStore";
import './main.css';
import { Navbar, Container, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
// import { Breadcrumb } from "../components/breadcrumb";
import { SideBar } from "../components/sidebar";
import { useLayoutStore } from "../stores/layoutStore";
import crisa from "../../public/assets/images/CrisaL.png";
export const Main: React.FC = () => {
  const auth = useAuthStore();
  const navigate = useNavigate();
  const title = useLayoutStore((state) => state.module);
  const [, setCurrentPath] = useState('');
  const logout = () => {
    auth.logout();
    navigate('/login');
  };
  const location = useLocation();
  useEffect(() => {
    auth.refresh(auth.authorization);
    setCurrentPath(location.pathname.replace(/\//, '').toLocaleLowerCase());
  }, []);
  const expand = 'lg';
  return (
    <>
        
      <Navbar expand={expand} className="navbar text-light">
        <Container fluid>
          <Navbar.Brand href="#">PROEMSA ADMIN</Navbar.Brand> 
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Crisa Admin
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 user-menu">
                <NavDropdown
                  title={
                    <><button className="btn bg-transparent p-0 m-0" onClick={logout}><i className="fa-solid fa-right-from-bracket text-light"></i></button><strong className="ms-2 text-light">{auth.user?.name} {auth.user?.lastname}</strong></>
                  }
                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                  align={"start"}
                >
                  <NavDropdown.Item onClick={logout}>
                      Cerrar Sesión
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <SideBar />
      
      <div className="col">
        <div className="row justify-content-end m-0 p-0">
          <main className="col-10 mt-3" style={{marginBottom:'80px'}}>
            {/* <Breadcrumb title={currentPath}/> */}
            <h1 className="h2">{title}</h1>
            <div className="container">
              <Outlet />
            </div>
          </main>
          <div className="footer col-10">
            <p>Copyright © 2024. Todos los derechos reservados.</p>
            <div className="position-absolute" style={{bottom: 10, right: 30, zIndex: 99}}><span className="texto-crisa">Developer by</span> <img src={crisa} style={{height:'40px'}}alt="" className="logo"/> </div>
          </div>
        </div>
        
      </div>
      
    </>
  );
};
