/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { customerService } from "../../services/customersService";
// import { Customer } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
// import { ViewUser } from "../users/view";
import { Destiny } from "../../components/destiny";
import { useQuotesStore } from "../../stores/quotesStore";
import moment from "moment";
import { states } from "../../interfaces/interfaces";
import AsyncSelect from 'react-select/async';
import { productsService } from "../../services/productsService";
import { quotesService } from "../../services/quotesService";



export interface QuotesCreateProps {
  close?: () => void;
  reload?: () => void;
}

export const QuotesCreate: FC<QuotesCreateProps> = ({reload}) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const backToList = () => {
    quotesStore.reset();
    navigate('/movements/quotes');
  };
  const [loading, setLoading] = useState(false);
  const quotesStore = useQuotesStore();
  // const [destiny, setDestiny] = useState();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = quotesStore.getState();
    if(payload.details?.length === 0){
      commonService.toast("Debe agregar productos a la cotización", "error");
      return;
    }
    if(!payload.destiny){
      commonService.toast("Debe seleccionar un destino", "error");
      return;
    }
    setLoading(true);
    const response = await quotesService.create(payload);
    if(response.success){
      commonService.toast(response.message, "success");
      backToList();
      setLoading(false);
      return;
    }
    commonService.toast(response.message, "error");
    setLoading(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setObject(undefined);
    setShow(false);
  };
  const getProducts = (filter?: string) => productsService.getPagination(1, 10, filter).then((res) => {
    if (res.success && res.object?.data) {
      // commonService.log('obj', res.object.data)
      return res.object.data.map(element => ({...element, value: element.id, label: element.name}));
    }
    return [];
  });
  const addItem = (element: any) => {
    if(element){
      const item = quotesStore?.details?.find(item => item.id === element.id);
      if(!item) quotesStore.addItem(element);
    }
  };
  useEffect(() => {
    quotesStore.calculateTotal();
  }, [quotesStore.details, quotesStore.discount]);
  
  useEffect(() => {
    quotesStore.reset();
    setLoad(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Destiny close={handleClose} reload={reload}/>
        </Modal.Body>
      </Modal>
      {
        load && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )
      }
      {
        !load && (
          <Form onSubmit={handleSubmit} method="POST">
            <Row className="mt-5">
              <Col sm="6">
                <Form.Group as={Row} className="mb-3">
                  
                  <Form.Label column sm="2">
                      Proyecto
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" name="name" required 
                      value={quotesStore.name} onChange={(e) => quotesStore.setValues('name', e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Button variant="primary" onClick={() => setShow(true)}>Selecciona Cliente</Button>
              </Col>
            </Row>
            {
              quotesStore.destiny && (
                <>
                  <hr />
                  <Row>
                    <Col sm="6">
                      {
                        quotesStore.destiny?.type === 'Clientes' ? 
                          <p><strong>Cliente: </strong> {quotesStore.destiny?.name}<br/><strong>RFC: </strong> {quotesStore.destiny?.rfc}</p> : 
                          <p><strong>Proveedor: </strong> {quotesStore.destiny?.name}</p>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                  Creación <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control type="date" name="created" required defaultValue={moment().format("YYYY-MM-DD")}/>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                  Expiración <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control type="date" name="expiration_date" required  
                            value={quotesStore.expiration_date} onChange={(e) => quotesStore.setValues('expiration_date', e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                        Dirección <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="address"
                            value={quotesStore.destiny.address} onChange={(e) => quotesStore.setDestiny({...quotesStore.destiny, address: e.target.value})}
                            required
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Ciudad <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control type="text" name="created" required value={quotesStore?.destiny?.city} onChange={
                            (e) => quotesStore.setDestiny({...quotesStore.destiny, city: e.target.value})
                          }/>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col as={Row}>
                      <Form.Label column sm="3">
                        Estado
                      </Form.Label>
                      <Col sm="9">

                        <Form.Select aria-label="Default select example" name="state" className="form-control"
                          value={quotesStore?.destiny?.state} onChange={
                            (e) => quotesStore.setDestiny({...quotesStore.destiny, state: e.target.value})
                          }
                        >
                          <option></option>
                          {states.map((item, i) => (
                            <option
                              key={i}
                              value={`${item}`}
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Select>

                        
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Código Postal <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control type="text" name="zipcode" required 
                            value={quotesStore?.destiny?.zipcode} onChange={
                              (e) => quotesStore.setDestiny({...quotesStore.destiny, zipcode: e.target.value})
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col as={Row}>
                      <Form.Label column sm="3">
                        País
                      </Form.Label>
                      <Col sm="9">
                        <Form.Select aria-label="Default select example" name="country" className="form-control">
                          <option value="México">México</option>
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Email <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control type="email" name="email" required 
                            value={quotesStore?.destiny?.email} onChange={
                              (e) => quotesStore.setDestiny({...quotesStore.destiny, email: e.target.value})
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col as={Row}>
                      <Form.Label column sm="3">
                        Teléfono <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control type="text" name="phone" 
                          value={quotesStore?.destiny?.phone} onChange={
                            (e) => quotesStore.setDestiny({...quotesStore.destiny, phone: e.target.value})
                          }
                          required
                        />
                      </Col>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-3 mb-3">
                    <AsyncSelect cacheOptions defaultOptions loadOptions={getProducts} onChange={addItem} />
                  </Row>
                  <Row>
                    <Table striped bordered hover className="w-100">
                      <thead>
                        <tr>
                          <th style={{width: "50%"}}>Producto</th>
                          <th style={{width: "10%"}}>Cantidad</th>
                          <th style={{width: "10%"}}>Precio / Costo</th>
                          <th style={{width: "10%"}}>Impuesto</th>
                          <th style={{width: "15%"}}>Total</th>
                          <th style={{width: "5%"}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          quotesStore?.details && quotesStore?.details?.map((element: any, index) => (
                            <tr key={`key-${index}`}>
                              <td>
                                {element.name}
                              </td>
                              <td>
                                <Form.Control type="number" name="quantity" required value={element.quantity} onChange={(e) => {
                                  quotesStore.updateItem(index, {quantity: e.target.value});
                                  quotesStore.calculateTotal();
                                }} step="1.00"/>
                              </td>
                              <td>
                                <Form.Label>
                                  Precio: 
                                </Form.Label>
                                <Form.Control type="number" name="price" required value={element.price} onChange={(e) => {
                                  quotesStore.updateItem(index, {price: e.target.value});
                                  quotesStore.calculateTotal();
                                }} step="1.00"/>
                                <Form.Label>
                                  Costo: 
                                </Form.Label>
                                <Form.Control type="number" name="cost" required value={element.cost} onChange={(e) => quotesStore.updateItem(index, {cost: e.target.value})} step="1.00"/>
                              </td>
                              <td>
                                <Form.Select aria-label="Default select example" name="iva" value={element.tax_percent} onChange={(e) => {
                                  quotesStore.updateItem(index, {tax_percent: e.target.value});
                                  quotesStore.calculateTotal();
                                }}>
                                  <option value="0.00">0 %</option>
                                  <option value="0.16">16 %</option> 
                                </Form.Select>
                              </td>
                              <td className="text-right">
                                {element.total.toFixed(2)}
                              </td>
                              <td>
                                <Button variant="danger" onClick={() => {
                                  quotesStore.removeItem(index);
                                  quotesStore.calculateTotal();
                                }}>
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))
                        }
                        {
                          quotesStore?.details && quotesStore?.details?.length > 0 && (
                            <>
                              <tr>
                                <td colSpan={3}></td>
                                <td>Subtotal</td>
                                <td className="text-right">{Number(quotesStore.subtotal)?.toFixed(2)}</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colSpan={3}></td>
                                <td>Impuesto</td>
                                <td className="text-right">{Number(quotesStore.tax)?.toFixed(2)}</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colSpan={3}></td>
                                <td>Descuento</td>
                                <td className="text-right">
                                  <Form.Control type="number" className="text-right" name="discount" value={quotesStore.discount} onChange={(e) => quotesStore.setValues('discount', e.target.value)} step="1.00"/>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colSpan={3}></td>
                                <td>Total</td>
                                <td className="text-right">{Number(quotesStore.total)?.toFixed(2)}</td>
                                <td></td>
                              </tr>
                            </>
                          )
                        }

                      </tbody>
                    </Table>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Observaciones
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control as="textarea" rows={5} name="notes" value={quotesStore.notes} onChange={(e) => quotesStore.setValues('notes', e.target.value)}/>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )
            }
            
            <div className="text-center">
              <Button
                variant="danger"
                id="button-addon2"
                disabled={loading}
                onClick={async () => {
                  const res = await commonService.confirm("Se perderán los cambios no guardados, ¿Esta seguro?");
                  if(res.isConfirmed){
                    backToList();
                  }
                }}
              >
                  Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                id="button-addon2"
                className="ms-2"
                disabled={loading}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                  hidden={!loading}
                />
                  Guardar
              </Button>
            </div>
          </Form>

        )
      }
    </>
  );
};
