/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react'
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  Spinner,
  Tab,
  Tabs,
} from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { commonService } from '../../services/commonService'
import { states } from '../../interfaces/interfaces'
import AsyncSelect from 'react-select/async'
import { productsService } from '../../services/productsService'
import { useLayoutStore } from '../../stores/layoutStore'
import { Logs } from '../../components/logs'
import { salesService } from '../../services/salesService'
import { useSalesStore } from '../../stores/salesStore'
import { ListSalesPayments } from './listSalesPayments'

export interface SalesEditProps {
  close?: () => void
  reload?: () => void
}

export const SalesEdit: FC<SalesEditProps> = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)
  const setModule = useLayoutStore((state) => state.setModule)
  const [disableEdit, setDisableEdit] = useState(true)
  const backToList = () => {
    salesStore.reset()
    navigate('/movements/sales')
  }
  const [loading, setLoading] = useState(false)
  const salesStore = useSalesStore()
  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const payload = salesStore.getState()
    if (payload.details?.length === 0) {
      commonService.toast('Debe agregar productos a la venta', 'error')
      return
    }

    setLoading(true)
    const response = await salesService.update(Number(id ?? 0), payload)
    if (response.success) {
      commonService.toast(response.message, 'success')
      backToList()
      setLoading(false)
      return
    }
    commonService.toast(response.message, 'error')
    setLoading(false)
  }

  const getProducts = (filter?: string) =>
    productsService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }))
      }
      return []
    })
  const addItem = (element: any) => {
    if (element) {
      const item = salesStore?.details?.find((item) => item.id === element.id)
      if (!item) salesStore.addItem(element)
    }
  }
  useEffect(() => {
    salesStore.calculateTotal()
  }, [salesStore.details, salesStore.discount])

  useEffect(() => {
    if (id) {
      setLoad(true)
      salesService.get(Number(id)).then((res) => {
        if (res.success && res.object) {
          const { customer, sales_details: quotesDetails, ...rest } = res.object
          commonService.log({ customer, quotesDetails, rest })
          salesStore.initSale(res.object)
          if (res.object.status === 'pending') {
            setDisableEdit(false)
          }
        }
        setLoad(false)
      })
      setModule('Venta #' + id)
    } else {
      backToList()
    }
  }, [])

  return (
    <>
      {load && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!load && (
        <Tabs defaultActiveKey="home" className="mb-3">
          <Tab eventKey="home" title="Información General">
            <Form onSubmit={handleSubmit} method="POST">
              <Row>
                <Col sm="6">
                  <strong>Cliente: </strong> {salesStore?.customer?.name} <br />
                  <strong>RFC: </strong> {salesStore?.customer?.rfc} <br />
                </Col>
                <Col sm="6">
                  <strong>Creado:</strong>{' '}
                  {commonService.formatDate(
                    salesStore.created_at,
                    'DD/MM/YYYY hh:mm a'
                  )}{' '}
                  <br />
                  <strong>Creado Por:</strong> {salesStore?.user.name} <br />
                  <strong>Última Actualización:</strong>{' '}
                  {commonService.formatDate(
                    salesStore.updated_at,
                    'DD/MM/YYYY hh:mm a'
                  )}{' '}
                  <br />
                </Col>
              </Row>
              <hr />
              <Row className="mt-3">
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Proyecto
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        disabled={disableEdit}
                        type="text"
                        name="name"
                        required
                        value={salesStore.name}
                        onChange={(e) =>
                          salesStore.setValues('name', e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm="6" as={Row}>
                  <Form.Label column sm="3">
                    Estatus
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select example"
                      name="state"
                      disabled={disableEdit}
                      className="form-select"
                      value={salesStore.status}
                      onChange={(e) =>
                        salesStore.setValues('status', e.target.value)
                      }
                    >
                      <option value="pending">Pendiente</option>
                      <option value="authorized">Aprobado</option>
                      <option value="cancelled">Cancelado</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
              <>
                <hr />

                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Dirección <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          as="textarea"
                          disabled={disableEdit}
                          rows={5}
                          name="address"
                          value={salesStore.shipping_address}
                          onChange={(e) =>
                            salesStore.setValues(
                              'shipping_address',
                              e.target.value
                            )
                          }
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Ciudad <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          disabled={disableEdit}
                          name="created"
                          required
                          value={salesStore.shipping_city}
                          onChange={(e) =>
                            salesStore.setValues(
                              'shipping_city',
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      Estado
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        aria-label="Default select example"
                        name="state"
                        disabled={disableEdit}
                        className="form-control"
                        value={salesStore.shipping_state}
                        onChange={(e) =>
                          salesStore.setValues('shipping_state', e.target.value)
                        }
                      >
                        <option></option>
                        {states.map((item, i) => (
                          <option key={i} value={`${item}`}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Código Postal <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          name="zipcode"
                          disabled={disableEdit}
                          required
                          value={salesStore.shipping_zipcode}
                          onChange={(e) =>
                            salesStore.setValues(
                              'shipping_zipcode',
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      País
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        aria-label="Default select example"
                        name="country"
                        disabled={disableEdit}
                        className="form-control"
                      >
                        <option value="México">México</option>
                      </Form.Select>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Email <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="email"
                          disabled={disableEdit}
                          name="email"
                          required
                          value={salesStore.email}
                          onChange={(e) =>
                            salesStore.setValues('email', e.target.value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      Teléfono <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        disabled={disableEdit}
                        name="phone"
                        value={salesStore.phone}
                        onChange={(e) =>
                          salesStore.setValues('phone', e.target.value)
                        }
                        required
                      />
                    </Col>
                  </Col>
                </Row>
                <hr />
                {!disableEdit && (
                  <Row className="mt-3 mb-3">
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={getProducts}
                      onChange={addItem}
                    />
                  </Row>
                )}
                <Row>
                  <Table striped bordered hover className="w-100">
                    <thead>
                      <tr>
                        <th style={{ width: '50%' }}>Producto</th>
                        <th style={{ width: '10%' }}>Cantidad</th>
                        <th style={{ width: '10%' }}>Precio / Costo</th>
                        <th style={{ width: '10%' }}>Impuesto</th>
                        <th style={{ width: '15%' }}>Total</th>
                        <th style={{ width: '5%' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesStore?.details &&
                        salesStore?.details?.map((element, index) => (
                          <tr key={`key-${index}`}>
                            <td>
                              {element?.name
                                ? element?.name
                                : element?.product?.name}
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="quantity"
                                disabled={disableEdit}
                                required
                                value={element.quantity}
                                onChange={(e) => {
                                  salesStore.updateItem(index, {
                                    quantity: e.target.value,
                                  })
                                  salesStore.calculateTotal()
                                }}
                                step="1.00"
                              />
                            </td>
                            <td>
                              <Form.Label>Precio:</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                disabled={disableEdit}
                                required
                                value={element.price}
                                onChange={(e) => {
                                  salesStore.updateItem(index, {
                                    price: e.target.value,
                                  })
                                  salesStore.calculateTotal()
                                }}
                                step="1.00"
                              />
                              <Form.Label>Costo:</Form.Label>
                              <Form.Control
                                type="number"
                                name="cost"
                                disabled={disableEdit}
                                required
                                value={element.unit_cost}
                                onChange={(e) =>
                                  salesStore.updateItem(index, {
                                    unit_cost: e.target.value,
                                  })
                                }
                                step="1.00"
                              />
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                name="iva"
                                disabled={disableEdit}
                                value={element.tax_percent}
                                onChange={(e) => {
                                  salesStore.updateItem(index, {
                                    tax_percent: e.target.value,
                                  })
                                  salesStore.calculateTotal()
                                }}
                              >
                                <option value="0.00">0 %</option>
                                <option value="0.16">16 %</option>
                              </Form.Select>
                            </td>
                            <td className="text-right">
                              {commonService.toCurrency(`${element.total}`)}
                            </td>
                            <td>
                              {!disableEdit && (
                                <Button
                                  variant="danger"
                                  onClick={() => {
                                    salesStore.removeItem(index)
                                    salesStore.calculateTotal()
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      {salesStore?.details &&
                        salesStore?.details?.length > 0 && (
                          <>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Subtotal</td>
                              <td className="text-right">
                                {commonService.toCurrency(
                                  `${salesStore.subtotal}`
                                )}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Impuesto</td>
                              <td className="text-right">
                                {commonService.toCurrency(`${salesStore.tax}`)}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Descuento</td>
                              <td className="text-right">
                                <Form.Control
                                  type="number"
                                  disabled={disableEdit}
                                  className="text-right"
                                  name="discount"
                                  value={salesStore.discount}
                                  onChange={(e) =>
                                    salesStore.setValues(
                                      'discount',
                                      e.target.value
                                    )
                                  }
                                  step="1.00"
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Total</td>
                              <td className="text-right">
                                {commonService.toCurrency(
                                  `${salesStore.total}`
                                )}
                              </td>
                              <td></td>
                            </tr>
                          </>
                        )}
                    </tbody>
                  </Table>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Observaciones
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          as="textarea"
                          disabled={disableEdit}
                          rows={5}
                          name="notes"
                          value={salesStore.notes}
                          onChange={(e) =>
                            salesStore.setValues('notes', e.target.value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </>

              <div className="text-center">
                <Button
                  variant="danger"
                  id="button-addon2"
                  disabled={loading}
                  onClick={async () => {
                    if (disableEdit) {
                      backToList()
                      return
                    }
                    const res = await commonService.confirm(
                      'Se perderán los cambios no guardados, ¿Esta seguro?'
                    )
                    if (res.isConfirmed) {
                      backToList()
                    }
                  }}
                >
                  Cancelar
                </Button>
                {!disableEdit && (
                  <Button
                    type="submit"
                    variant="primary"
                    id="button-addon2"
                    className="ms-2"
                    disabled={loading}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                      hidden={!loading}
                    />
                    Guardar
                  </Button>
                )}
              </div>
            </Form>
          </Tab>
          <Tab eventKey="payments" title="Pagos">
            <ListSalesPayments id={`${id}`} />
          </Tab>
          <Tab eventKey="logs" title="Logs">
            <Logs tableName="sales" id={id ?? ''} />
          </Tab>
        </Tabs>
      )}
    </>
  )
}
