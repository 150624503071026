import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { CustomersIndex } from "../pages/customers";
import { Customer } from "../interfaces/interfaces";
import { useLayoutStore } from "../stores/layoutStore";
import { useSalesStore } from "../stores/salesStore";
import { CustomersCreate } from "../pages/customers/create";
import { SupplierIndex } from "../pages/suppliers";
import { usePurchasesStore } from "../stores/purchasesStore";

interface customerProps {
  close?: () => void;
  reload?: () => void;
}

export const SupplierSelector: React.FC<customerProps> = ({close}) => {
  const purchaseStore = usePurchasesStore();
  const layoutStore = useLayoutStore();
  const insideContent = (destiny: Customer) => {
    return <>
      <Button variant="success" onClick={(e) => {
        e.stopPropagation();
        purchaseStore.setValues('supplier', destiny);
        purchaseStore.setValues('supplier_id', destiny.id);
        purchaseStore.setValues('email', destiny.email);
        purchaseStore.setValues('phone', destiny.phone);
        purchaseStore.setValues('shipping_address', destiny.address);
        purchaseStore.setValues('shipping_city', destiny.city);
        purchaseStore.setValues('shipping_zipcode', destiny.zipcode);
        purchaseStore.setValues('shipping_state', destiny.state);
        purchaseStore.setValues('shipping_country', destiny.country);

        
        layoutStore.setModule("Compras");
        close && close();
      }}>
        <i className="fa-solid fa-check"></i>
      </Button>
    </>;
  };
  // const outsideContent = (destiny: Customer) => {
  //   salesStore.setValues('customer', destiny);
  //   salesStore.setValues('customer_id', destiny.id);
  //   salesStore.setValues('email', destiny.email);
  //   salesStore.setValues('phone', destiny.phone);
  //   salesStore.setValues('shipping_address', destiny.address);
  //   salesStore.setValues('shipping_city', destiny.city);
  //   salesStore.setValues('shipping_zipcode', destiny.zipcode);
  //   salesStore.setValues('shipping_state', destiny.state);
  //   salesStore.setValues('shipping_country', destiny.country);
  //   layoutStore.setModule("Ventas");
  //   close && close();
  // };

  // const createContent = () => {
  //   return <>
  //     <CustomersCreate close={close} reload={() => {}} outside={outsideContent}/>
  //   </>;
  // };
  return (
    <Form>
      <Form.Group as={Col} className="mb-3 col-12">
        <Col className="mt-1">
          Selecciona un Proveedor del listado.
          <SupplierIndex type="inside" insideContent={insideContent}/>
        </Col>
      </Form.Group>
    </Form>
  );
};