import { Contacts, Suppliers, PaginationResult } from "../interfaces/interfaces";
import { AjaxService } from "./ajax";

const basePath = 'supplies';

export const suppliesService = {
  getPagination: (page: number, perPage?: number, name?: string, filter?:any) =>
    AjaxService.get<PaginationResult<Suppliers>>({
      url: `${basePath}`,
      data: {
        page,
        perPage,
        name,
        filter,
      },
    }),
  delete: (id: number) =>
    AjaxService.delete<Suppliers>({
      url: `${basePath}/${id}`,
    }),
  create: (product: Partial<Suppliers>) =>
    AjaxService.post<Suppliers>({
      url: `${basePath}`,
      data: product,
    }),
  get: (id: number) =>
    AjaxService.get<Suppliers>({
      url: `${basePath}/${id}`,
    }),
  update: (id: number, product: Partial<Suppliers>) =>
    AjaxService.put<Suppliers>({
      url: `${basePath}/${id}`,
      data: product,
    }),

};
