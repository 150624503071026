/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { customerService } from "../../services/customersService";
import { Customer, states } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";

export interface CustomersCreateProps {
  close?: () => void;
  reload?: () => void;
  outside?: (customer: Customer) => void;
}

export const CustomersCreate: FC<CustomersCreateProps> = ({close, reload, outside}) => {
  const { id } = useParams();
  const [object,] = useState<Customer>();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
    customerService.create(values).then((response) => {
      setIsLoading(false);
      if(response.success){
        commonService.toast("Cliente creado correctamente", "success");
        if(outside){
          outside(response.object || {} as Customer);
          return;
        }
        close && close();
        reload && reload();
      }
    });
  };
  
  return (
    <>
      <Form onSubmit={handleSubmit} method="POST">
        <Tabs defaultActiveKey="home" className="mb-3">
          <Tab eventKey="home" title="Información General">
            <Row>
              <h5>Cliente {id}</h5>
            </Row>
            <Form.Group as={Row} className="mb-3">
              <input type="hidden" name="id" value={id} />
              <Form.Label column sm="2">
                  Nombre <small className="text-danger">*</small>
              </Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="name" required />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                  Dirección
              </Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="address" />
              </Col>
            </Form.Group>
            <div className="row">
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Teléfono
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="phone" />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Email
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="email" />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-12">
                <Form.Label column sm="12">
                    Ciudad
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="city" />
                </Col>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Estado
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="state">
                    <option></option>
                    {states.map((item, i) => (
                      <option
                        key={i}
                        value={`${item}`}
                        selected={item === object?.state}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Sitio web
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="website" />
                </Col>
              </Form.Group>
            </div>
            <hr />
            <h5>Datos Fiscales</h5>
            <div className="row">

              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    RFC
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="rfc" style={{textTransform: "uppercase"}}/>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Código Postal
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="zipcode" />
                </Col>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Uso de CFDI
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="use_cfdi" />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Regimen Fiscal
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="country" />
                </Col>
              </Form.Group>
            </div>
          </Tab>
        </Tabs>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => close && close()}
            disabled={isLoading}
          >
              Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
            disabled={isLoading}
          >
            {isLoading && <span className="spinner-border spinner-border-sm"></span>}
              Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
