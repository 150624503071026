/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  Features,
  TechnicalSheet,
  TechnicalSheetDetails,
} from "../interfaces/interfaces";

interface TechnicalSheetState extends TechnicalSheet {}

interface TechnicalSheetActions {
  setValues: (key: string, value: any) => void;
  reset: () => void;
  getState: () => TechnicalSheetState;
  addSupply: (supply: any) => void;
  initSale: (init: Partial<TechnicalSheetState>) => void;
  removeSupply: (supply_id: number) => void;
  setValuesSupply: (index: number, field: string, value: any) => void;
  addFeature: (index: number, feature: Features) => void;
  removeFeature: (index: number, featureIndex: number) => void;
  setValuesFeatures: (index: number, indexFeature: number, field: string, value: any) => void;
}

const initialState: TechnicalSheetState = {
  id: 0,
  product_id: 0,
  description: "",
  product: undefined,
  details: undefined,
  length: "",
  width: "",
  height: "",
  image: "",
};

export const useTechnicalSheetStore = create<
  TechnicalSheetState & TechnicalSheetActions
>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setValues: (key, value) => set({ [key]: value }),
        setValuesSupply: (index, field, value) => {
          const currentSupplies = get().details ?? [];
          currentSupplies[index][field as keyof TechnicalSheetDetails] = value;
          set((state) => ({
            ...state,
            details: [...currentSupplies],
          }));
        },
        setValuesFeatures: (index, indexFeature, field, value) => {
          const currentSupplies = get().details ?? [];
          currentSupplies[index].features[indexFeature][field as keyof Features] = value;
          set((state) => ({
            ...state,
            details: [...currentSupplies],
          }));
        },
        initSale: (init) =>
          set({
            ...init,
          }),
        reset: () => {
          console.log('aa')
          set({ ...initialState });
        },
        addFeature: (index, feature) => {
          const currentSupplies = get().details ?? [];
          const id = currentSupplies[index].features.findIndex(
            (s) => s.id === feature.id
          );

          if(id === -1){
            currentSupplies[index].features.push(feature);
            set((state) => ({
              ...state,
              details: [...currentSupplies],
            }));
          }
        },
        removeFeature: (index, featureIndex) => {
          const currentSupplies = get().details ?? [];
          currentSupplies[index].features.splice(featureIndex, 1);
          set((state) => ({
            ...state,
            details: [...currentSupplies],
          }));
        },
        addSupply: (supply) => {
          const currentSupplies = get().details ?? [];
          const id = currentSupplies.findIndex(
            (s) => s.supply_id === supply.id
          );
          if (id === -1) {
            set((state) => ({
              ...state,
              details: [
                ...currentSupplies,
                {
                  supply_id: supply.id,
                  supply: supply,
                  features: [] as Features[],
                } as TechnicalSheetDetails,
              ],
            }));
          }
        },
        removeSupply(supply_id) {
          console.log({ supply_id });
          const id = get()?.details?.findIndex(
            (s) => s.supply_id === supply_id
          );
          if (id !== -1) {
            const currentSupplies = get().details ?? [];
            currentSupplies.splice(Number(id), 1);
            set((state) => ({
              ...state,
              details: [...currentSupplies],
            }));
          }
        },
        getState: () => ({
          ...initialState,
          description: get().description,
          product: get().product,
          details: get().details,
          product_id: get()?.product?.id ?? 0,
          length: get().length,
          width: get().width,
          height: get().height,
          image: get().image,
          id: get().id,
        }),
      }),
      {
        name: "crisa-technical-sheet",
      }
    )
  )
);
