/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Catalog } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { catalogService } from "../../services/catalogService";

interface propsViewCategory {
  close?: () => void;
  reload?: () => void;
  object?: Catalog;
}

export const SuppliersViewCategory: React.FC<propsViewCategory> = ({
  close,
  reload,
  object,
}) => {
  const form = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (object) {
    
      document
        .querySelector("[name='id']")
        ?.setAttribute("value", `${object.id}`);
      document
        .querySelector("[name='name']")
        ?.setAttribute("value", object?.name);
    }
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const product: Record<string, any> = {
      id: 0,
      name: "",
    };
    for (const [key, value] of data.entries()) {
      product[key] = value;
    }
    
    catalogService.update('group', product as Catalog).then((res) => {
      if (res.success) {
        commonService.toast("Categoría actualizada correctamente", "success");
        close && close();
        reload && reload();
        return;
      }
      commonService.toast("Error al actualizar Categoría", "error");
    });
  };

  return (
    <>
      {object ? (
        <Form onSubmit={handleSubmit} method="POST" ref={form}>
          <input type="hidden" name="id" />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Nombre <small className="text-danger">*</small>
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" name="name" required />
            </Col>
          </Form.Group>
          <div className="text-center">
            <Button
              variant="danger"
              id="button-addon2"
              onClick={() => close && close()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              id="button-addon2"
              className="ms-2"
            >
              Guardar
            </Button>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <h3>Cargando</h3>
        </div>
      )}
    </>
  );
};
