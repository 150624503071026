/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button, Modal, Table, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../services/commonService";
import { useSalesStore } from "../../stores/salesStore";
import { states } from "../../interfaces/interfaces";
import AsyncSelect from "react-select/async";
import { productsService } from "../../services/productsService";
import { salesService } from "../../services/salesService";
import { CustomerSelector } from "../../components/customerSelector";

export interface SalesCreateProps {
  close?: () => void;
  reload?: () => void;
}

export const SalesCreate: FC<SalesCreateProps> = ({ reload }) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const backToList = () => {
    salesStore.reset();
    navigate("/movements/sales");
  };
  const [loading, setLoading] = useState(false);
  const salesStore = useSalesStore();
  // const [destiny, setDestiny] = useState();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = salesStore.getState();
    if (payload.details?.length === 0) {
      commonService.toast("Debe agregar productos a la venta", "error");
      return;
    }
    if (!payload.customer_id) {
      commonService.toast("Debe seleccionar un cliente", "error");
      return;
    }
    setLoading(true);
    const response = await salesService.create(payload);
    if (response.success) {
      commonService.toast(response.message, "success");
      backToList();
      setLoading(false);
      return;
    }
    commonService.toast(response.message, "error");
    setLoading(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setObject(undefined);
    setShow(false);
  };
  const getProducts = (filter?: string) =>
    productsService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
      }
      return [];
    });
  const addItem = (element: any) => {
    if (element) {
      const item = salesStore?.details?.find((item) => item.id === element.id);
      if (!item) salesStore.addItem(element);
    }
  };
  useEffect(() => {
    salesStore.calculateTotal();
  }, [salesStore.details, salesStore.discount]);

  useEffect(() => {
    salesStore.reset();
    setLoad(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerSelector close={handleClose} reload={reload} />
        </Modal.Body>
      </Modal>
      {load && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!load && (
        <Form onSubmit={handleSubmit} method="POST">
          <Row className="mt-5">
            <Col sm="12">
              <Button variant="primary" onClick={() => setShow(true)}>
                Selecciona Cliente
              </Button>
            </Col>
          </Row>
          {salesStore.customer.id !== 0 && (
            <>
              <hr />
              <Row>
                <Col sm="6">
                  <strong>Cliente: </strong> {salesStore?.customer?.name} <br />
                  <strong>RFC: </strong> {salesStore?.customer?.rfc}
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Proyecto
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        name="name"
                        required
                        value={salesStore.name}
                        onChange={(e) =>
                          salesStore.setValues("name", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Dirección <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="address"
                        value={salesStore.shipping_address}
                        onChange={(e) =>
                          salesStore.setValues(
                            "shipping_address",
                            e.target.value
                          )
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Ciudad <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="created"
                        required
                        value={salesStore.shipping_city}
                        onChange={(e) =>
                          salesStore.setValues("shipping_city", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col as={Row}>
                  <Form.Label column sm="3">
                    Estado
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select example"
                      name="state"
                      className="form-control"
                      value={salesStore.shipping_state}
                      onChange={(e) =>
                        salesStore.setValues("shipping_state", e.target.value)
                      }
                    >
                      <option></option>
                      {states.map((item, i) => (
                        <option key={i} value={`${item}`}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Código Postal <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="zipcode"
                        required
                        value={salesStore.shipping_zipcode}
                        onChange={(e) =>
                          salesStore.setValues(
                            "shipping_zipcode",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col as={Row}>
                  <Form.Label column sm="3">
                    País
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select example"
                      name="country"
                      className="form-control"
                    >
                      <option value="México">México</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Email <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="email"
                        name="email"
                        required
                        value={salesStore.email}
                        onChange={(e) =>
                          salesStore.setValues("email", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col as={Row}>
                  <Form.Label column sm="3">
                    Teléfono <small className="text-danger">*</small>
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      name="phone"
                      value={salesStore.phone}
                      onChange={(e) =>
                        salesStore.setValues("phone", e.target.value)
                      }
                      required
                    />
                  </Col>
                </Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-3">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={getProducts}
                  onChange={addItem}
                />
              </Row>
              <Row>
                <Table striped bordered hover className="w-100">
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>Producto</th>
                      <th style={{ width: "10%" }}>Cantidad</th>
                      <th style={{ width: "10%" }}>Precio / Costo</th>
                      <th style={{ width: "10%" }}>Impuesto</th>
                      <th style={{ width: "15%" }}>Total</th>
                      <th style={{ width: "5%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesStore?.details &&
                      salesStore?.details?.map((element: any, index) => (
                        <tr key={`key-${index}`}>
                          <td>{element.name}</td>
                          <td>
                            <Form.Control
                              type="number"
                              name="quantity"
                              required
                              value={element.quantity}
                              onChange={(e) => {
                                salesStore.updateItem(index, {
                                  quantity: e.target.value,
                                });
                                salesStore.calculateTotal();
                              }}
                              step="1.00"
                            />
                          </td>
                          <td>
                            <Form.Label>Precio:</Form.Label>
                            <Form.Control
                              type="number"
                              name="price"
                              required
                              value={element.price}
                              onChange={(e) => {
                                salesStore.updateItem(index, {
                                  price: e.target.value,
                                });
                                salesStore.calculateTotal();
                              }}
                              step="1.00"
                            />
                            <Form.Label>Costo:</Form.Label>
                            <Form.Control
                              type="number"
                              name="cost"
                              required
                              value={element.cost}
                              onChange={(e) =>
                                salesStore.updateItem(index, {
                                  cost: e.target.value,
                                })
                              }
                              step="1.00"
                            />
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="iva"
                              value={element.tax_percent}
                              onChange={(e) => {
                                salesStore.updateItem(index, {
                                  tax_percent: e.target.value,
                                });
                                salesStore.calculateTotal();
                              }}
                            >
                              <option value="0.00">0 %</option>
                              <option value="0.16">16 %</option>
                            </Form.Select>
                          </td>
                          <td className="text-right">
                            {commonService.toCurrency(`${element.total}`)}
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => {
                                salesStore.removeItem(index);
                                salesStore.calculateTotal();
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    {salesStore?.details && salesStore?.details?.length > 0 && (
                      <>
                        <tr>
                          <td colSpan={3}></td>
                          <td>Subtotal</td>
                          <td className="text-right">
                            {commonService.toCurrency(`${salesStore.subtotal}`)}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                          <td>Impuesto</td>
                          <td className="text-right">
                            {commonService.toCurrency(`${salesStore.tax}`)}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                          <td>Descuento</td>
                          <td className="text-right">
                            <Form.Control
                              type="number"
                              className="text-right"
                              name="discount"
                              value={salesStore.discount}
                              onChange={(e) =>
                                salesStore.setValues("discount", e.target.value)
                              }
                              step="1.00"
                            />
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                          <td>Total</td>
                          <td className="text-right">
                            {commonService.toCurrency(`${salesStore.total}`)}
                          </td>
                          <td></td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Observaciones
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="notes"
                        value={salesStore.notes}
                        onChange={(e) =>
                          salesStore.setValues("notes", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <div className="text-center">
            <Button
              variant="danger"
              id="button-addon2"
              disabled={loading}
              onClick={async () => {
                const res = await commonService.confirm(
                  "Se perderán los cambios no guardados, ¿Esta seguro?"
                );
                if (res.isConfirmed) {
                  backToList();
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              id="button-addon2"
              className="ms-2"
              disabled={loading}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
                hidden={!loading}
              />
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};
