/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import { PaginationResult, User } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { CreateUser } from "./create";
import { Modal } from "react-bootstrap";
import { ViewUser } from "./view";
import { usersService } from "../../services/usersService";
import { useAuthStore } from "../../stores/authStore";
import { useNavigate } from "react-router-dom";
import { useLayoutStore } from "../../stores/layoutStore";

export function UsersIndex() {
  const auth = useAuthStore();
  const moduleLabel = 'Usuarios';
  const setModule = useLayoutStore(state => state.setModule);
  useEffect(() => {
    setModule(moduleLabel);
  }, []);
  const navigate = useNavigate();
  const viewItem = (item: any) => commonService.log(item)
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [object, setObject] = useState<User>();
  const map = {
    administrator: 'Administrador',
    regular: 'Regular',
  };
  const headers = {
    id: {
      label: "ID",
    },
    name: {
      label: "Nombre"
    },
    role: {
      label: "Perfil",
      format: (value: string) => map[value as keyof typeof map]
    },
    email: {
      label: "Usuario",
    },
    actions: {
      label: "Acciones",
      render: ({id, role}: {id: number, role: string}) => <>
        <button className="btn btn-warning ms-2" title="Editar" onClick={async (e) => {
          e.stopPropagation();
          handleShow();
          usersService.get(id).then((res) => {
             
            if(res.success){
              setObject(res.object);
            }
          });
        }}>
          <i className="fa-solid fa-pen-to-square"></i>
        </button>
        {
          id !== auth.user?.id  && role !== 'administrator' && (
            <button className="btn btn-danger ms-2" title="Borrar" onClick={async (e) => {
              e.stopPropagation();
              const response = await commonService.confirm('¿Estas seguro de borrar el Usuario?, esta acción es irreversible');
              if(response.isConfirmed){
                const res = await usersService.delete(id);
                if(res.success){
                  commonService.toast('Usuario borrado con éxito', 'success');
                  reload();
                }else{
                  commonService.toast('Error al borrar el Usuario', 'error');
                }
              }
            }}>
              <i className="fa-solid fa-trash-can"></i>
            </button>

          )
        }
      </>
    }
  };
  useEffect(() => {
    if(auth.user?.role !== 'administrator'){
      navigate("/");
    }
  }, []);
  const getObject = (page: number, filter?: string) => usersService.getPagination(page, 10, filter).then((res) => {
    if (res.success && res.object?.data) {
      setPaginationResults(res.object);
    }
  });
    
  useMemo(() => getObject(page, filter), [page, filter]);
  
  const reload = () => getObject(page, filter);
  
  const [paginationResults, setPaginationResults] = useState<PaginationResult<User[]>>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setObject(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  
  return (
    <>
      <DataTable
        create={(close: () => void) => <CreateUser close={close} reload={reload}/>}
        onRowClick={viewItem}
        headers={headers}
        changePage={setPage}
        filter={setFilter}
        reload={reload}
        resultInfo={paginationResults}  
      />
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              object && <ViewUser object={object} close={handleClose} reload={reload}/>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
