/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../services/commonService";
import { useSalesStore } from "../../stores/salesStore";
import { SaleSelector } from "../../components/saleSelector";
import { useDeliveriesStore } from "../../stores/deliveriesStore";
import { useSenderStore } from "../../stores/senderStore";
import { sendersService } from "../../services/sendersService";
import { SaleSenderSelector } from "../../components/saleSenderSelector";
import { useLayoutStore } from "../../stores/layoutStore";
import { Sender, states } from "../../interfaces/interfaces";

export interface SalesCreateProps {
  close?: () => void;
  reload?: () => void;
  object?: Sender;
}

export const SenderCreate: FC<SalesCreateProps> = ({ reload, close, object }) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const salesStore = useSalesStore();
  const deliveriesStore = useDeliveriesStore();
  const senderStore = useSenderStore();
  const setModule = useLayoutStore((state) => state.setModule);
  useEffect(() => {
    setModule("Remitentes");
    senderStore.reset();
    if(object){
      console.log({object})
      senderStore.setValues('customer_id', object.customer_id)
      senderStore.setValues('sale_id', object.sale_id)
      senderStore.setValues('sale', object.sale)
      senderStore.setValues('name', object.name)
      senderStore.setValues('address', object.address)
      senderStore.setValues('city', object.city)
      senderStore.setValues('state', object.state)
      senderStore.setValues('zip', object.zip)
      senderStore.setValues('phone', object.phone)
      senderStore.setValues('rfc', object.rfc)
      senderStore.setValues('type', object.type)
      senderStore.setValues('is_payed', object.is_payed)
      senderStore.setValues('deliver_by', object.deliver_by)
      senderStore.setValues('notes', object.notes)
      senderStore.setValues('id', object.id)
    }
  }, []);
  const [products, setProducts] = useState("");
  const backToList = () => {
    senderStore.reset();
    navigate("/movements/senders");
  };
  // const [destiny, setDestiny] = useState();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = senderStore.getState();
    commonService.log({ payload });
    if (!payload.sale_id) {
      commonService.toast("Debe seleccionar una venta", "error");
      return;
    }
    setLoading(true);

    // return;
    if(object){
      const response = await sendersService.update(Number(payload.id), payload);
      if (response.success) {
        commonService.toast(response.message, "success");
        backToList();
        setLoading(false);
        reload && reload();
        close && close();
        senderStore.reset();
        const resPdf = await sendersService.getPdf(Number(response?.object?.id));

        if (resPdf.success) {
          commonService.showPDF(resPdf?.object?.pdf ?? "");
        }
        return;
      }
      commonService.toast(response.message, "error");
      setLoading(false);
      return;
    }
    const response = await sendersService.create(payload);
    if (response.success) {
      commonService.toast(response.message, "success");
      backToList();
      setLoading(false);
      const resPdf = await sendersService.getPdf(Number(response?.object?.id));
      if (resPdf.success) {
        commonService.showPDF(resPdf?.object?.pdf ?? "");
      }
      return;
    }
    commonService.toast(response.message, "error");
    setLoading(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setObject(undefined);
    setShow(false);
  };
  // useEffect(() => {
  //   salesStore.calculateTotal();
  // }, [salesStore.details, salesStore.discount]);

  useEffect(() => {
    // senderStore.reset();
    setLoad(false);
  }, []);
  // useEffect(() => {
  //   if (senderStore?.sale?.id) {
  //     setProducts(
  //       senderStore?.sale?.sales_details.map((e) => `${e.product_id}`).join(",")
  //     );
  //   }
  // }, [senderStore?.sale?.id]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona una Venta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SaleSenderSelector close={handleClose} />
        </Modal.Body>
      </Modal>
      {load && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!load && (
        <Form onSubmit={handleSubmit} method="POST">
          {
            !senderStore.sale_id && (
              <Row className="mt-5">
                <Col sm="12">
                  <Button variant="primary" onClick={() => setShow(true)}>
                    Selecciona una Venta
                  </Button>
                </Col>
              </Row>

            )
          }
          {senderStore?.sale_id && (
            <>
              <Row>
                <Col>
                  <strong>Venta: </strong> {senderStore.sale?.id} - {senderStore.sale?.name}<br />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Empresa <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        required
                        value={senderStore.name}
                        onChange={(e) =>
                          senderStore.setValues("name", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Dirección <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        required
                        value={senderStore.address}
                        onChange={(e) =>
                          senderStore.setValues("address", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      Estado
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        aria-label="Default select example"
                        name="state"
                        className="form-control"
                        value={senderStore.state}
                        onChange={(e) =>
                          salesStore.setValues("state", e.target.value)
                        }
                      >
                        <option></option>
                        {states.map((item, i) => (
                          <option key={i} value={`${item}`}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Col>
                </Col>
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Teléfono <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        required
                        value={senderStore.phone}
                        onChange={(e) =>
                          senderStore.setValues("phone", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      RFC <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        required
                        value={senderStore.rfc}
                        onChange={(e) =>
                          senderStore.setValues("rfc", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                      Paquetería <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        required
                        value={senderStore.deliver_by}
                        onChange={(e) =>
                          senderStore.setValues("deliver_by", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      Pagado:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        aria-label="Default select example"
                        name="state"
                        className="form-control"
                        value={senderStore.is_payed}
                        onChange={(e) =>
                          senderStore.setValues("is_payed", e.target.value)
                        }
                      >
                        <option value="">Seleccione</option>
                        <option value="0">No Pagado</option>
                        <option value="1">Pagado</option>
                      </Form.Select>
                    </Col>
                  </Col>
                </Col>
                <Col sm="6">
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      Tipo de entrega:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        aria-label="Default select example"
                        name="state"
                        className="form-control"
                        value={senderStore.type}
                        onChange={(e) =>
                          senderStore.setValues("type", e.target.value)
                        }
                      >
                        <option value="">Seleccione</option>
                        <option value="Domicilio">Domicilio</option>
                        <option value="En sitio">En sitio</option>
                      </Form.Select>
                    </Col>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="mt-3">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="1">
                      Notas
                    </Form.Label>
                    <Col sm="11">
                      <Form.Control
                        type="text"
                        value={senderStore.notes}
                        onChange={(e) =>
                          senderStore.setValues("notes", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <div className="text-center" style={{ marginTop: "30px" }}>
            <Button
              variant="danger"
              id="button-addon2"
              disabled={loading}
              onClick={async () => {
                const res = await commonService.confirm(
                  "Se perderán los cambios no guardados, ¿Esta seguro?"
                );
                if (res.isConfirmed) {
                  if(object){
                    reload && reload();
                    close && close();
                    return;
                  }
                  backToList();
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              id="button-addon2"
              className="ms-2"
              disabled={loading}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
                hidden={!loading}
              />
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};
