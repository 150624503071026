/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Catalog,
  Customer,
  Suppliers,
  TechnicalSheet,
  features,
} from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { suppliesService } from "../../services/suppliesService";
import AsyncSelect from "react-select/async";
import { productsService } from "../../services/productsService";
import { useTechnicalSheetStore } from "../../stores/technicalSheetStore";
import { technicalSheetService } from "../../services/technicalSheetService";
import { suppliersService } from "../../services/suppliersService";

export interface CustomersCreateProps {
  close?: () => void;
  reload?: () => void;
  outside?: (customer: Suppliers) => void;
  object?: TechnicalSheet;
}

export const TechnicalSheetEdit: FC<CustomersCreateProps> = ({
  close,
  reload,
  outside,
  object,
}) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState<Catalog[]>([]);
  const technicalSheetStore = useTechnicalSheetStore();
  const onChangeItem = (element: any) =>
    technicalSheetStore.setValues("product", element);
  const onSupplierChange = (index: number, element: any) =>
    technicalSheetStore.setValuesSupply(index, "supplier", element);
  const getProducts = (filter?: string) =>
    productsService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
      }
      return [];
    });
  const getSuppliers = (filter?: string) =>
    suppliersService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
      }
      return [];
    });

  const getSupplies = (filter?: string) =>
    suppliesService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
      }
      return [];
    });
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const values = technicalSheetStore.getState();
    // console.log({ values });
    // return;
    technicalSheetService.update(Number(values.id), values).then((response) => {
      setIsLoading(false);
      if (response.success) {
        technicalSheetStore.reset();
        commonService.toast("Hoja Técnica creado correctamente", "success");
        if (outside) {
          outside(response.object || ({} as Suppliers));
          return;
        }
        close && close();
        reload && reload();
      }
    });
  };

  const addSupply = (selectedSupply: any) => {
    technicalSheetStore.addSupply(selectedSupply);
  };

  const addFeature = (index: number, selectedFeature: string) => {
    const id = features.findIndex((e) => e.id === selectedFeature);
    if (id === -1) return;
    technicalSheetStore.addFeature(index, {
      ...features[id],
    });
    // console.log({ selectedFeature });
  };

  useEffect(() => {
    if(object){
      technicalSheetStore.setValues("description", object.description)
      technicalSheetStore.setValues("height", object.height)
      technicalSheetStore.setValues("width", object.width)
      technicalSheetStore.setValues("details", object.details)
      technicalSheetStore.setValues("product", object.product)
      technicalSheetStore.setValues("product_id", object.product_id)
      technicalSheetStore.setValues("image", object.image)
      technicalSheetStore.setValues("length", object.length)
      technicalSheetStore.setValues("id", object.id)
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit} method="POST">
        <Row>
          <h5>Hoja Técnica</h5>
        </Row>
        <Row className="mt-3 mb-3">
          <Form.Label column sm="2">
            Producto <small className="text-danger">*</small>
          </Form.Label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={getProducts}
            onChange={onChangeItem}
            value={{...technicalSheetStore.product, value: technicalSheetStore?.product?.id, label: technicalSheetStore?.product?.name}}
            required
          />
        </Row>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Descripción
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="textarea"
              name="description"
              rows={6}
              value={technicalSheetStore.description}
              onChange={(e) =>
                technicalSheetStore.setValues("description", e.target.value)
              }
              required
            />
          </Col>
        </Form.Group>
        <Row>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Largo <small className="text-danger">*</small>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  required
                  value={technicalSheetStore.length}
                  onChange={(e) =>
                    technicalSheetStore.setValues("length", e.target.value)
                  }
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Ancho <small className="text-danger">*</small>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  required
                  value={technicalSheetStore.width}
                  onChange={(e) =>
                    technicalSheetStore.setValues("width", e.target.value)
                  }
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Alto <small className="text-danger">*</small>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  required
                  value={technicalSheetStore.height}
                  onChange={(e) =>
                    technicalSheetStore.setValues("height", e.target.value)
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Form.Label column sm="12">
            Insumos{" "}
            <small className="text-danger">
              * debe seleccionar al menos un Insumo
            </small>
          </Form.Label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={getSupplies}
            onChange={addSupply}
          />
        </Row>
        <Row>
          <Col sm="12">
            <ul className="list-group">
              {technicalSheetStore.details &&
                technicalSheetStore?.details.map((supply, index) => (
                  <li className="list-group-item" key={supply.supply_id}>
                    <strong>
                      {supply.supply.id} {supply.supply.name}
                    </strong>
                    <Col sm="12">
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                          Descripción<small className="text-danger">*</small>{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            as="textarea"
                            type="text"
                            required
                            value={supply.notes}
                            onChange={(e) =>
                              technicalSheetStore.setValuesSupply(
                                index,
                                "notes",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="12">
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                          Proveedor
                        </Form.Label>
                        <Col sm="8">
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={getSuppliers}
                            onChange={(value) => onSupplierChange(index, value)}
                            value={{...supply.supplier, value: supply?.supplier?.id, label: supply?.supplier?.name}}
                            required
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Row>
                      <Col>
                        <select
                          className="form-select"
                          onChange={(e) => addFeature(index, e.target.value)}
                        >
                          <option value="">
                            Seleccione una característica
                          </option>
                          {features.map((feature, index) => (
                            <option key={index} value={feature.id}>
                              {feature.label}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      {supply.features.map((feature, indexFeature) => (
                        <Col sm="4" className="mt-3" key={indexFeature}>
                          <Form.Label column sm="12">
                            {feature.label}{" "}
                            <small className="text-danger">*</small>{" "}
                          </Form.Label>
                          <Col sm="9">
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="text"
                                required
                                value={feature.value}
                                onChange={(e) =>
                                  technicalSheetStore.setValuesFeatures(
                                    index,
                                    indexFeature,
                                    "value",
                                    e.target.value
                                  )
                                }
                              />
                              <Button
                                variant="danger"
                                className="col-3"
                                onClick={(e) => {
                                  // console.log({ index, indexFeature });
                                  technicalSheetStore.removeFeature(
                                    index,
                                    indexFeature
                                  );
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </InputGroup>
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    <div className="text-center mt-3">
                      <Button
                        variant="danger"
                        onClick={() => {
                          technicalSheetStore.removeSupply(
                            Number(supply.supply_id)
                          );
                        }}
                      >
                        Borrar Insumo <i className="fa fa-trash"></i>
                      </Button>
                    </div>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => {
              technicalSheetStore.reset();
              close && close();
            }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
            disabled={isLoading}
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
