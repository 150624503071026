import { Contacts, Suppliers, PaginationResult } from "../interfaces/interfaces";
import { AjaxService } from "./ajax";

const basePath = 'suppliers';

export const suppliersService = {
  getPagination: (page: number, perPage?: number, name?: string) =>
    AjaxService.get<PaginationResult<Suppliers>>({
      url: `${basePath}`,
      data: {
        page,
        perPage,
        name,
      },
    }),
  delete: (id: number) =>
    AjaxService.delete<Suppliers>({
      url: `${basePath}/${id}`,
    }),
  create: (product: Partial<Suppliers>) =>
    AjaxService.post<Suppliers>({
      url: `${basePath}`,
      data: product,
    }),
  get: (id: number) =>
    AjaxService.get<Suppliers>({
      url: `${basePath}/${id}`,
    }),
  update: (id: number, product: Partial<Suppliers>) =>
    AjaxService.put<Suppliers>({
      url: `${basePath}/${id}`,
      data: product,
    }),
  getContacts: (id: number) =>
    AjaxService.get<Contacts>({
      url: `contacts/${id}`,
    }),
  upsertContacts: (contacts: Partial<Contacts>) =>
    AjaxService.post<Contacts>({
      url: `contacts`,
      data: contacts,
    }),
  deleteContact: (id: number) =>
    AjaxService.delete<Contacts>({
      url: `contacts/${id}`,
    }),
};
