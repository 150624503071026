/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from "react";
import { Form, Row, Col, Button, Tabs, Tab, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Contacts, Suppliers, states } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { suppliersService } from "../../services/suppliersService";
import { suppliesService } from "../../services/suppliesService";

export interface EditSuppliesProps {
  close?: () => void;
  reload?: () => void;
  object?: any;
}

export const SuppliesEdit: FC<EditSuppliesProps> = ({
  close,
  reload,
  object: paramObject,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [object, setObject] = useState<any>();
  useEffect(() => {
    if (id) {
      refreshInfo();
    }
    if (paramObject) {
      setObject(paramObject);
      refreshInfo();
    }
  }, []);

  const refreshInfo = () => {
    if(paramObject){
      for (const field in paramObject) {
        const input: any = document.querySelector(`[name='${field}']`);
        if(field === 'type'){
          const radio = document.querySelector(`[name='${field}'][value='${paramObject[field]}']`) as HTMLInputElement;
          radio.checked = true;
        }
        if (input) {
          input.value = paramObject[field];
        }
      }
      return
    }
    suppliersService.get(Number(id)).then((data) => {
      if (data.success) {
        const fields = data.object;
        setObject(fields);
        for (const field in fields) {
          const input: any = document.querySelector(`[name='${field}']`);
          if (input) {
            input.value = fields[field as keyof Suppliers];
          }
        }
      }
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
    if(id){
      suppliesService.update(Number(id), values).then((response) => {
        if (response.success) {
          commonService.toast("Insumo creado correctamente", "success");
          navigate("/movements/supplies");
        }
      });
    }else{
      delete values.id;
      suppliesService.update(Number(object.id), values).then((response) => {
        if (response.success) {
          commonService.toast("Insumo creado correctamente", "success");
          reload && reload();
          close && close();
          // navigate("/movements/supplies");
        }
      });
    }
  };
  
  return (
    <>
      <>
        <Form onSubmit={handleSubmit} method="POST">
          <Row>
            <h5>Insumo {id}</h5>
          </Row>
          <Form.Group as={Row} className="mb-3">
            <input type="hidden" name="id" value={id} />
            <Form.Label column sm="2">
              Nombre <small className="text-danger">*</small>
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" name="name" required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Descripción
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" name="description" />
            </Col>
          </Form.Group>
          <div className="row">
            <Form.Group as={Col} className="mb-3 col-6">
              <Form.Label column sm="12">
                Tipo
              </Form.Label>
              <Col sm="12">
                <Form.Check
                  inline
                  label="Materia Prima"
                  name="type"
                  type="radio"
                  value="product"
                />
                <Form.Check
                  inline
                  label="Servicio"
                  name="type"
                  type="radio"
                  value="service"
                />
              </Col>
            </Form.Group>
            <div className="text-center">
              <Button
                variant="danger"
                id="button-addon2"
                onClick={() => close && close()}
              >
                Cerrar
              </Button>
              <Button
                type="submit"
                variant="primary"
                id="button-addon2"
                className="ms-2"
              >
                Guardar
              </Button>
            </div>
          </div>
        </Form>
      </>
    </>
  );
};
