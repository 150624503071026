import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import { Catalog, PaginationResult } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { CreateCategory } from "./create";
import { Modal } from "react-bootstrap";
import { ViewCategory } from "./view";
import { catalogService } from "../../services/catalogService";
import { useLayoutStore } from "../../stores/layoutStore";

export function CategoryIndex() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item)
  const moduleLabel = 'Categorías';
  const setModule = useLayoutStore(state => state.setModule);
  useEffect(() => {
    setModule(moduleLabel);
  }, []);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [object, setObject] = useState<Catalog>();
  const headers = {
    id: {
      label: "ID",
    },
    name: {
      label: "Nombre"
    },
    status: {
      label: "Estatus",
      format: (value: string) => value === 'active' ? 'Activo' : 'Inactivo'
    },
    actions: {
      label: "Acciones",
      render: ({id}: {id: number}) => <>
        <button className="btn btn-warning ms-2" title="Editar" onClick={async (e) => {
          e.stopPropagation();
          handleShow();
          catalogService.getById('category', id).then((res) => {
             
            if(res.success){
              setObject(res.object);
            }
          });
        }}>
          <i className="fa-solid fa-pen-to-square"></i>
        </button>
        <button className="btn btn-danger ms-2" title="Borrar" onClick={async (e) => {
          e.stopPropagation();
          const response = await commonService.confirm('¿Estas seguro de borrar la Categoría?, esta acción es irreversible');
          if(response.isConfirmed){
            const res = await catalogService.delete('category', id);
            if(res.success){
              commonService.toast('Categoría borrado con éxito', 'success');
              reload();
            }else{
              commonService.toast('Error al borrar la Categoría', 'error');
            }
          }
        }}>
          <i className="fa-solid fa-trash-can"></i>
        </button>
      </>
    }
  };
  
  const getObject = (page: number, filter?: string) => catalogService.list('category', page, 10, filter).then((res) => {
    if (res.success && res.object?.data) {
      setPaginationResults(res.object);
    }
  });
    
  useMemo(() => getObject(page, filter), [page, filter]);
  
  const reload = () => getObject(page, filter);
  
  const [paginationResults, setPaginationResults] = useState<PaginationResult<Catalog[]>>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setObject(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  
  return (
    <>
      <DataTable
        create={(close: () => void) => <CreateCategory close={close} reload={reload}/>}
        onRowClick={viewItem}
        headers={headers}
        changePage={setPage}
        filter={setFilter}
        reload={reload}
        resultInfo={paginationResults}  
      />
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              object && <ViewCategory object={object} close={handleClose} reload={reload}/>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
