/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PaginationResult,
  Product,
  Sale,
  statusSale,
} from "../interfaces/interfaces";
import { AjaxService } from "./ajax";
const basePath = "sales";
export const salesService = {
  getPagination: (
    page: number,
    perPage?: number,
    name?: string,
    status?: statusSale,
    orderBy?: string,
    orderType?: string,
    filters?: any
  ) =>
    AjaxService.get<PaginationResult<Sale>>({
      url: `${basePath}`,
      data: {
        page,
        perPage,
        name,
        status,
        orderBy,
        orderType,
        filters,
      },
    }),
  delete: (id: number) =>
    AjaxService.delete<Sale>({
      url: `${basePath}/${id}`,
    }),
  create: (data: Record<string, any>) =>
    AjaxService.post<Product>({
      url: `${basePath}`,
      data,
    }),
  get: (id: number) =>
    AjaxService.get<Sale>({
      url: `${basePath}/${id}`,
    }),
  update: (id: number, data: Record<string, any>) =>
    AjaxService.put<Sale>({
      url: `${basePath}/${id}`,
      data,
    }),
  getPdf: (id: number) =>
    AjaxService.get<{ pdf: string }>({
      url: `${basePath}/${id}/pdf`,
    }),
  getLog: (id: number, page: number, perPage?: number, name?: string) =>
    AjaxService.get<any>({
      url: `${basePath}/${id}/log`,
      data: {
        page,
        perPage,
        name,
      },
    }),
  toOrder: (id: number) =>
    AjaxService.post<any>({
      url: `${basePath}/${id}/toOrder`,
    }),
};
