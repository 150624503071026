import {
  LoginResponse,
  RefreshResponse,
} from "../interfaces/interfaces";
import { useAuthStore } from "../stores/authStore";
import { AjaxService } from "./ajax";
const authState = useAuthStore.getState();

export const authService = {
  login: async (
    email: string,
    password: string
  ) =>
    AjaxService.post<LoginResponse>({
      url: "login",
      data: { email, password },
      headers: {},
    }).then((response) => {
      if (response.success && response.object) {
        authState.setUser(response.object.login.user);
        authState.login(response.object.login.authorization);
      }
      return response;
    }),
  refreshTokens: async () =>
    AjaxService.post<RefreshResponse>({ url: "refresh"}).then((response) => {
      if(response.success && response.object){
        authState.login(response.object.refresh.authorization);
      }
    }),
  logout: () => {
    authState.logout();
  }
 
};
