/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Response<T> {
  success: boolean;
  message: string;
  object?: T;
}

export interface RegisterResponse {
  register: Register
}

export interface LoginResponse {
  login: Register
}

export interface RefreshResponse {
  refresh: Register
}

export interface Register {
  user: User
  authorization: Authorization
}

export interface User {
  name: string
  email: string
  lastname: string
  role: string
  id: number
  status: string
  permissions: any[]
}

export interface Permission {
  id: number
  user_id: number
  role: string
  module_id: number
  action: string
  module: Module
}

export interface Module {
  id: number
  name: string
  status: string
}

export interface Authorization {
  token?: string
  type: string
}

export interface PaginationResult<T> {
  current_page: number
  data: T[]
  first_page_url: string
  from?: string
  last_page: number
  last_page_url: string
  links: Links[]
  next_page_url?: string
  path: string
  per_page: number
  prev_page_url: string
  to?: string
  total: number
}

export interface Links {
  url: string
  label: string
  active: boolean
}

export interface GeneralItem {
  [key: string]: any
}

export interface Product extends GeneralItem{
  id: number
  name: string
  status: string
  image?: string
  description: string
  short_description: string
  long_description?: string
  price: string
  tax: string
  category_id: number
  brand_id?: number
  created_at?: string
  updated_at?: string
  deleted_at?: string
  category?: Category
  brand?: Brand
}

export interface ProductRow extends Product {
  cost: number
  quantity: number
  tax_percent: number
  tax_amount: number
  subtotal: number
  total: number
}

export interface Category {
  id: number
  name: string
  status: string
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface Brand {
  id: number
  name: string
  status: string
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface Catalog {
  id: number
  name: string
}

export interface Customer {
  id: number
  name: string
  lastname: string
  address: string
  phone: string
  city: string
  state: string
  website?: string
  group_id?: string
  status: string
  rfc?: string
  fiscal_address?: string
  zipcode?: string
  country?: string
  use_cfdi?: string
  created_at: string
  updated_at: string
  deleted_at?: string
  contacts: Contacts[]
}

export interface Contacts {
  id: number
  name: string
  lastname: string
  phone: string[]
  email: string[]
  image: string
  type: string
  status: string
  created_at: string
  updated_at: string
  deleted_at?: string
  pivot: CustomerContact
}

export interface CustomerContact {
  customer_id: number
  contact_id: number
}

export const mapRoutesNames: Record<string, string> = {
  brands: "Marcas",
  products: "Productos",
  categories: "Categorías",
  customers: "Clientes",
  users: "Usuarios",
};

export const features = [
  {
    label: "Unidad de Medida",
    id: "unit",
    values: ["Pieza", "Cantidad", "Metros"]
  },
  {
    label: "Calibre",
    id: "caliber",
  },
  {
    label: "Medidas",
    id: "measures",
  },
  {
    label: "Dirección",
    id: "address",
  },
  {
    label: "Teléfono",
    id: "phone",
  
  }
]

export const states = ["Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Ciudad de México",
  "Coahuila de Zaragoza",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "Estado de México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

export interface Quotes {
  id: number
  name: string
  customer_id: number
  supplier_id?: number
  contact_id?: number
  user_id: number
  email: string
  phone: string
  status: string
  subtotal: string | number
  tax: string | number
  discount: string | number
  total: string | number
  shipping_address: string
  shipping_city: string
  shipping_zipcode: string
  shipping_state: string
  shipping_country: string
  notes?: string
  expiration_date: string
  created_at: string
  updated_at: string
  deleted_at?: string
  customer?: Customer
  supplier?: any
  quotes_details: Quotes_details[]
}

export interface Customer {
  id: number
  name: string
  lastname: string
  address: string
  phone: string
  city: string
  state: string
  website?: string
  group_id?: string
  status: string
  rfc?: string
  fiscal_address?: string
  zipcode?: string
  country?: string
  use_cfdi?: string
  created_at: string
  updated_at: string
  deleted_at?: string
  email?: string
}

export interface Quotes_details {
  unit_price: any;
  name?: string;
  id: number
  quote_id: number
  product_id: number
  quantity: string
  unit_cost: string
  tax_rate: string
  tax: string
  discount: string
  status: string
  product: Product
  price?: number | string
  tax_percent?: number | string
  total?: number | string
  subtotal?: number | string
  tax_amount?: number | string
  created_at: string
  updated_at: string
  deleted_at?: string
}

export const statusQuotes = {
  draft: "Borrador",
  pending: "Pendiente",
  approved: "Aprobado",
  rejected: "Rechazado",
  archived: "Archivado"
};

export const statusQuotesClass = {
  draft: "",
  pending: "bg-warning",
  approved: "bg-success text-light",
  rejected: "bg-danger text-light",
  archived: ""
};

export const statusSalesClass = {
  pending: "bg-warning",
  processing: "",
  authorized: "bg-success text-light",
  paid: "bg-success text-light",
  refunded: "bg-danger text-light",
  chargeback: "bg-danger text-light",
  cancelled: "bg-danger text-light"
};

export const statusSales = {
  pending: "Pendiente",
  processing: "Procesando",
  authorized: "Autorizado",
  paid: "Pagado",
  refunded: "Reembolsado",
  chargeback: "Reembolso",
  cancelled: "Cancelado"
};

export type statusSale = '' | 'pending' | 'processing' | 'authorized' | 'paid' | 'refunded' | 'chargeback' | 'cancelled'

export interface Sale {
  id: number
  customer_id: number
  name?: string
  contact_id?: any
  user_id: number
  quote_id: number
  email?: string
  phone?: string
  subtotal: string | number
  tax: string | number
  discount: string | number
  total: string | number
  shipping_address: string
  shipping_city: string
  shipping_zipcode: string
  shipping_state: string
  shipping_country: string
  notes?: any
  status: string
  created_at: string
  updated_at: string
  deleted_at?: any
  user?: any
  customer: Customer
  sales_details: Sales_details[]
}

export interface Sales_details {
  id: number
  sale_id: number
  product_id: number
  product?: Product
  quantity: string
  unit_cost: string
  unit_price: string
  subtotal: string
  tax_rate: string
  tax: string
  discount: string
  total: string
  status: string
  created_at: string
  updated_at: string
  deleted_at?: any
}


export interface Modules {
  active: boolean;
  id: number
  name: string
  status: string
}


export const mapModuleNames = {
  products: 'Productos',
  brands: 'Marcas',
  categories: 'Categorias',
  customers: 'Clientes',
  quotes: 'Cotizaciones',
  sales: 'Ventas',
  users: 'Usuarios',
  modules: 'Modulos',
  purchases: 'Compras',
  suppliers: 'Proveedores',
  staff: 'Empleados',
  'technical-sheet': "Hoja Técnica"
};


export interface Deliveries {
  id?: number
  user_id: number
  user?: User
  sales_id: number
  sale?: Sale
  expiring_date?: string
  delivery_date?: string
  image?: string
  details?: DeliveriesDetails[]
  notes?:string
  deleted_at?: string
  created_at?: string
  updated_at?: string
}

export interface DeliveriesDetails {
  id?: number
  delivery_id?: number
  product_id?: number
  product?: Product
  quantity?: number | string
  unit_price?: number | string
  total?: number | string
  deleted_at?: string
  created_at?: string
  updated_at?: string
}

export interface Sender {
  id?: string
  customer_id?: string
  sale_id?: string
  sale?: Sale
  name?: string
  address?: string
  city?: string
  state?: string
  zip?: string
  phone?: string
  rfc?: string
  type?: string
  is_payed?: string
  deliver_by?: string
  created_at?: string
  updated_at?: string
  notes?: string
}


export interface Suppliers {
  id?: string | number
  name?: string
  address?: string
  phone?: string
  city?: string
  state?: string
  website?: string
  group_id?: string
  email?:string
  rfc?:string
  zipcode?:string
  use_cfdi?:string
  country?:string,
  status?: 'active' | 'inactive'
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface Purchases {
  id?: string
  name?: string
  supplier_id?: string | number
  supplier?: Suppliers
  user_id?: string
  quote_id?: string
  quote?: Quotes
  user?: User
  notes?: string
  status?: 'pending' | 'processing' | 'authorized' | 'paid' | 'refunded' | 'chargeback' | 'cancelled'
  subtotal?: string | number
  tax?: string | number
  discount?: string | number
  total?: string | number
  shipping_address?: string
  shipping_city?: string
  shipping_zipcode?: string
  shipping_state?: string
  shipping_country?: string
  expiring_date?: string
  delivery_date?: string
  created_at?: string
  updated_at?: string
  deleted_at?: string
  email?: string
  phone?: string
  purchase_order_details?: Purchases_details[]
}

export interface Purchases_details {
  id?: string
  purchase_order_id?: string
  product_id?: string
  product?: Product
  quantity?: string | number
  unit_cost?: string | number
  tax_rate?: string | number
  tax?: string | number
  discount?: string | number
  total?: string | number
  status?: 'active' | 'inactive'
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface Supplies {
  id?: number
  name: string
  description?: string
  type?: 'product' | 'service'
  created_at?: any
  updated_at?: any
}


export interface TechnicalSheet {
  id?: number
  product_id: number
  description?: string
  length?: any
  width?: any
  height?: any
  image?: any
  created_at?: any
  updated_at?: any
  details?: TechnicalSheetDetails[]
  product?: Product
}

export interface TechnicalSheetDetails {
  id?: number
  technical_sheet_id?: number
  supply_id?: number
  supplier_id?: any
  supplier?: Suppliers
  type?: string
  notes?: any
  features: Features[]
  created_at?: any
  updated_at?: any
  supply: Supply
}

export interface Features {
  label?: string
  value?: number
  id?: string
}

export interface Supply {
  id: number
  name: string
  description: string
  type: string
  created_at?: any
  updated_at?: any
}

export interface Extra_fields {
  clave_sat: string
  unidad_sat: string
  unidad_texto: string
  codigo_agrupador: string
}

export interface Staff{
  id?: number
  name?: string 
  code?: string 
  email?: string 
  position?: string
  start_on?: string
}
