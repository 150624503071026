/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Deliveries } from "../interfaces/interfaces";

interface DeliveriesState extends Deliveries {
}

interface DeliveriesActions {
  setValues: (key: string, value: any) => void;
  reset: () => void;
  deleteAllItems: () => void;
  addItem: (item: any) => void;
  updateItem: (index: number, changes: any) => void;
  removeItem: (index: number) => void;
  getState: () => DeliveriesState;
  initSale: (init: Partial<DeliveriesState>) => void;
}

const initialState: DeliveriesState = {
  user_id: 0,
  sales_id: 0,
  details: [],
  sale: undefined,
  expiring_date: '',
  notes: '',
  user: undefined,
};

export const useDeliveriesStore = create<DeliveriesState & DeliveriesActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setValues: (key, value) => set({ [key]: value }),
        initSale: (init) =>
          set({
            ...init,
          }),
        reset: () => {
          set({ ...initialState });
        },
        deleteAllItems: () => set({ details: [] }),
        addItem: (item) => {
          set((state) => {
            const details = state.details ?? [];
            const element = get().sale?.sales_details.find(e => e.product_id === item.id)
            const elementExist = details.find(e => e.product_id === item.id)
            if (elementExist) {
              return {
                details: [
                  ...details,
                ],
              };
            }
            
            return {
              details: [
                ...details,
                {
                  ...element
                },
              ],
            };
            //  ({details: [...state.details, item]})),
          });
          // this.calculateTotal(); 
        },
        updateItem(index, changes) {
          set((state) => {
            const details = state.details ?? [];
            details[index] = { ...details[index], ...changes };
            // Update Total Item
            const price = Number(details[index].unit_price ?? 0);
            const quantity = Number(details[index].quantity ?? 0);
            const total = quantity * price;
            details[index] = {
              ...details[index],
              total,
            };

            return { details };
          });
        },
        removeItem(index) {
          set((state) => {
            const details = state.details ?? [];
            details.splice(index, 1);
            return { details };
          });
        },
        getState: () => ({
          ...initialState,
          details: get().details,
          user_id: get().user_id,
          sales_id: get().sales_id,
          expiring_date: get().expiring_date,
          delivery_date: get().delivery_date,
          image: get().image,
          sale: get().sale,
          notes: get().notes,
        }),
      }),
      {
        name: "crisa-deliveries",
      }
    )
  )
);
