/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { commonService } from "../../services/commonService";
import { states } from "../../interfaces/interfaces";
import AsyncSelect from "react-select/async";
import { productsService } from "../../services/productsService";
import { useLayoutStore } from "../../stores/layoutStore";
import { Logs } from "../../components/logs";
import { usePurchasesStore } from "../../stores/purchasesStore";
import { purchasesService } from "../../services/purchasesService";
import { ListPurchasePayments } from "./listPurchasePayments";

export interface SalesEditProps {
  close?: () => void;
  reload?: () => void;
}

export const PurchaseEdit: FC<SalesEditProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const setModule = useLayoutStore((state) => state.setModule);
  const [disableEdit, setDisableEdit] = useState(true);
  const backToList = () => {
    purchaseStore.reset();
    navigate("/movements/purchases");
  };
  const [loading, setLoading] = useState(false);
  const purchaseStore = usePurchasesStore();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = purchaseStore.getState();
    if (payload.details?.length === 0) {
      commonService.toast("Debe agregar productos a la Compra", "error");
      return;
    }

    setLoading(true);
    const response = await purchasesService.update(Number(id ?? 0), payload);
    if (response.success) {
      commonService.toast(response.message, "success");
      backToList();
      setLoading(false);
      return;
    }
    commonService.toast(response.message, "error");
    setLoading(false);
  };

  const getProducts = (filter?: string) =>
    productsService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
      }
      return [];
    });
  const addItem = (element: any) => {
    if (element) {
      const item = purchaseStore?.details?.find(
        (item) => item.id === element.id
      );
      if (!item) purchaseStore.addItem(element);
    }
  };
  useEffect(() => {
    purchaseStore.calculateTotal();
  }, [purchaseStore.details, purchaseStore.discount]);

  useEffect(() => {
    if (id) {
      setLoad(true);
      purchasesService.get(Number(id)).then((res) => {
        if (res.success && res.object) {
          const { ...rest } = res.object;
          purchaseStore.initSale(res.object);
          if (res.object.status === "pending") {
            setDisableEdit(false);
          }
          // quotesStore.setValues(res.object);
        }
        setLoad(false);
      });
      setModule("Compra #" + id);
    } else {
      backToList();
    }
  }, []);

  return (
    <>
      {load && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!load && (
        <Tabs defaultActiveKey="home" className="mb-3">
          <Tab eventKey="home" title="Información General">
            <Form onSubmit={handleSubmit} method="POST">
              <Row>
                <Col sm="6">
                  <strong>Proveedor: </strong> {purchaseStore?.supplier?.name}{" "}
                  <br />
                  <strong>RFC: </strong> {purchaseStore?.supplier?.rfc}
                </Col>
                <Col sm="6">
                  <strong>Creado:</strong>{" "}
                  {commonService.formatDate(
                    `${purchaseStore?.created_at}`,
                    "DD/MM/YYYY hh:mm a"
                  )}{" "}
                  <br />
                  <strong>Creado Por:</strong> {purchaseStore?.user?.name}{" "}
                  <br />
                  <strong>Última Actualización:</strong>{" "}
                  {commonService.formatDate(
                    `${purchaseStore?.updated_at}`,
                    "DD/MM/YYYY hh:mm a"
                  )}{" "}
                  <br />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Proyecto
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        disabled={disableEdit}
                        type="text"
                        name="name"
                        required
                        value={purchaseStore.name}
                        onChange={(e) =>
                          purchaseStore.setValues("name", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm="6" as={Row}>
                  <Form.Label column sm="3">
                    Estatus
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      aria-label="Default select example"
                      name="state"
                      disabled={disableEdit}
                      className="form-select"
                      value={purchaseStore.status}
                      onChange={(e) =>
                        purchaseStore.setValues("status", e.target.value)
                      }
                    >
                      <option value="pending">Pendiente</option>
                      <option value="authorized">Aprobado</option>
                      <option value="cancelled">Cancelado</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>

              <>
                <hr />

                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Dirección <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          as="textarea"
                          disabled={disableEdit}
                          rows={5}
                          name="address"
                          value={purchaseStore.shipping_address}
                          onChange={(e) =>
                            purchaseStore.setValues(
                              "shipping_address",
                              e.target.value
                            )
                          }
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Ciudad <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          disabled={disableEdit}
                          name="created"
                          required
                          value={purchaseStore.shipping_city}
                          onChange={(e) =>
                            purchaseStore.setValues(
                              "shipping_city",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      Estado
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        aria-label="Default select example"
                        disabled={disableEdit}
                        name="state"
                        className="form-select"
                        value={purchaseStore.shipping_state}
                        onChange={(e) =>
                          purchaseStore.setValues(
                            "shipping_state",
                            e.target.value
                          )
                        }
                      >
                        <option></option>
                        {states.map((item, i) => (
                          <option key={i} value={`${item}`}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Código Postal <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          disabled={disableEdit}
                          name="zipcode"
                          required
                          value={purchaseStore.shipping_zipcode}
                          onChange={(e) =>
                            purchaseStore.setValues(
                              "shipping_zipcode",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      País
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        aria-label="Default select example"
                        disabled={disableEdit}
                        name="country"
                        className="form-control"
                      >
                        <option value="México">México</option>
                      </Form.Select>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Email <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="email"
                          name="email"
                          disabled={disableEdit}
                          required
                          value={purchaseStore.email}
                          onChange={(e) =>
                            purchaseStore.setValues("email", e.target.value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col as={Row}>
                    <Form.Label column sm="3">
                      Teléfono <small className="text-danger">*</small>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="phone"
                        disabled={disableEdit}
                        value={purchaseStore.phone}
                        onChange={(e) =>
                          purchaseStore.setValues("phone", e.target.value)
                        }
                        required
                      />
                    </Col>
                  </Col>
                </Row>
                <hr />
                {!disableEdit && (
                  <Row className="mt-3 mb-3">
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={getProducts}
                      onChange={addItem}
                    />
                  </Row>
                )}
                <Row>
                  <Table striped bordered hover className="w-100">
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }}>Producto</th>
                        <th style={{ width: "10%" }}>Cantidad</th>
                        <th style={{ width: "10%" }}>Precio</th>
                        <th style={{ width: "10%" }}>Impuesto</th>
                        <th style={{ width: "15%" }}>Total</th>
                        <th style={{ width: "5%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseStore?.details &&
                        purchaseStore?.details?.map((element, index) => (
                          <tr key={`key-${index}`}>
                            <td>{element.product?.name}</td>
                            <td>
                              <Form.Control
                                type="number"
                                name="quantity"
                                disabled={disableEdit}
                                required
                                value={element.quantity}
                                onChange={(e) => {
                                  purchaseStore.updateItem(index, {
                                    quantity: e.target.value,
                                  });
                                  purchaseStore.calculateTotal();
                                }}
                                step="1.00"
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="price"
                                required
                                value={element.price}
                                onChange={(e) => {
                                  purchaseStore.updateItem(index, {
                                    price: e.target.value,
                                  });
                                  purchaseStore.calculateTotal();
                                }}
                                step="1.00"
                              />
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                name="iva"
                                disabled={disableEdit}
                                value={element.tax_percent}
                                onChange={(e) => {
                                  purchaseStore.updateItem(index, {
                                    tax_percent: e.target.value,
                                  });
                                  purchaseStore.calculateTotal();
                                }}
                              >
                                <option value="0.00">0 %</option>
                                <option value="0.16">16 %</option>
                              </Form.Select>
                            </td>
                            <td className="text-right">
                              {commonService.toCurrency(`${element.total}`)}
                            </td>
                            <td>
                              {!disableEdit && (
                                <Button
                                  variant="danger"
                                  onClick={() => {
                                    purchaseStore.removeItem(index);
                                    purchaseStore.calculateTotal();
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      {purchaseStore?.details &&
                        purchaseStore?.details?.length > 0 && (
                          <>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Subtotal</td>
                              <td className="text-right">
                                {commonService.toCurrency(
                                  `${purchaseStore.subtotal}`
                                )}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Impuesto</td>
                              <td className="text-right">
                                {commonService.toCurrency(
                                  `${purchaseStore.tax}`
                                )}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Descuento</td>
                              <td className="text-right">
                                <Form.Control
                                  type="number"
                                  className="text-right"
                                  name="discount"
                                  disabled={disableEdit}
                                  value={purchaseStore.discount}
                                  onChange={(e) =>
                                    purchaseStore.setValues(
                                      "discount",
                                      e.target.value
                                    )
                                  }
                                  step="1.00"
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Total</td>
                              <td className="text-right">
                                {commonService.toCurrency(
                                  `${purchaseStore.total}`
                                )}
                              </td>
                              <td></td>
                            </tr>
                          </>
                        )}
                    </tbody>
                  </Table>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Observaciones
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="notes"
                          disabled={disableEdit}
                          value={purchaseStore.notes}
                          onChange={(e) =>
                            purchaseStore.setValues("notes", e.target.value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </>

              <div className="text-center">
                <Button
                  variant="danger"
                  id="button-addon2"
                  disabled={loading}
                  onClick={async () => {
                    if (disableEdit) {
                      backToList();
                      return;
                    }
                    const res = await commonService.confirm(
                      "Se perderán los cambios no guardados, ¿Esta seguro?"
                    );
                    if (res.isConfirmed) {
                      backToList();
                    }
                  }}
                >
                  Cancelar
                </Button>
                {!disableEdit && (
                  <Button
                    type="submit"
                    variant="primary"
                    id="button-addon2"
                    className="ms-2"
                    disabled={loading}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                      hidden={!loading}
                    />
                    Guardar
                  </Button>
                )}
              </div>
            </Form>
          </Tab>
          <Tab eventKey="payments" title="Pagos">
            <ListPurchasePayments id={`${id}`} />
          </Tab>
          <Tab eventKey="logs" title="Logs">
            <Logs tableName="purchase_orders" id={id ?? ""} />
          </Tab>
        </Tabs>
      )}
    </>
  );
};
