/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { routes } from "../routes";
import { useAuthStore } from "../stores/authStore";
import { useLocation } from "react-router-dom";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export const SideBar: React.FC = () => {
  const auth = useAuthStore();
  const permissions = auth.getPermissions();
  const location = useLocation();
  const [navBar, ] = useAutoAnimate();
  const toggleSubMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
     
    const target = e.currentTarget;
    target?.parentElement?.querySelector(".submenu")?.classList.toggle("expand");
  };
  
  return (
    <nav
      id="sidebar"
      className="col-2 d-md-block sidebar collapse fondo-menu"
      ref={navBar}
      style={{ overflowY: 'auto'}}
    >
      <div className="position-sticky">
        <div className="icono-user">   
          <i className="fa-solid fa-circle-user"></i><strong className="user">{auth.user?.name} {auth.user?.lastname}</strong>
        </div>
        
        <div>
          <ul className="nav flex-column m-2 p-2">
            {routes.length > 0 &&
              routes.map((element: any, index) => {
                if (
                  element.role === "administrator" &&
                  auth.user?.role !== "administrator"
                ) {
                  return null;
                }
                if(element.hidden){
                  return null;
                }
                if(element.module && auth.user?.role !== "administrator"){
                  if(!permissions.find(p => element.module === p)){
                    return null;
                  }
                }

                return (
                  <li className="nav-item" key={index}>
                    {element.children && element.children.length > 0 && (
                      <div style={{cursor: "pointer"}}>
                        <div onClick={toggleSubMenu} className="nav-link">
                          <i className={`fa-solid ${element.icon}`}></i>
                          <span className="ml-2">{element.label}</span>
                          <i className="fa-solid fa-chevron-down float-end"></i>
                        </div>
                        <ul className={element.children.find((e:any) => e.path === location.pathname) ? "submenu expand" : "submenu"}>
                          {element.children.map((child: any, index: string) => {
                            if (
                              child.role === "administrator" &&
                              auth.user?.role !== "administrator"
                            ) {
                              return null;
                            }
                            if(child.hidden){
                              return null;
                            }
                            if(child.module && auth.user?.role !== "administrator"){
                              if(!permissions.find(p => child.module === p)){
                                return null;
                              }
                            }
                            return (
                              <li key={"child-" + index}>
                                <a className={location.pathname === child.path ? 'nav-link active' : 'nav-link'} href={child.path}>
                                  <i className={`fa-regular ${child.icon}`}></i>
                                  <span className="ml-2">{child.label}</span>
                                </a>
                              </li>);
                          })}
                        </ul>
                      </div>
                    )}
                    {!element.children && (
                      <a className={location.pathname === element.path ? 'nav-link active' : 'nav-link'} href={element.path}>
                        <i className={`fa-solid ${element.icon}`}></i>
                        <span className="ml-2">{element.label}</span>
                      </a>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
        
      </div>
    </nav>
  );
};
