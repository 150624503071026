/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Table,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { customerService } from "../../services/customersService";
import { Contacts, Customer, states } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";

export interface EditCustomersProps {}

export const CustomersEdit: FC<EditCustomersProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [object, setObject] = useState<Customer>();
  const [contact, setContact] = useState<Contacts>();
  useEffect(() => {
    if (id) {
      refreshInfo();
    }
  }, []);

  const refreshInfo = () => {
    customerService.get(Number(id)).then((data) => {
      if (data.success) {
        const fields = data.object;
        setObject(fields);
        for (const field in fields) {
          const input: any = document.querySelector(`[name='${field}']`);
          if (input) {
            input.value = fields[field as keyof Customer];
          }
        }
      }
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
    customerService.update(Number(id), values).then((response) => {
      if(response.success){
        commonService.toast("Cliente creado correctamente", "success");
        navigate("/movements/customers");
      }
    });
  };
  const handleSubmitContact = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
     
    customerService.upsertContacts(values).then((data) => {
       
      if (data.success) {
        commonService.toast("Contacto guardado", "success");
        refreshInfo();
        setShow(false);
        setContact(undefined);
      }
    });
     
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setObject(undefined);
    setShow(false);
  };
  useEffect(() => {
    if (contact) {
      document
        ?.querySelector('[name="id"]')
        ?.setAttribute("value", `${contact.id}`);
      document
        ?.querySelector('[name="contact_name"]')
        ?.setAttribute("value", `${contact?.name}`);
      document
        ?.querySelector('[name="contact_phone"]')
        ?.setAttribute("value", `${contact.phone}`);
      document
        ?.querySelector('[name="contact_email"]')
        ?.setAttribute("value", `${contact.email}`);
    }
  }, [contact]);

  const contactForm = useRef(null);
  return (
    <>
      <>
        <Form onSubmit={handleSubmit} method="POST">
          <Tabs defaultActiveKey="home" className="mb-3">
            <Tab eventKey="home" title="Información General">
              <Row>
                <h5>Cliente {id}</h5>
              </Row>
              <Form.Group as={Row} className="mb-3">
                <input type="hidden" name="id" value={id} />
                <Form.Label column sm="2">
                  Nombre <small className="text-danger">*</small>
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" name="name" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Dirección
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" name="address" />
                </Col>
              </Form.Group>
              <div className="row">
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Teléfono
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="phone" />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Email
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="email" />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-12">
                  <Form.Label column sm="12">
                    Ciudad
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="city" />
                  </Col>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Estado
                  </Form.Label>
                  <Col sm="12">
                    <select className="form-select" name="state">
                      <option></option>
                      {states.map((item, i) => (
                        <option
                          key={i}
                          value={`${item}`}
                          selected={item === object?.state}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Sitio web
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="website" />
                  </Col>
                </Form.Group>
              </div>
            </Tab>
            <Tab eventKey="fiscal" title="Información Fiscal">
              <div className="row">

                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    RFC
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="rfc" style={{textTransform: "uppercase"}}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Código Postal
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="zipcode" />
                  </Col>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Uso de CFDI
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="use_cfdi" />
                  </Col>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-6">
                  <Form.Label column sm="12">
                    Regimen Fiscal
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control type="text" name="country" />
                  </Col>
                </Form.Group>
              </div>
            </Tab>
            <Tab eventKey="contacts" title="Contactos">
              <InputGroup className="mb-3">
                <Button
                  variant="primary"
                  className="float-end"
                  id="button-addon2"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  +
                </Button>
              </InputGroup>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Telefono</th>
                    <th>Email</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {object?.contacts.map((contact, index) => (
                    <tr key={index}>
                      <td>{contact.id}</td>
                      <td>{contact.name}</td>
                      <td>{contact.phone}</td>
                      <td>{contact.email}</td>
                      <td>
                        <Button
                          variant="primary"
                          id="button-addon2"
                          className="mt-1 ms-2"
                          onClick={() => {
                            customerService
                              .getContacts(contact.id)
                              .then((response) => {
                                if (response.success) {
                                  setContact(response?.object);
                                  setShow(true);

                                  // contact_name
                                  // contact_phone
                                  // contact_email
                                }
                              });
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </Button>
                        <Button
                          variant="danger"
                          id="button-addon2"
                          className="mt-1 ms-2"
                          onClick={async () => {
                            const response = await commonService.confirm("¿Esta seguro de borrar el contacto?, esta acción no puede deshacerse.");

                            if(response.isConfirmed){
                              customerService
                                .deleteContact(contact.id)
                                .then((response) => {
                                  if (response.success) {
                                    commonService.toast(
                                      "Contacto borrado",
                                      "success"
                                    );
                                    refreshInfo();
                                  }
                                });
                            }
                          }}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
            
          </Tabs>
          <div className="text-center">
            <Button
              variant="danger"
              id="button-addon2"
              onClick={() => navigate(-1)}
            >
              Atrás
            </Button>
            <Button
              type="submit"
              variant="primary"
              id="button-addon2"
              className="ms-2"
            >
              Guardar
            </Button>
          </div>
        </Form>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Contacto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={contactForm} onSubmit={handleSubmitContact}>
              <input type="hidden" name="customer_id" value={id} />
              <input type="hidden" name="id" />
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Nombre <small className="text-danger">*</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  name="contact_name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Teléfono"
                  name="contact_phone"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="contact_email"
                />
              </Form.Group>
              <div className="text-center mt-3">
                <Button
                  variant="danger"
                  id="button-addon2"
                  onClick={() => setShow(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  id="button-addon2"
                  className="ms-2"
                >
                  Guardar
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};
