/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button, Modal, Table, Spinner, Tab, Tabs, DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { commonService } from "../../services/commonService";
import { Destiny } from "../../components/destiny";
import { useQuotesStore } from "../../stores/quotesStore";
import { states } from "../../interfaces/interfaces";
import AsyncSelect from "react-select/async";
import { productsService } from "../../services/productsService";
import { quotesService } from "../../services/quotesService";
import { useLayoutStore } from "../../stores/layoutStore";
import { Logs } from "../../components/logs";

export interface QuotesEditProps {
  close?: () => void;
  reload?: () => void;
}

export const QuotesEdit: FC<QuotesEditProps> = ({ reload }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const setModule = useLayoutStore(state => state.setModule);
  const [disableEdit, setDisableEdit] = useState(false)
  const backToList = () => {
    quotesStore.reset();
    navigate("/movements/quotes");
  };
  const [loading, setLoading] = useState(false);
  const quotesStore = useQuotesStore();
    const handleSubmit = async (event: any) => {
    event.preventDefault();
    const payload = quotesStore.getState();
    if (payload.details?.length === 0) {
      commonService.toast("Debe agregar productos a la cotización", "error");
      return;
    }
    if (!payload.destiny) {
      commonService.toast("Debe seleccionar un destino", "error");
      return;
    }
    setLoading(true);
    const response = await quotesService.update(Number(id ?? 0), payload);
    if (response.success) {
      commonService.toast(response.message, "success");
      backToList();
      setLoading(false);
      return;
    }
    commonService.toast(response.message, "error");
    setLoading(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setObject(undefined);
    setShow(false);
  };
  const getProducts = (filter?: string) =>
    productsService.getPagination(1, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        // commonService.log('obj', res.object.data)
        return res.object.data.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
      }
      return [];
    });
  const addItem = (element: any) => {
    if (element) {
      const item = quotesStore?.details?.find((item) => item.id === element.id);
      if (!item) quotesStore.addItem(element);
    }
  };
  useEffect(() => {
    quotesStore.calculateTotal();
  }, [quotesStore.details, quotesStore.discount]);

  useEffect(() => {
    if (id) {
      setLoad(true);
      quotesService.get(Number(id)).then((res) => {
        if (res.success && res.object) {
          const {
            customer,
            supplier,
            quotes_details: quotesDetails,
            ...rest
          } = res.object;
           
          quotesStore.initQuote(res.object);
          if (customer) {
            quotesStore.setDestiny({ 
              ...customer, 
              type: "Clientes", 
              address: rest.shipping_address,
              city: rest.shipping_city,
              state: rest.shipping_state,
              zipcode: rest.shipping_zipcode,
              email: rest.email,
              phone: rest.phone 
            });
          }
          if (supplier) {
            quotesStore.setDestiny({ ...supplier, type: "Proveedores" });
          }
          if(res.object.status === 'rejected' || res.object.status === 'approved'){
            setDisableEdit(true)
          }
          // quotesStore.setValues(res.object);
        }
        setLoad(false);
      });
      setModule("Cotización #" + id);
    }else{
      backToList();
    }
  }, []);
  const handleConvertTo = async (option: any) => {
    // if(option === '1') {
    const response = await commonService.confirm("¿Desea convertir la cotización a un pedido?");
    if(response.isConfirmed){
      const res = await quotesService.toOrder(Number(id));
      if(res.success){
        commonService.toast(res.message, "success");
        backToList();
        navigate('/movements/sales/' + res.object.id);
      }else{
        commonService.toast(res.message, "error");
      }
    }
    // }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Destiny close={handleClose} reload={reload} />
        </Modal.Body>
      </Modal>
      {load && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!load && (
        <Tabs defaultActiveKey="home" className="mb-3">
          <Tab eventKey="home" title="Información General">
            <Row>
              <Col>
              {
                !disableEdit && (
                  <Button variant="success" onClick={handleConvertTo}>
                    Convertir a Pedido
                  </Button>
                )
              }
              </Col>
            </Row>
            <Form onSubmit={handleSubmit} method="POST">
            {quotesStore.destiny && (
                <>
              <Row>
                <Col sm="6">
                  {quotesStore.destiny?.type === "Clientes" ? (
                    <p>
                      <strong>Cliente: </strong> {quotesStore.destiny?.name} <br />
                      <strong>RFC: </strong> {quotesStore.destiny?.rfc}
                    </p>
                  ) : (
                    <p>
                      <strong>Proveedor: </strong> {quotesStore.destiny?.name}
                    </p>
                  )}
                </Col>
                <Col sm="6">
                  <strong>Creado:</strong> {commonService.formatDate(quotesStore.created_at, 'DD/MM/YYYY hh:mm a')} <br />
                  <strong>Última Actualización:</strong> {commonService.formatDate(quotesStore.updated_at, 'DD/MM/YYYY hh:mm a')} <br />
                </Col>
              </Row>
              <hr />
              <Row className="mt-3">
                <Col sm="6">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                      Proyecto
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        disabled={disableEdit}
                        type="text"
                        name="name"
                        required
                        value={quotesStore.name}
                        onChange={(e) =>
                          quotesStore.setValues("name", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm="6" as={Row}>
                  <Form.Label column sm="3">
                    Estatus
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                    disabled={disableEdit}
                      aria-label="Default select example"
                      name="state"
                      className="form-select"
                      value={quotesStore.status}
                      onChange={(e) =>
                        quotesStore.setValues('status', e.target.value)
                      }
                    >
                      <option value="pending">Pendiente</option>
                      <option value="approved">Aprobado</option>
                      <option value="rejected">Rechazado</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>
              
                  <hr />
                  
                  <Row>
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Expiración <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            disabled={disableEdit}
                            type="date"
                            name="expiration_date"
                            required
                            value={commonService.formatDate(quotesStore.expiration_date, 'YYYY-MM-DD')}
                            onChange={(e) =>
                              quotesStore.setValues(
                                "expiration_date",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Dirección <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            as="textarea"
                            disabled={disableEdit}
                            rows={5}
                            name="address"
                            value={quotesStore.destiny.address}
                            onChange={(e) =>
                              quotesStore.setDestiny({
                                ...quotesStore.destiny,
                                address: e.target.value,
                              })
                            }
                            required
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Ciudad <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            name="created"
                            disabled={disableEdit}
                            required
                            value={quotesStore?.destiny?.city}
                            onChange={(e) =>
                              quotesStore.setDestiny({
                                ...quotesStore.destiny,
                                city: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col as={Row}>
                      <Form.Label column sm="3">
                        Estado
                      </Form.Label>
                      <Col sm="9">
                        <Form.Select
                          aria-label="Default select example"
                          name="state"
                          disabled={disableEdit}
                          className="form-control"
                          value={quotesStore?.destiny?.state}
                          onChange={(e) =>
                            quotesStore.setDestiny({
                              ...quotesStore.destiny,
                              state: e.target.value,
                            })
                          }
                        >
                          <option></option>
                          {states.map((item, i) => (
                            <option key={i} value={`${item}`}>
                              {item}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Código Postal <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            disabled={disableEdit}
                            name="zipcode"
                            required
                            value={quotesStore?.destiny?.zipcode}
                            onChange={(e) =>
                              quotesStore.setDestiny({
                                ...quotesStore.destiny,
                                zipcode: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col as={Row}>
                      <Form.Label column sm="3">
                        País
                      </Form.Label>
                      <Col sm="9">
                        <Form.Select
                          aria-label="Default select example"
                          name="country"
                          disabled={disableEdit}
                          className="form-control"
                        >
                          <option value="México">México</option>
                        </Form.Select>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Email <small className="text-danger">*</small>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="email"
                            name="email"
                            required
                            disabled={disableEdit}
                            value={quotesStore?.destiny?.email}
                            onChange={(e) =>
                              quotesStore.setDestiny({
                                ...quotesStore.destiny,
                                email: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col as={Row}>
                      <Form.Label column sm="3">
                        Teléfono <small className="text-danger">*</small>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          name="phone"
                          disabled={disableEdit}
                          value={quotesStore?.destiny?.phone}
                          onChange={(e) =>
                            quotesStore.setDestiny({
                              ...quotesStore.destiny,
                              phone: e.target.value,
                            })
                          }
                          required
                        />
                      </Col>
                    </Col>
                  </Row>
                  <hr />
                  {
                    !disableEdit && (
                      <Row className="mt-3 mb-3">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={getProducts}
                          onChange={addItem}
                        />
                      </Row>
                    )
                  }
                  <Row>
                    <Table striped bordered hover className="w-100">
                      <thead>
                        <tr>
                          <th style={{ width: "50%" }}>Producto</th>
                          <th style={{ width: "10%" }}>Cantidad</th>
                          <th style={{ width: "10%" }}>Precio / Costo</th>
                          <th style={{ width: "10%" }}>Impuesto</th>
                          <th style={{ width: "15%" }}>Total</th>
                          <th style={{ width: "5%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotesStore?.details &&
                          quotesStore?.details?.map((element, index) => (
                            <tr key={`key-${index}`}>
                              <td>{element?.name ? element?.name : element?.product?.name}</td>
                              <td>
                                <Form.Control
                                  type="number"
                                  name="quantity"
                                  disabled={disableEdit}
                                  required
                                  value={element.quantity}
                                  onChange={(e) => {
                                    quotesStore.updateItem(index, {
                                      quantity: e.target.value,
                                    });
                                    quotesStore.calculateTotal();
                                  }}
                                  step="1.00"
                                />
                              </td>
                              <td>
                                <Form.Label>Precio:</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="price"
                                  disabled={disableEdit}
                                  required
                                  value={element.price}
                                  onChange={(e) => {
                                    quotesStore.updateItem(index, {
                                      price: e.target.value,
                                    });
                                    quotesStore.calculateTotal();
                                  }}
                                  step="1.00"
                                />
                                <Form.Label>Costo:</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="cost"
                                  disabled={disableEdit}
                                  required
                                  value={element.unit_cost}
                                  onChange={(e) =>
                                    quotesStore.updateItem(index, {
                                      unit_cost: e.target.value,
                                    })
                                  }
                                  step="1.00"
                                />
                              </td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="iva"
                                  disabled={disableEdit}
                                  value={element.tax_percent}
                                  onChange={(e) => {
                                    quotesStore.updateItem(index, {
                                      tax_percent: e.target.value,
                                    });
                                    quotesStore.calculateTotal();
                                  }}
                                >
                                  <option value="0.00">0 %</option>
                                  <option value="0.16">16 %</option>
                                </Form.Select>
                              </td>
                              <td className="text-right">
                                {commonService.toCurrency(`${element.total}`)}
                              </td>
                              <td>
                                {
                                  !disableEdit && (
                                    <Button
                                      variant="danger"
                                      onClick={() => {
                                        quotesStore.removeItem(index);
                                        quotesStore.calculateTotal();
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>

                                  )
                                }
                              </td>
                            </tr>
                          ))}
                        {quotesStore?.details &&
                          quotesStore?.details?.length > 0 && (
                          <>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Subtotal</td>
                              <td className="text-right">
                                {commonService.toCurrency(`${quotesStore.subtotal}`)}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Impuesto</td>
                              <td className="text-right">
                                {commonService.toCurrency(`${quotesStore.tax}`)}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Descuento</td>
                              <td className="text-right">
                                <Form.Control
                                  type="number"
                                  className="text-right"
                                  name="discount"
                                  disabled={disableEdit}
                                  value={quotesStore.discount}
                                  onChange={(e) =>
                                    quotesStore.setValues(
                                      "discount",
                                      e.target.value
                                    )
                                  }
                                  step="1.00"
                                />
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Total</td>
                              <td className="text-right">
                                {commonService.toCurrency(`${quotesStore.total}`)}
                              </td>
                              <td></td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Observaciones
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="notes"
                            disabled={disableEdit}
                            value={quotesStore.notes}
                            onChange={(e) =>
                              quotesStore.setValues("notes", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}

              <div className="text-center">
                <Button
                  variant="danger"
                  id="button-addon2"
                  disabled={loading}
                  onClick={async () => {
                    if(disableEdit){
                      backToList();
                      return;
                    }
                    const res = await commonService.confirm(
                      "Se perderán los cambios no guardados, ¿Esta seguro?"
                    );
                    if (res.isConfirmed) {
                      backToList();
                    }
                  }}
                >
                  Cancelar
                </Button>
                {
                  !disableEdit && (
                    <Button
                      type="submit"
                      variant="primary"
                      id="button-addon2"
                      className="ms-2"
                      disabled={loading}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                        hidden={!loading}
                      />
                      Guardar
                    </Button>

                  )
                }
              </div>
            </Form>
          </Tab>
          <Tab eventKey="logs" title="Logs">
            <Logs tableName="quotes" id={id ?? ''} />
          </Tab>
        </Tabs>
      )}
    </>
  );
};
