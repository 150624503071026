import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Sale } from "../interfaces/interfaces";
import { useLayoutStore } from "../stores/layoutStore";
import { SalesIndex } from "../pages/sales";
import { useAuthStore } from "../stores/authStore";
import { useSenderStore } from "../stores/senderStore";
import { commonService } from "../services/commonService";

interface salesProps {
  close?: () => void;
  reload?: () => void;
}

export const SaleSenderSelector: React.FC<salesProps> = ({close}) => {
  const layoutStore = useLayoutStore();
  const userStore = useAuthStore();
  const senderStore = useSenderStore();
  const insideContent = (destiny: Sale) => {
    commonService.log('sales row', destiny)
    return <>
      <Button variant="success" onClick={(e) => {
        e.stopPropagation();
        commonService.log({destiny})
        senderStore.setValues('customer_id', destiny.customer_id)
        senderStore.setValues('sale_id', destiny.id)
        senderStore.setValues('sale', destiny)
        senderStore.setValues('name', destiny.customer?.name)
        senderStore.setValues('address', destiny.shipping_address)
        senderStore.setValues('city', destiny.shipping_city)
        senderStore.setValues('state', destiny.shipping_state)
        senderStore.setValues('zip', destiny.shipping_zipcode)
        senderStore.setValues('phone', destiny.phone)
        senderStore.setValues('rfc', destiny.customer.rfc)
        senderStore.setValues('user_id', userStore?.user?.id)

        // senderStore.setValues('type', destiny.type)
        // senderStore.setValues('is_payed', destiny.is_payed)
        // senderStore.setValues('deliver_by', destiny.deliver_by)
        close && close();
      }}>
        <i className="fa-solid fa-check"></i>
      </Button>
    </>;
  };

  return (
    <Form>
      <Form.Group as={Col} className="mb-3 col-12">
        <Col className="mt-1">
          Selecciona una venta del listado.
          <SalesIndex type="inside" insideContent={insideContent} />
        </Col>
      </Form.Group>
    </Form>
  );
};