import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Sale } from "../interfaces/interfaces";
import { useLayoutStore } from "../stores/layoutStore";
import { useSalesStore } from "../stores/salesStore";
import { SalesIndex } from "../pages/sales";
import { useDeliveriesStore } from "../stores/deliveriesStore";
import { useAuthStore } from "../stores/authStore";
import { commonService } from "../services/commonService";

interface salesProps {
  close?: () => void;
  reload?: () => void;
}

export const SaleSelector: React.FC<salesProps> = ({close}) => {
  const deliveriesStore = useDeliveriesStore();
  const layoutStore = useLayoutStore();
  const userStore = useAuthStore();
  const insideContent = (destiny: Sale) => {
    commonService.log('sales row', destiny)
    return <>
      <Button variant="success" onClick={(e) => {
        e.stopPropagation();
        commonService.log({destiny})
        deliveriesStore.setValues('sale', destiny);
        deliveriesStore.setValues('sales_id', destiny.id);
        deliveriesStore.setValues('user_id', userStore.user?.id);
        deliveriesStore.setValues('user', userStore.user?.id);
        deliveriesStore.setValues('details', [...destiny.sales_details]);
        layoutStore.setModule("Notas de Remisión");
        close && close();
      }}>
        <i className="fa-solid fa-check"></i>
      </Button>
    </>;
  };

  return (
    <Form>
      <Form.Group as={Col} className="mb-3 col-12">
        <Col className="mt-1">
          Selecciona una venta del listado.
          <SalesIndex type="inside" insideContent={insideContent} />
        </Col>
      </Form.Group>
    </Form>
  );
};