import React, { useEffect, useRef } from "react";
import { commonService } from "../../services/commonService";
import { authService } from "../../services/authService";
import { useAuthStore } from "../../stores/authStore";
import { useNavigate } from "react-router-dom";
import proemsa_logo from '../../../public/assets/images/proemsa.png';
import crisa_logo from '../../../public/assets/images/CrisaL.png';
export const Login: React.FC = () => {
  const user = useAuthStore((state) => state.user);
  const navigator = useNavigate();
  const form = useRef<HTMLFormElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (event?: any) => {
    event?.preventDefault();
    const data = new FormData(form?.current ?? undefined);
    const usuario = (data.get("user") as string) ?? "";
    const password = (data.get("password") as string) ?? "";
    if (usuario === "" || password === "") {
      commonService.toast("Ingrese usuario y contraseña", "error");
      return;
    }
    const { success, message } = await authService.login(usuario, password);
    if (success) {
      commonService.toast("Bienvenido", "success");
      navigator('/');
      return;
    } else {
       
      commonService.toast(`Usuario o Contraseña incorrecto, intente de nuevo`, "error");
    }
  };
  useEffect(() => {
    if (user?.id) {
      navigator("/");
    }
  }, []);



  return (
    <div className="container-fluid background-container">
      <div className="row">
        <div className="col-5 m-0 p-0">
          <div className="info-container w-100 text-center">
            <img src={crisa_logo} alt="Logo de Crisa" className="crisa-logo" />
            <h1>Transformando <span className="texto-crisa">Datos <br /></span> en <span className="texto-crisa">Estrategias</span></h1>
          </div>
        </div>
        <div className="col">
          <div className="row justify-content-center">
            <div className="col-6">
              <div className="card caja-login">
                <h2 className="card-header text-center">

                  <img src={proemsa_logo} alt="Logo de Proemsa" width={300} />
                  <h3>Iniciar Sesión</h3>
                </h2>

                <div className="card-body">
                  <form onSubmit={handleSubmit} ref={form}>
                    <div className="input-group mb-3">
                      <input type="text" placeholder="Usuario" id="email_address" className="form-control text-white" name="user" required />
                      <span className="input-group-text bg-transparent text-white">
                        <i className="fa-solid fa-user"></i>
                      </span>
                      
                    </div>
                    <div className="input-group mb-3">
                      <input type="password" placeholder="Contraseña" id="password" className="form-control text-white" name="password" required />
                      <span className="input-group-text bg-transparent text-white">
                        <i className="fa-solid fa-lock"></i>
                      </span>
                      

                    </div>
                    <div className="d-grid mx-auto">
                      <button type="submit" className="btn btn-primary text-black">Iniciar Sesión </button>

                    </div>
                  </form>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );
};
