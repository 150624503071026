/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import { GeneralItem, PaginationResult } from '../interfaces/interfaces'

export interface propsDataTable {
  title?: string
  filters?: any[]
  headers: Record<string, any>
  resultInfo?: PaginationResult<GeneralItem>
  changePage: (page: number) => void
  filter: (filter: string) => void
  reload: () => void
  simple?: boolean
  create?: (close: () => void) => ReactNode
  createUrl?: string
  onRowClick?: (row: Record<string, any>) => void
  generalFilter?: string
  setOrder?: (field: string, type: string) => void
}

export const DataTable: React.FC<propsDataTable> = ({
  headers,
  resultInfo,
  onRowClick,
  simple,
  filter,
  reload,
  changePage,
  create,
  title,
  createUrl,
  filters,
  generalFilter,
  setOrder: orderBy,
}) => {
  const [filterValue, setFilterValue] = useState('')
  const { data, total, prev_page_url, next_page_url, current_page } =
    resultInfo || { data: [], total: 0, current_page: 1 }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [or, setOr] = useState(true)
  const setOrder = (field?: string) => {
    if (orderBy && field && headers[field].order) {
      orderBy(field, or ? 'asc' : 'desc')
      setOr(!or)
    }
  }

  return (
    <>
      <div>
        {title && <h3 className="text-center">{title}</h3>}
        {!simple && (
          <InputGroup className="mb-3">
            <Form.Control
              name="filter"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              placeholder={generalFilter ? generalFilter : 'Filtre por nombre'}
              onKeyDown={(e) => {
                if(e.key === 'Enter'){
                  e.preventDefault();
                  filter(filterValue)
                }
              }}
            />
            <Button
              variant="primary"
              id="button-addon2"
              onClick={() => filter(filterValue)}
            >
              Buscar
            </Button>
            <Button
              variant="success"
              id="button-addon2"
              onClick={() => reload()}
            >
              <i className="fa-solid fa-rotate"></i>
            </Button>
            {create && (
              <Button
                variant="warning"
                id="button-addon2"
                onClick={() => handleShow()}
              >
                <i className="fa-solid fa-plus"></i>
              </Button>
            )}
            {createUrl && (
              <Button variant="warning" id="button-addon2" href={createUrl}>
                <i className="fa-solid fa-plus"></i>
              </Button>
            )}
          </InputGroup>
        )}
        <Row className="mb-3">
          {filters && (
            <>
              <strong>Filtros Avanzados</strong>
              <form className='row'>
              {filters.map((filter, index) => {
                if (filter.options && filter.options.length) {
                  return (
                    <>
                      <Col>
                        <Form.Select
                          key={`filter-${index}`}
                          aria-label="Default select example"
                          name={filter.name}
                          className="form-select"
                          onChange={(e) =>
                            filter.onChange && filter.onChange(e.target.value)
                          }
                        >
                          <option value="">{filter.placeholder}</option>
                          {filter.options.map(
                            (
                              option: { label: string; value: string },
                              i: number
                            ) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Col>
                    </>
                  )
                }
                if (filter?.type === 'date') {
                  return (
                    <>
                      <Col>
                        <Form.Control
                          key={`filter-${index}`}
                          type="date"
                          name={filter.name}
                          placeholder={filter.placeholder}
                          onChange={(e) =>
                            filter.onChange && filter.onChange(e.target.value)
                          }
                        />
                      </Col>
                    </>
                  )
                }
                if (filter?.type === 'reset') {
                  return (
                    <Col sm={1}>
                      <Button
                        key={`filter-${index}`}
                        variant="danger"
                        type='reset'
                        onClick={() => {
                          if(filter.onChange) {
                            filter.onChange('')
                            setFilterValue('')
                          }
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  )
                }
                return (
                  <>
                    <InputGroup className="mb-3 col" key={index}>
                      <Form.Control
                        name={filter.name}
                        placeholder={filter.placeholder}
                        onChange={(e) =>
                          filter.onChange && filter.onChange(e.target.value)
                        }
                      />
                    </InputGroup>
                  </>
                )
              })}
              </form>
            </>
          )}
        </Row>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              {Object.keys(headers).map((header, index) => (
                <th
                  key={index}
                  style={{
                    width: headers[header]?.width ?? 'auto',
                    verticalAlign: 'middle',
                    cursor: headers[header]?.order ? 'pointer' : 'default',
                  }}
                  onClick={() => setOrder(header)}
                >
                  {headers[header].label}
                  {headers[header]?.order && (
                    <i className="fa-solid fa-sort ms-1"></i>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={'row-' + index}
                onClick={() => onRowClick && onRowClick(row)}
              >
                {Object.keys(headers).map((header, index) => (
                  <td
                    key={'row-label' + index}
                    className={
                      headers[header]?.customClass
                        ? headers[header].customClass(row)
                        : ''
                    }
                  >
                    {headers[header].render
                      ? headers[header].render(row)
                      : headers[header].format
                        ? headers[header].format(row[header])
                        : row[header]}
                  </td>
                ))}
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td
                  colSpan={Object.keys(headers).length}
                  className="text-center"
                >
                  No hay datos
                </td>
              </tr>
            )}
          </tbody>
        </Table>
          <div className="text-center">
            {total > data.length && (
                <ButtonGroup className="me-2" aria-label="pagination">
                  <Button
                    variant="secondary"
                    disabled={!prev_page_url}
                    onClick={() => changePage(current_page - 1)}
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                  </Button>{' '}
                  <Button
                    variant="secondary"
                    disabled={!next_page_url}
                    onClick={() => changePage(current_page + 1)}
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                  </Button>{' '}
                </ButtonGroup>
            )}
            <span className='float-end fw-bold'>{`Total de Resultados: ${total}`}</span>
          </div>
      </div>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Crear</Modal.Title>
          </Modal.Header>
          <Modal.Body>{create && create(handleClose)}</Modal.Body>
        </Modal>
      </>
    </>
  )
}
