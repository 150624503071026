import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";

export function App() {
  return (
    <>
      <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
    </>
  );
}
