import React from 'react';

export function NotFound() {
  return (
    <>
      <div className="container e-404">
        <h1>404</h1>
        <a href="/">Volver al Inicio</a>
      </div>
    </>
  );
}
