/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Catalog, Customer, Suppliers, states } from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
import { suppliersService } from "../../services/suppliersService";
import { catalogService } from "../../services/catalogService";

export interface CustomersCreateProps {
  close?: () => void;
  reload?: () => void;
  outside?: (customer: Suppliers) => void;
}

export const SupplierCreate: FC<CustomersCreateProps> = ({close, reload, outside}) => {
  const { id } = useParams();
  const [object,] = useState<Customer>();
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState<Catalog[]>([])
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.target);
    const values = Object.fromEntries(data.entries());
    suppliersService.create(values).then((response) => {
      setIsLoading(false);
      if(response.success){
        commonService.toast("Proveedor creado correctamente", "success");
        if(outside){
          outside(response.object || {} as Suppliers);
          return;
        }
        close && close();
        reload && reload();
      }
    });
  };
  
  useEffect(() => {
    catalogService.list('group', 1, 100).then((response) => {
      if(response.success){
        setGroup(response?.object?.data || [])
      }
    })
  }, [])

  return (
    <>
      <Form onSubmit={handleSubmit} method="POST">
        <Tabs defaultActiveKey="home" className="mb-3">
          <Tab eventKey="home" title="Información General">
            <Row>
              <h5>Proveedores {id}</h5>
            </Row>
            <Form.Group as={Row} className="mb-3">
              <input type="hidden" name="id" value={id} />
              <Form.Label column sm="2">
                  Razón Social <small className="text-danger">*</small>
              </Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="name" required />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                  Dirección
              </Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="address" />
              </Col>
            </Form.Group>
            <div className="row">
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Teléfono
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="phone" />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Email
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="email" />
                </Col>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Ciudad
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="city" />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Grupo
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="group_id" required>
                    <option></option>
                    {group.map((item, i) => (
                      <option
                        key={i}
                        value={`${item.id}`}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Estado
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="state">
                    <option></option>
                    {states.map((item, i) => (
                      <option
                        key={i}
                        value={`${item}`}
                        selected={item === object?.state}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Sitio web
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="website" />
                </Col>
              </Form.Group>
            </div>
            <hr />
            <h5>Datos Fiscales</h5>
            <div className="row">

              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    RFC
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="rfc" style={{textTransform: "uppercase"}}/>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                    Código Postal
                </Form.Label>
                <Col sm="12">
                  <Form.Control type="text" name="zipcode" />
                </Col>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                  Uso de CFDI
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="use_cfdi">
                    <option></option>
                    <option value="G01-Adquisición de mercancías.">G01-Adquisición de mercancías.</option>
                    <option value="G02-Devoluciones, descuentos o bonificaciones.">G02-Devoluciones, descuentos o bonificaciones.</option>
                    <option value="G03-Gastos en general.">G03-Gastos en general.</option>
                    <option value="I01-Construcciones.">I01-Construcciones.</option>
                    <option value="I02-Mobiliario y equipo de oficina por inversiones.">I02-Mobiliario y equipo de oficina por inversiones.</option>
                    <option value="I03-Equipo de transporte.">I03-Equipo de transporte.</option>
                    <option value="I04-Equipo de computo y accesorios.">I04-Equipo de computo y accesorios.</option>
                    <option value="I05-Dados, troqueles, moldes, matrices y herramental.">I05-Dados, troqueles, moldes, matrices y herramental.</option>
                    <option value="I06-Comunicaciones telefónicas.">I06-Comunicaciones telefónicas.</option>
                    <option value="I07-Comunicaciones satelitales.">I07-Comunicaciones satelitales.</option>
                    <option value="I08-Otra maquinaria y equipo.">I08-Otra maquinaria y equipo.</option>
                    <option value="D01-Honorarios médicos, dentales y gastos hospitalarios.">D01-Honorarios médicos, dentales y gastos hospitalarios.</option>
                    <option value="D02-Gastos médicos por incapacidad o discapacidad.">D02-Gastos médicos por incapacidad o discapacidad.</option>
                    <option value="D03-Gastos funerales.">D03-Gastos funerales.</option>
                    <option value="D04-Donativos.">D04-Donativos.</option>
                    <option value="D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).">D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).</option>
                    <option value="D06-Aportaciones voluntarias al SAR.">D06-Aportaciones voluntarias al SAR.</option>
                    <option value="D07-Primas por seguros de gastos médicos.">D07-Primas por seguros de gastos médicos.</option>
                    <option value="D08-Gastos de transportación escolar obligatoria.">D08-Gastos de transportación escolar obligatoria.</option>
                    <option value="D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.">D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.</option>
                    <option value="D10-Pagos por servicios educativos (colegiaturas).">D10-Pagos por servicios educativos (colegiaturas).</option>
                    <option value="S01-Sin efectos fiscales.">S01-Sin efectos fiscales.</option>
                    <option value="CP0-Pagos">CP0-Pagos</option>
                    <option value="CN0-Nómina">CN0-Nómina</option>
                  </select>
                </Col>
              </Form.Group>


              <Form.Group as={Col} className="mb-3 col-6">
                <Form.Label column sm="12">
                  Regimen Fiscal
                </Form.Label>
                <Col sm="12">
                  <select className="form-select" name="country">
                    <option></option>
                    <option value="601-General de Ley Personas Morales">601-General de Ley Personas Morales</option>
                    <option value="603-Personas Morales con Fines no Lucrativos">603-Personas Morales con Fines no Lucrativos</option>
                    <option value="605-Sueldos y Salarios e Ingresos Asimilados a Salarios">605-Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                    <option value="606-Arrendamiento">606-Arrendamiento</option>
                    <option value="607-Régimen de Enajenación o Adquisición de Bienes">607-Régimen de Enajenación o Adquisición de Bienes</option>
                    <option value="608-Demás ingresos">608-Demás ingresos</option>
                    <option value="610-Residentes en el Extranjero sin Establecimiento Permanente en México">610-Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                    <option value="611-Ingresos por Dividendos (socios y accionistas)">611-Ingresos por Dividendos (socios y accionistas)</option>
                    <option value="612-Personas Físicas con Actividades Empresariales y Profesionales">612-Personas Físicas con Actividades Empresariales y Profesionales</option>
                    <option value="614-Ingresos por intereses">614-Ingresos por intereses</option>
                    <option value="615-Régimen de los ingresos por obtención de premios">615-Régimen de los ingresos por obtención de premios</option>
                    <option value="616-Sin obligaciones fiscales">616-Sin obligaciones fiscales</option>
                    <option value="620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos">620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                    <option value="621-Incorporación Fiscal">621-Incorporación Fiscal</option>
                    <option value="622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                    <option value="623-Opcional para Grupos de Sociedades">623-Opcional para Grupos de Sociedades</option>
                    <option value="624-Coordinados">624-Coordinados</option>
                    <option value="625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                    <option value="626-Régimen Simplificado de Confianza">626-Régimen Simplificado de Confianza</option>
                  </select>
                </Col>
              </Form.Group>
            </div>
          </Tab>
        </Tabs>
        <div className="text-center">
          <Button
            variant="danger"
            id="button-addon2"
            onClick={() => close && close()}
            disabled={isLoading}
          >
              Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="button-addon2"
            className="ms-2"
            disabled={isLoading}
          >
            {isLoading && <span className="spinner-border spinner-border-sm"></span>}
              Guardar
          </Button>
        </div>
      </Form>
    </>
  );
};
