/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Quotes, Quotes_details } from "../interfaces/interfaces";

interface QuotesState extends Quotes {
  destiny?: any;
  details: Quotes_details[];
}

interface QuotesActions {
  setDestiny: (destiny: any) => void;
  setValues: (key: string, value: any) => void;
  reset: () => void;
  deleteAllItems: () => void;
  addItem: (item: any) => void;
  updateItem: (index: number, changes: any) => void;
  removeItem: (index: number) => void;
  calculateTotal: () => void;
  getState: () => QuotesState;
  initQuote: (init: Partial<QuotesState>) => void;
}

const initialState: QuotesState = {
  details: [],
  name: "",
  subtotal: 0,
  tax: 0,
  discount: 0,
  total: 0,
  destiny: undefined,
  notes: "",
  expiration_date: "",
  id: 0,
  customer_id: 0,
  user_id: 0,
  email: "",
  phone: "",
  status: "",
  shipping_address: "",
  shipping_city: "",
  shipping_zipcode: "",
  shipping_state: "",
  shipping_country: "",
  created_at: "",
  updated_at: "",
  customer: undefined,
  quotes_details: [],
};

export const useQuotesStore = create<QuotesState & QuotesActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setDestiny: (destiny) => set({ destiny }),
        setValues: (key, value) => set({ [key]: value }),
        initQuote: (init) =>
          set({
            ...init,
            notes: init.notes ?? "",
            details: init.quotes_details?.map((e) => ({
              ...e,
              tax_amount: e.tax,
              tax_percent: e.tax_rate,
              price: e.unit_price,
            })),
          }),
        reset: () => set(initialState),
        deleteAllItems: () => set({ details: [] }),
        addItem: (item) => {
          set((state) => {
            const price = Number(item.price ?? 0);
            const details = state.details ?? [];
            const taxPercent = (item.tax ?? 0) / 100;
            const taxAmount = price * taxPercent;
            const total = price + taxAmount;
             
            return {
              details: [
                ...details,
                {
                  ...item,
                  product_id: item.id,
                  name: item.name,
                  cost: price,
                  quantity: 1,
                  tax_percent: taxPercent,
                  tax_amount: taxAmount,
                  subtotal: price,
                  total: total,
                },
              ],
            };
            //  ({details: [...state.details, item]})),
          });
          // this.calculateTotal();
        },
        updateItem(index, changes) {
          set((state) => {
            const details = state.details ?? [];
            details[index] = { ...details[index], ...changes };
            // Update Total Item
            const price = Number(details[index].price ?? 0);
            const taxPercent = Number(details[index].tax_percent ?? 0);
            const quantity = Number(details[index].quantity ?? 0);
            const taxAmount = quantity * price * taxPercent;
            const total = quantity * price + taxAmount;
            const subTotal = quantity * price;
            details[index] = {
              ...details[index],
              total,
              subtotal: subTotal,
              tax_amount: taxAmount,
            };

            return { details };
          });
        },
        removeItem(index) {
          set((state) => {
            const details = state.details ?? [];
            details.splice(index, 1);
            return { details };
          });
        },
        calculateTotal() {
          set((state) => {
            const details = state.details ?? [];
            const subtotal = details.reduce(
              (acc, item) => acc + Number(item.subtotal),
              0
            );
            const totalTax = details.reduce(
              (acc, item) => acc + Number(item.tax_amount),
              0
            );
            const discount = Number(state.discount ?? 0);
            const total = subtotal + totalTax - discount;
             
            return { total, subtotal, tax: totalTax, discount };
          });
        },
        getState: () => ({
          ...initialState,
          expiration_date: get().expiration_date,
          name: get().name,
          details: get().details,
          subtotal: get().subtotal,
          tax: get().tax,
          discount: get().discount,
          total: get().total,
          destiny: get().destiny,
          notes: get().notes,
          status: get().status,
        }),
      }),
      {
        name: "crisa-quote",
      }
    )
  )
);
