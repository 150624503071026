import { PaginationResult } from "../interfaces/interfaces";
import { AjaxService } from "./ajax";

const basePath = 'sales-payments';

export const salesPaymentsService = {
  getPagination: (page: number, perPage?: number, name?: string, filter?:any) =>
    AjaxService.get<PaginationResult<any>>({
      url: `${basePath}`,
      data: {
        page,
        perPage,
        sale_id: name,
        filter,
      },
    }),
  delete: (id: number) =>
    AjaxService.delete<any>({
      url: `${basePath}/${id}`,
    }),
  create: (product: Partial<any>) =>
    AjaxService.post<any>({
      url: `${basePath}`,
      data: product,
    }),
  get: (id: number) =>
    AjaxService.get<any>({
      url: `${basePath}/${id}`,
    }),
  update: (id: number, product: Partial<any>) =>
    AjaxService.put<any>({
      url: `${basePath}/${id}`,
      data: product,
    }),

};
