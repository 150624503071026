/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { DataTable } from "../../components/datatable";
import {
  Catalog,
  PaginationResult,
  Purchases,
  Sale,
  statusSale,
  statusSales,
  statusSalesClass,
} from "../../interfaces/interfaces";
// import { commonService } from "../../services/commonService";
// import { CreateBrand } from "./create";
import { Button, Form, Modal } from "react-bootstrap";
// import { ViewBrand } from "./view";
import { useLayoutStore } from "../../stores/layoutStore";
import { commonService } from "../../services/commonService";
import { useAuthStore } from "../../stores/authStore";
import { salesService } from "../../services/salesService";
import { purchasesService } from "../../services/purchasesService";
import { on } from "events";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { purchasesPaymentsService } from "../../services/purchasesPaymentsService";

interface SalesIndexProps {
  type?: "inside";
  insideContent?: (object: any) => void;
  createContent?: () => ReactNode;
}

export function PurchaseIndex({
  type,
  insideContent,
  createContent,
}: SalesIndexProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [status] = useState<statusSale>(type === "inside" ? "authorized" : "");
  const [, setObject] = useState<Catalog>();
  const moduleLabel = "Compras";
  const auth = useAuthStore();
  // const base = process.env.REACT_APP_API_URL;
  const setModule = useLayoutStore((state) => state.setModule);
  const [filters, setFilters] = useState({});
  const amountToPayRef = useRef<HTMLInputElement>(null);
  const paymentMethodRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    setModule("Compras");
  }, []);
  const headers = {
    id: {
      label: "ID",
      width: "10%",
      order: true,
      render: (row: any) =>
        type === "inside" ? (
          <>{row?.id}</>
        ) : (
          <a href={`/movements/purchases/${row?.id}`} className="link">
            {row?.id} {row?.name}
          </a>
        ),
    },
    customer: {
      label: "Proveedor",
      width: "30%",
      render: (row: any) =>
        type === "inside" ? (
          <>
            ({row?.supplier?.id}) - {row?.supplier?.name}
          </>
        ) : (
          <a href={`/movements/suppliers/${row?.supplier?.id}`} className="link">
            ({row?.supplier?.id}) - {row?.supplier?.name}
          </a>
        ),
    },
    status: {
      label: "Estatus",
      width: "10%",
      order: true,
      format: (value: string) => statusSales[value as keyof typeof statusSales],
      customClass: (value: any) =>
        statusSalesClass[value.status as keyof typeof statusSalesClass],
    },
    created_at: {
      label: "Fecha de Compra",
      width: "10%",
      order: true,
      format: (value: string) => commonService.formatDate(value),
    },
    total: {
      label: "Total",
      width: "10%",
      format: (value: string) => commonService.toCurrency(value),
    },
    pending_to_pay: {
      label: "Pendiente de Pago",
      width: "10%",
      format: (value: string) => commonService.toCurrency(value),
    },
    actions: {
      label: "Acciones",
      width: "20%",
      render: (object: any) => (
        <>
          {type === "inside" && insideContent && insideContent(object)}
          {!type && (
            <>
              <Button
                variant="success"
                title="Ver"
                className="ms-2 mt-2"
                href={`/movements/purchases/${object.id}`}
              >
                <i className="fa-solid fa-eye"></i>
              </Button>
              <Button
                variant="primary"
                title="Ver PDF"
                className="ms-2 mt-2"
                onClick={async () => {
                  const response = await purchasesService.getPdf(object.id);

                  if (response.success) {
                    commonService.showPDF(response?.object?.pdf ?? "");
                  }
                }}
              >
                <i className="fa-regular fa-file-pdf"></i>
              </Button>
              {object.status === "authorized" && (
                <>
                  <Button
                    variant="warning"
                    title="Agregar Pago"
                    className="ms-2 mt-2 text-white"
                    onClick={async () => {
                      const MySwal = withReactContent(Swal)
                      // const response = await purchasesService.getPdf(object.id);
                      const { value: formValues } = await MySwal.fire({
                        title: "Ingrese el Monto a Pagar y el Método de Pago",
                        html: 
                        <>
                          <Form.Control type="text" name="description" defaultValue={object.pending_to_pay} onFocus={(e) => e.currentTarget.value = ''} ref={amountToPayRef} placeholder="Monto a Pagar"/>
                          <select className="form-select swal2-input mt-3" ref={paymentMethodRef}>
                            <option value="transfer">Transferencia</option>
                            <option value="cash">Efectivo</option>
                          </select>
                        </>,
                        focusConfirm: false,
                        preConfirm: () => {
                          return {
                            amountToPay: amountToPayRef?.current?.value,
                            paymentMethod: paymentMethodRef?.current?.value
                          }
                        },

                      });
                      if (formValues) {
                        if(!formValues.amountToPay || !formValues.paymentMethod){
                          return;
                        }
                        if(Number(formValues.amountToPay) > object.pending_to_pay){
                          MySwal.fire(`El monto a pagar no puede ser mayor al pendiente de pago`);
                          return false;
                        }else{
                          const res = await purchasesPaymentsService.create({
                            amount: formValues.amountToPay,
                            payment_method: formValues.paymentMethod,
                            purchase_order_id: object.id
                          });
                          if (res.success) {
                            commonService.toast(
                              "Pago agregado con éxito",
                              "success"
                            );
                            reload();
                          } else {
                            commonService.toast(
                              "Error al agregar el pago",
                              "error"
                            );
                          }
                        }
                      }
                    }}
                  >
                    <i className="fa-solid fa-money-bill"></i>
                  </Button>
                </>
              )}

              {auth.user?.role === "administrator" && (
                <button
                  className="btn btn-danger ms-2 mt-2"
                  title="Borrar"
                  onClick={async (e) => {
                    e.stopPropagation();
                    const response = await commonService.confirm(
                      "¿Estas seguro de borrar la " +
                        moduleLabel +
                        "?, esta acción es irreversible"
                    );
                    if (response.isConfirmed) {
                      const res = await purchasesService.delete(object.id);
                      if (res.success) {
                        commonService.toast(
                          moduleLabel + " borrado con éxito",
                          "success"
                        );
                        reload();
                      } else {
                        commonService.toast(
                          "Error al borrar la " + moduleLabel,
                          "error"
                        );
                      }
                    }
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </button>
              )}
            </>
          )}
        </>
      ),
    },
  };

  const getObject = (
    page: number,
    filter?: string,
    status?: statusSale,
    filters?: any
  ) =>
    purchasesService
      .getPagination(page, 10, filter, status, filters)
      .then((res) => {
        if (res.success && res.object?.data) {
          setPaginationResults(res.object);
        }
      });

  useMemo(
    () => getObject(page, filter, status, filters),
    [page, filter, filters]
  );

  const reload = () => getObject(page, filter, status, filters);

  const [paginationResults, setPaginationResults] =
    useState<PaginationResult<Purchases>>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setObject(undefined);
    setShow(false);
  };
  // const handleShow = () => setShow(true);

  return (
    <>
      {type === "inside" && createContent && (
        <DataTable
          create={() => createContent()}
          onRowClick={viewItem}
          headers={headers}
          changePage={setPage}
          filter={setFilter}
          reload={reload}
          resultInfo={paginationResults}
        />
      )}
      {type === "inside" && !createContent && (
        <DataTable
          // create={(close: () => void) => createContent()}
          onRowClick={viewItem}
          headers={headers}
          changePage={setPage}
          filter={setFilter}
          reload={reload}
          resultInfo={paginationResults}
        />
      )}
      {!type && (
        <>
          <DataTable
            // create={(close: () => void) => <CreateBrand close={close} reload={reload}/>}
            createUrl="/movements/purchases/create"
            onRowClick={viewItem}
            headers={headers}
            changePage={setPage}
            filter={setFilter}
            reload={reload}
            resultInfo={paginationResults}
            generalFilter="Filtrar por Proyecto"
            filters={[
              {
                name: "status",
                placeholder: "Estatus",
                options: [
                  {
                    label: "Pendiente",
                    value: "pending",
                  },
                  {
                    label: "Aprobado",
                    value: "authorized",
                  },
                  {
                    label: "Cancelado",
                    value: "cancelled",
                  },
                ],
                onChange: (value: any) =>
                  setFilters({ ...filters, status: value }),
              },
              {
                name: "created_at",
                placeholder: "Fecha de Pedido",
                type: "date",
                onChange: (value: any) =>
                  setFilters({
                    ...filters,
                    created_at: { operation: ">=", value },
                  }),
              },
              {
                type: "reset",
                onChange: () => {
                  setFilters({});
                  setFilter("");
                },
              },
            ]}
          />
          <>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Editar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {
                  // object && <ViewBrand object={object} close={handleClose} reload={reload}/>
                }
              </Modal.Body>
            </Modal>
          </>
        </>
      )}
    </>
  );
}
