/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { DataTable } from "../../components/datatable";
import {
  Customer,
  PaginationResult,
  Suppliers,
  TechnicalSheet,
} from "../../interfaces/interfaces";
import { commonService } from "../../services/commonService";
// import { CreateProduct } from "./create";
// import { Modal } from "react-bootstrap";
import { customerService } from "../../services/customersService";
// import { CustomersCreate } from "./create";
import { useLayoutStore } from "../../stores/layoutStore";
import { useAuthStore } from "../../stores/authStore";
import { TechnicalSheetCreate } from "./create";
import { suppliersService } from "../../services/suppliersService";
import { suppliesService } from "../../services/suppliesService";
import { Button, Modal } from "react-bootstrap";
import { TechnicalSheetEdit } from "./edit";
import { technicalSheetService } from "../../services/technicalSheetService";
// import { ViewProduct } from "./view";

interface CustomersIndexProps {
  type?: "inside";
  insideContent?: (object: any) => void;
  createContent?: () => ReactNode;
}

export function TechnicalSheetIndex({
  type,
  insideContent,
  createContent,
}: CustomersIndexProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewItem = (item: any) => commonService.log(item);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const auth = useAuthStore();
  const moduleLabel = "Hoja Técnica";
  const setModule = useLayoutStore((state) => state.setModule);
  useEffect(() => {
    setModule(moduleLabel);
  }, []);
  // const [, setObject] = useState<Customer>();
  const headers = {
    id: {
      label: "ID",
    },
    name: {
      label: "Producto",
      render: (row: TechnicalSheet) => row?.product?.name
    },
    actions: {
      label: "Acciones",
      render: (object: any) => (
        <>
          {type === "inside" && insideContent && insideContent(object)}
          {!type && (
            <>
              <button
                className="btn btn-warning ms-2 mt-1"
                title="Editar"
                onClick={async (e) => {
                  e.stopPropagation();
                  handleShow();

                  technicalSheetService.get(object.id).then((res) => {
                    if (res.success) {
                      setSupply(res.object);
                    }
                  });
                }}
              >
                <i className="fa-solid fa-pen-to-square"></i>
              </button>
              <Button
                variant="primary"
                title="Ver PDF"
                className="ms-2 mt-2"
                onClick={async () => {
                  const response = await technicalSheetService.getPdf(object.id);
                  if (response.success) {
                    commonService.showPDF(response?.object?.pdf ?? "");
                  }
                }}
              >
                <i className="fa-regular fa-file-pdf"></i>
              </Button>
              {auth.user?.role === "administrator" && (
                <button
                  className="btn btn-danger ms-2 mt-1"
                  title="Borrar"
                  onClick={async (e) => {
                    e.stopPropagation();
                    const response = await commonService.confirm(
                      "¿Estas seguro de borrar el Insumo?, esta acción es irreversible"
                    );
                    if (response.isConfirmed) {
                      const res = await suppliesService.delete(object?.id);
                      if (res.success) {
                        commonService.toast(
                          "Insumo borrado con éxito",
                          "success"
                        );
                        reload();
                      } else {
                        commonService.toast(
                          "Error al borrar el Insumo",
                          "error"
                        );
                      }
                    }
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </button>
              )}
            </>
          )}
        </>
      ),
    },
  };

  const getObjects = (page: number, filter?: string) =>
    technicalSheetService.getPagination(page, 10, filter).then((res) => {
      if (res.success && res.object?.data) {
        setPaginationResults(res.object);
      }
    });

  useMemo(() => getObjects(page, filter), [page, filter]);

  const reload = () => getObjects(page, filter);

  const [paginationResults, setPaginationResults] =
    useState<PaginationResult<Suppliers[]>>();

  const [supply, setSupply] = useState<any>();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setSupply(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      {type === "inside" && createContent && (
        <DataTable
          create={() => createContent()}
          onRowClick={viewItem}
          headers={headers}
          changePage={setPage}
          filter={setFilter}
          reload={reload}
          resultInfo={paginationResults}
        />
      )}
      {type === "inside" && !createContent && (
        <DataTable
          // create={(close: () => void) => createContent()}
          onRowClick={viewItem}
          headers={headers}
          changePage={setPage}
          filter={setFilter}
          reload={reload}
          resultInfo={paginationResults}
        />
      )}
      {!type && (
        <>
          <DataTable
            create={(close: () => void) => (
              <TechnicalSheetCreate close={close} reload={reload} />
            )}
            onRowClick={viewItem}
            headers={headers}
            changePage={setPage}
            filter={setFilter}
            reload={reload}
            resultInfo={paginationResults}
          />
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Editar Hoja Técnica</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {supply && (
                <TechnicalSheetEdit
                  object={supply}
                  close={handleClose}
                  reload={reload}
                />
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
